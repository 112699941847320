import React from 'react';
import { useSearchParams } from 'react-router-dom';
import ButtonsBack from 'components/buttons/Back';
import DashboardsSemenovDetailedStatisticIndex from 'components/dashboards/semenov/detailedStatistic/Index';
import Layout from 'helpers/Layout';
// Страница метрик по врачам
const ViewsDashboardsSemenovDetailedStatistic = () => {
    const [searchParams] = useSearchParams();
    const specialityQueryValue = searchParams.get('speciality');
    const doctorIdQueryValue = searchParams.get('doctorId');
    const doctorNameQueryValue = searchParams.get('doctorName');
    const renderContent = () => (React.createElement(Layout, { className: 'overflow-auto' },
        React.createElement(ButtonsBack, { text: '\u0414\u0430\u0448\u0431\u043E\u0440\u0434 \u043F\u043E \u0432\u0440\u0430\u0447\u0430\u043C', className: 'mb-1' }),
        React.createElement("h1", { className: 'mb-8' }, "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0430\u044F \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043A\u0430 \u043F\u043E \u0432\u0440\u0430\u0447\u0430\u043C"),
        specialityQueryValue && (React.createElement(DashboardsSemenovDetailedStatisticIndex, { specialityQueryValue: specialityQueryValue, doctorIdQueryValue: doctorIdQueryValue, doctorNameQueryValue: doctorNameQueryValue }))));
    return renderContent();
};
export default ViewsDashboardsSemenovDetailedStatistic;
