import { getRubFormattedNumber } from 'functions/data';
export const linearSemenovMetrics = {
    conversionPercentage: {
        title: 'Конверсия',
        tooltipText: 'Показатель отражает количество пациентов, начавших лечение относительно ' +
            'количества пациентов, пришедших на консультацию.',
        tickText: value => `${value}%`,
        stats: {
            tooltipText: 'Конверсия',
            valueKey: 'conversion_percentage',
            dateValueKey: 'treat_date',
            cubeKey: 'treats'
        }
    },
    treatsCountPerClient: {
        title: 'Количество визитов на пациента',
        tooltipText: 'Визитов на физлицо - среднее количество лечений, оказанных данным доктором ' +
            'одному пациенту за выбранный период.',
        tickText: value => String(value),
        stats: {
            tooltipText: 'Визиты',
            valueKey: 'count_per_client',
            dateValueKey: 'treat_date',
            cubeKey: 'treats'
        }
    },
    // TODO: метрика "Показатель повторности" временно убрана
    // repetitionRate: {
    //   title: 'Показатель повторности',
    //   tooltipText: 'Показатель отражает количество визитов на один курс лечения одного пациента.',
    //   tickText: value => String(value),
    //   stats: {
    //     tooltipText: 'Повторность',
    //     valueKey: 'repetition_rate',
    //     dateValueKey: 'treat_date',
    //     cubeKey: 'treats'
    //   }
    // },
    sumOfRevenueByDoctor: {
        title: 'Выручка',
        tooltipText: 'Сумма денежных средств, поступивших в кассу клиники.',
        tickText: value => `${(+value / 1000).toFixed(0)} тыс`,
        stats: {
            tooltipText: 'Выручка',
            valueKey: 'sum_of_revenue',
            dateValueKey: 'treat_date',
            cubeKey: 'treats'
        }
    },
    consultationRevenue: {
        title: 'Выручка за консультацию',
        tooltipText: 'Выручка, которую приносит данному доктору один прошедший первичную консультацию пациент.',
        tickText: value => `${(+value / 1000).toFixed(0)} тыс`,
        stats: {
            tooltipText: 'Выручка',
            valueKey: 'consultation_revenue',
            dateValueKey: 'treat_date',
            cubeKey: 'treats'
        }
    },
    doctorsWorkload: {
        title: 'Загрузка по факту',
        tooltipText: `Процент времени графика врача, занятый фактическим приёмом пациента. Показатель
      ориентируется на отметки прихода и ухода пациента в МИС.`,
        tickText: value => `${value}%`,
        stats: {
            tooltipText: 'Загрузка',
            valueKey: 'doctors_workload',
            dateValueKey: 'work_date',
            cubeKey: 'doctors_schedules'
        }
    },
    doctorsWorkloadBySchedule: {
        title: 'Загрузка по расписанию',
        tooltipText: 'Процент времени графика врача, занятый пациентами по расписанию.',
        tickText: value => `${value}%`,
        stats: {
            tooltipText: 'Загрузка',
            valueKey: 'doctors_workload_by_schedule',
            dateValueKey: 'work_date',
            cubeKey: 'doctors_schedules'
        }
    },
    averageHourlyCost: {
        title: 'Средняя стоимость часа работы',
        tooltipText: 'Выручка у данного доктора за один час работы.',
        tickText: value => getRubFormattedNumber(+value),
        stats: {
            tooltipText: 'Стоимость часа',
            valueKey: 'average_hourly_cost',
            dateValueKey: 'appointment_date',
            cubeKey: 'schedules'
        }
    },
    averageCostPerVisit: {
        title: 'Средняя стоимость посещения',
        tooltipText: 'Средняя выручка у данного врача за один приём пациента.',
        tickText: value => getRubFormattedNumber(+value),
        stats: {
            tooltipText: 'Стоимость посещения',
            valueKey: 'average_cost_per_visit',
            dateValueKey: 'treat_date',
            cubeKey: 'treats'
        }
    }
};
