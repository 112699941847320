import renderChartJsTooltip from './renderChartJsTooltip';
import getChartValueAsPercent from './getChartValueAsPercent';
// Получение опций для отрисовки графика
const getChartJsDoughnutOptions = ({ tooltip, getTooltipText }) => ({
    responsive: true,
    cutout: 60,
    plugins: {
        legend: { display: false },
        datalabels: {
            display: true,
            color: 'white',
            font: {
                size: 14,
                weight: 600,
                family: 'Lato'
            },
            formatter: (value, context) => {
                const datapoints = context.chart.data.datasets[0].data;
                const percentageValue = getChartValueAsPercent(value, datapoints);
                if (percentageValue > 99)
                    return '>99%';
                if (percentageValue < 6)
                    return null;
                return `${Math.round(percentageValue)}%`;
            }
        },
        tooltip: {
            enabled: false,
            external: context => renderChartJsTooltip(context, tooltip, {
                chartType: 'doughnut',
                getTooltipText
            })
        }
    }
});
export default getChartJsDoughnutOptions;
