// Получение цвета для статуса дэшборда
const getDashboardStatusColor = (status) => {
    switch (status) {
        case 'average':
            return {
                main: 'bgGradientYellow',
                secondary: 'bg-orange'
            };
        case 'bad':
            return {
                main: 'bgGradientRed',
                secondary: 'bg-red'
            };
        case 'good':
            return {
                main: 'bgGradientGreen',
                secondary: 'bg-green'
            };
        case 'notRated':
            return {
                main: 'bgGradientGray',
                secondary: 'bg-white'
            };
    }
};
export default getDashboardStatusColor;
