import React, { useContext } from 'react';
import Button from 'helpers/Button';
import { ProcessingDashboardContext } from 'helpers/ProcessingDashboard/Context';
import { loadingHandler } from 'functions/data';
// Шапка дашборда обработки рассылок с возможностью редактирования
const ProcessingDashboardHeadEdit = ({ button, fetchChangeSchedulesStatus }) => {
    const { selectedItemsIds, setIsLoading } = useContext(ProcessingDashboardContext);
    const { text, Icon } = button;
    // Нажатие на кнопку смены статуса записей
    const onChangeStatusClick = () => {
        loadingHandler(() => fetchChangeSchedulesStatus(selectedItemsIds), setIsLoading);
    };
    const renderContent = () => (React.createElement("div", { className: 'absolute left-0 top-0 w-full h-16 flex items-center bgGradientGrayVertical', "data-testid": 'SchedulesDashboardTableHeadEdit' },
        React.createElement("div", { className: 'schedulesDashboardTableHeadEdit__cell w-[6%]' }),
        React.createElement("div", { className: 'schedulesDashboardTableHeadEdit__cell' },
            React.createElement(Button, { className: 'flex items-center border border-gray-20 rounded-[33px] bg-white px-2 h-8', onClick: onChangeStatusClick, "data-testid": 'ProcessingDashboardHeadEdit_button' },
                React.createElement(Icon, { className: 'w-5 h-5 mr-1 fill-primary' }),
                React.createElement("span", { className: 'text-small text-gray-bi' }, text)))));
    return renderContent();
};
export default ProcessingDashboardHeadEdit;
