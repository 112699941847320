import { createContext } from 'react';
import { defaultPeriod } from 'components/schedules/Filters/data';
export const ProcessingDashboardContext = createContext({
    selectedItemsIds: [],
    setSelectedItemsIds: () => { },
    isLoading: false,
    setIsLoading: () => { },
    tabKey: '',
    setTabKey: () => { },
    page: 0,
    setPage: () => { },
    totalPages: 0,
    setTotalPages: () => { },
    period: defaultPeriod,
    setPeriod: () => { }
});
