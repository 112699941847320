import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TableWrapper } from 'components/table/Wrapper';
import { IconFile } from 'svg/common';
// Список элементов с возможностью навигации по ним
const NavList = ({ list, isLoading, placeholderText = 'Список пуст' }) => {
    const navigate = useNavigate();
    // Вывод ряда
    const renderRow = ({ id, name, to }) => (React.createElement("tr", { key: id, className: 'text-gray-75', onClick: () => navigate(to), "data-testid": `NavList_row_${id}` },
        React.createElement("td", { className: 'text-small pl-5.5 cursor-pointer' },
            React.createElement("div", { className: 'flex items-center', "data-testid": `NavList_dashboard_${id}` },
                React.createElement(IconFile, { className: 'mr-5 fill-primary' }),
                React.createElement("h4", null, name)))));
    // Вывод плейсхолдера при пустом списке
    const renderPlaceholder = () => (React.createElement("tr", { "data-testid": 'navList_placeholder', className: 'placeholder flex items-center justify-center text-smaller h-12.5' },
        React.createElement("td", null, placeholderText)));
    // Вывод списка нацигации
    const renderList = (passedList) => (passedList.length > 0 ? passedList.map(renderRow) : renderPlaceholder());
    const renderContent = () => (React.createElement(TableWrapper, { wrapperClass: 'pt-4 rounded-lg overflow-auto min-h-[82px]', tableClass: 'navList', isLoading: isLoading, testId: 'NavList' }, list && renderList(list)));
    return renderContent();
};
export default NavList;
