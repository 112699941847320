import React, { useEffect, useState } from 'react';
import PatientsHistoriesMessagesChat from 'components/patients/history/messages/Chat';
import PatientsHistoriesMessagesMessengerButton from 'components/patients/history/messages/MessengerButton';
import { classNames } from 'functions/Main';
// Компонент вывода вкладки истории сообщений на странице пациенты
const PatientsHistoriesMessagesChatsIndex = ({ data: { conversations } }) => {
    const [selectedConversation, setSelectedConversation] = useState(null);
    useEffect(() => {
        if (!selectedConversation) {
            setSelectedConversation(conversations[0]);
        }
    }, []);
    // Вывод чатов
    const renderContent = () => (React.createElement("div", { className: 'flex flex-1', "data-testid": 'ChatsIndex' },
        React.createElement("div", { className: classNames('w-[314px] border-r border-gray-15 relative', {}, ['p-4']) }, conversations.map((item) => (React.createElement(PatientsHistoriesMessagesMessengerButton, { key: item.id, title: item.channel_name, isSelected: selectedConversation?.id === item.id, onClick: () => setSelectedConversation(item) })))),
        selectedConversation && React.createElement(PatientsHistoriesMessagesChat, { selectedConversation: selectedConversation })));
    return renderContent();
};
export default PatientsHistoriesMessagesChatsIndex;
