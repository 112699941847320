import React, { memo, useCallback, useContext } from 'react';
import Checkbox from 'helpers/Checkbox';
import { ServicesDashboardContext } from 'helpers/Services/Dashboard/Context';
// Компонент вывода блока выбора всех чекбоксов списка
const ServicesDashboardSelectAll = () => {
    const { services, setServices, kind } = useContext(ServicesDashboardContext);
    // Проверяем, что у всех объектов в массиве параметр checked: true
    const allSelected = useCallback(() => services.length > 0 && services.every(item => item?.isChecked), [services]);
    // Обработка нажатия на чекбокс выбора всех элементов
    // Если выбраны все, то со всем присваивается checked: false
    // Если выбраны не все, то всем присваивается checked: true
    const handleSelectAll = () => {
        setServices((prev) => prev.map(item => ({ ...item, isChecked: !allSelected() })));
    };
    const renderContent = () => (React.createElement("div", { className: 'py-3 px-4 border-y border-gray-15' },
        React.createElement(Checkbox, { label: '\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0432\u0441\u0435', htmlFor: `${kind}selectAll`, dimensionsClassName: 'w-4 h-4', onClick: handleSelectAll, checked: allSelected() })));
    return renderContent();
};
export default memo(ServicesDashboardSelectAll);
