import React, { useEffect } from 'react';
import Loader from 'helpers/Loader';
import { useCubeFetch } from 'functions/cubeApi';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { cubeApi } from 'config/cubeApi';
// Формула загрузки врача
const DoctorsWorkloadFormula = ({ dateRange, doctorId }) => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const { resultSet, error, isLoading } = useCubeFetch(cubeApi.description.doctorsWorkload.formula({ clinicId, dateRange, doctorId }));
    const data = resultSet?.rawData()[0];
    const setError = useShowError();
    useEffect(() => {
        if (error)
            setError(error.message);
    }, [error]);
    // Получение формулы
    const getFormula = (doctorsWorkloadData) => {
        const treatMinutes = doctorsWorkloadData['doctors_schedules.work_time'];
        const treatHours = +(+treatMinutes / 60).toFixed(2);
        const doctorMinutes = doctorsWorkloadData['doctors_schedules.sum_of_doctors_schedules_minutes'];
        const doctorHours = +(+doctorMinutes / 60).toFixed(2);
        const doctorWorkload = doctorsWorkloadData['doctors_schedules.doctors_workload'];
        return `(${treatHours}/${doctorHours})×100=${doctorWorkload}%`;
    };
    const renderContent = () => (isLoading ? (React.createElement("div", { className: 'absolute left-0 top-0 h-full w-full flex flexCenter' },
        React.createElement(Loader, { className: 'h-6 w-6' }))) : (React.createElement("p", { className: 'text-big font-normal text-gray-85' }, data ? (getFormula(data)) : ('Нет данных'))));
    return renderContent();
};
export default DoctorsWorkloadFormula;
