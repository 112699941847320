import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { ru } from 'date-fns/locale';
import Button from 'helpers/Button';
import ButtonsMain from 'components/buttons/Main';
import CalendarHeader from 'components/helpers/Calendar/Header';
import { IconArrowLeft } from 'svg/common';
import 'react-datepicker/dist/react-datepicker.css';
// Компонент календаря с выбором диапазона дат
const Calendar = ({ onClose, setDateRange }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    // Изменение диапазона дат
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    // Установка диапазона дат
    const onSetDateRange = () => {
        if (startDate && endDate) {
            setDateRange([startDate, endDate]);
            onClose();
        }
    };
    // Вывод шапки
    const renderHeader = () => (React.createElement("div", { className: 'calendar__header mb-3 pb-2' },
        React.createElement(Button, { className: 'flex items-center', onClick: onClose, "data-testid": 'Calendar_headerButton' },
            React.createElement(IconArrowLeft, { className: 'mr-0.5 w-6 h-6 fill-gray-bi' }),
            React.createElement("span", { className: 'mb-0.5 text-smaller text-gray-bi' }, "\u041A\u043E\u043D\u043A\u0440\u0435\u0442\u043D\u044B\u0435 \u0434\u0430\u0442\u044B"))));
    // Вывод инпутов
    const renderInputs = () => (React.createElement("div", null,
        React.createElement("div", { className: 'mb-3' },
            React.createElement("span", { className: 'text-smallest text-gray-47 mb-3' }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0434\u0430\u0442\u0443 \u0438\u043B\u0438 \u043F\u0435\u0440\u0438\u043E\u0434 \u0434\u043B\u044F \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u044F")),
        React.createElement("div", { className: 'mb-4 flex items-center justify-between' },
            React.createElement(DatePicker, { selected: startDate, startDate: startDate, endDate: endDate, onChange: setStartDate, selectsStart: true, dateFormat: 'dd.MM.yyyy', placeholderText: '\u0434\u0434.\u043C\u043C.\u0433\u0433', open: false }),
            React.createElement("div", { className: 'h-px w-3.5 bg-gray-20' }),
            React.createElement(DatePicker, { selected: endDate, startDate: startDate, endDate: endDate, onChange: setEndDate, selectsEnd: true, dateFormat: 'dd.MM.yyyy', placeholderText: '\u0434\u0434.\u043C\u043C.\u0433\u0433', open: false }))));
    // Вывод футера
    const renderFooter = () => (React.createElement("div", { className: 'calendar__footer pt-4 flex justify-end' },
        React.createElement("div", { className: 'w-45' },
            React.createElement(ButtonsMain, { disabled: !(startDate && endDate), onClick: onSetDateRange, "data-testid": 'Calendar_refreshButton' }, "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u0444\u0438\u043B\u044C\u0442\u0440"))));
    const renderContent = () => (React.createElement("div", { className: 'calendar absolute flex flex-col justify-between bg-white rounded p-3', "data-testid": 'Calendar' },
        React.createElement("div", null,
            renderHeader(),
            renderInputs(),
            React.createElement(DatePicker, { selected: startDate, startDate: startDate, endDate: endDate, onChange: onChange, renderCustomHeader: CalendarHeader, selectsRange: true, locale: ru, inline: true })),
        renderFooter()));
    return renderContent();
};
export default Calendar;
