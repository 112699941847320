import React from 'react';
import Loader from 'helpers/Loader';
import { classNames } from 'functions/Main';
// Таблица на flex верстке
const TableWrapperFlex = ({ thead, children, isLoading, className, bodyClassName }) => {
    const renderContent = () => (React.createElement("div", { className: classNames('flex flex-col relative', {}, [className || '']), "data-testid": 'TableWrapperFlex' },
        thead,
        React.createElement("div", { className: classNames('flex flex-col', {}, [bodyClassName || '']), "data-testid": 'TableWrapperFlex_body' }, children),
        isLoading && (React.createElement("div", { className: 'absolute h-full w-full flex flexCenter left-0 top-0' },
            React.createElement(Loader, null)))));
    return renderContent();
};
export default TableWrapperFlex;
