import React, { useEffect, useState } from 'react';
// Полоска прогресса
const ProgressBar = ({ percent }) => {
    const [width, setWidth] = useState(0);
    useEffect(() => {
        // Таймаут используется, чтобы анимация применилась на первом рендере
        // TODO: переписать, используя react-transition-group
        const timeout = setTimeout(() => {
            setWidth(percent);
        }, 100);
        return () => clearTimeout(timeout);
    }, [percent]);
    const renderContent = () => (React.createElement("div", { className: 'w-full h-4.5 bg-gray-25' },
        React.createElement("div", { className: 'h-full bg-primary-bright transition-[width] chartJsAnimation', style: { width: `${width}%` }, "data-testid": 'ProgressBar_bar' })));
    return renderContent();
};
export default ProgressBar;
