import React, { useEffect } from 'react';
import { handleErrors, useShowError } from 'functions/Errors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { updateAppConfig } from 'redux/slices/appConfig';
import { apiUrls, apiWrapper } from 'config/api';
// Компонент вывода версии приложения
const AuthReleaseVersion = ({ isInitial }) => {
    const { releaseVersion } = useAppSelector(state => state.appConfig);
    const dispatch = useAppDispatch();
    const setError = useShowError();
    useEffect(() => {
        if (isInitial)
            fetchReleaseVersion();
    }, [isInitial]);
    // Запрос релизной версии
    const fetchReleaseVersion = async () => {
        const response = await apiWrapper.get(apiUrls.appConfig.releaseVersion);
        if (response.ok) {
            dispatch(updateAppConfig({ releaseVersion: response.data.release_version }));
        }
        else {
            setError(handleErrors(response));
        }
    };
    const renderContent = () => (React.createElement("span", { className: 'text-smallest mb-2 text-gray-50 font-medium' }, releaseVersion && `Версия ${releaseVersion}`));
    return renderContent();
};
export default AuthReleaseVersion;
