import React, { useEffect } from 'react';
import CampaignsItem from 'components/campaigns/Item';
import CardsStats from 'components/cards/Stats';
import Layout from 'helpers/Layout';
import Loader from 'helpers/Loader';
import Stats from 'helpers/Stats';
import { getCampaignMessagesStatsFormatted } from 'functions/campaigns';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { apiUrls } from 'config/api';
// Страница рассылок
const ViewsCampaignsIndex = () => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const { data, error, isDataFetched, refetch, loading } = useFetch(apiUrls.campaigns.list, 'get', { clinic_id: clinicId });
    const setError = useShowError();
    useEffect(() => {
        if (error)
            setError(error);
    }, [error]);
    useEffect(() => {
        if (isDataFetched)
            refetch();
    }, [clinicId]);
    // Вывод статистики рассылок
    const renderStats = (messagesCount) => {
        const stats = getCampaignMessagesStatsFormatted(messagesCount);
        return (React.createElement("div", { className: 'h-[138px] mb-9 flex gap-2', "data-testid": 'ViewsCampaignsIndex_stats' }, Object.values(stats).map(stat => (React.createElement(CardsStats, { className: 'w-full h-full flexCenter', key: stat.additionalValueType },
            React.createElement(Stats, { ...stat, valueClassName: 'text-title-big font-bold', valueWrapperClassName: 'mb-2', additionalValueClassName: 'text-title-smaller font-medium', descriptionClassName: 'font-medium' }))))));
    };
    const renderContent = () => (React.createElement(Layout, { className: 'overflow-auto' },
        React.createElement("h1", { className: 'mb-8' }, "\u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0438"),
        (data && !loading) ? (React.createElement(React.Fragment, null,
            renderStats(data.messages_count_total),
            data.campaigns.map(item => React.createElement(CampaignsItem, { data: item, key: item.id })))) : (React.createElement(Loader, { className: 'h-1/2' }))));
    return renderContent();
};
export default ViewsCampaignsIndex;
