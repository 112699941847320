import React, { useContext, useEffect, useState } from 'react';
import ProcessingDashboardHeadEdit from 'helpers/ProcessingDashboard/HeadEdit';
import Loader from 'helpers/Loader';
import { ProcessingDashboardContext } from 'helpers/ProcessingDashboard/Context';
import WithTransition from 'helpers/WithTransition';
import { handleErrors, useShowError } from 'functions/Errors';
import { loadingHandler } from 'functions/data';
import { useAppSelector } from 'redux/hooks';
import { apiWrapper } from 'config/api';
// Таблица дашбода обработки рассылок
const ProcessingDashboardTable = ({ status, statusToChange, fetchItemsConfig, fetchChangeItemsStatusUrl, handleResponse, renderHeader, renderRow, headerButton }) => {
    const { activeClinic } = useAppSelector(state => state.clinics);
    const { period, selectedItemsIds, setSelectedItemsIds, isLoading, setIsLoading, page, setPage, totalPages, setTotalPages } = useContext(ProcessingDashboardContext);
    const [itemsData, setItemsData] = useState(null);
    const setError = useShowError();
    useEffect(() => {
        setSelectedItemsIds([]);
        loadingHandler(fetchItems, setIsLoading);
    }, [period, activeClinic.id, status, page]);
    // Запрос записей
    const fetchItems = async () => {
        const response = await apiWrapper.get(fetchItemsConfig.url, {
            period: period?.value,
            clinic_id: activeClinic.id,
            status,
            page: page ? page + 1 : null,
            ...fetchItemsConfig.params
        });
        if (response.ok && response.data) {
            setItemsData(handleResponse(response.data));
            const { pagination } = response.data;
            if (setTotalPages && pagination) {
                setTotalPages(pagination.pages);
            }
        }
        else {
            setError(handleErrors(response));
        }
    };
    // Запрос смены статуса записей
    const fetchChangeItemsStatus = async (ids) => {
        const response = await apiWrapper.patch(fetchChangeItemsStatusUrl, { status: statusToChange, ids });
        if (response.ok) {
            await updateItems(ids);
        }
        else
            setError(handleErrors(response));
    };
    // Обновление отображаемых записей
    const updateItems = async (ids) => {
        setSelectedItemsIds([]);
        // TODO: переработать поведение, когда на текущей странице не остается записей.
        // Необходимо, чтобы бэк при запросе номера страницы больше доступного
        // возвращал данные последней доступной страницы, а не ошибку.
        // Так же нужно доработать компонент Paginator, чтобы он отображал корректную страницу,
        // если page > totalPages.
        const isTrailingPage = totalPages && page === totalPages && totalPages > 1;
        const isAllItemsSelected = ids.length === itemsData?.length;
        // Переходим на предыдущую страницу, если эта последняя и перенесены все записи
        if (isTrailingPage && isAllItemsSelected && setPage && page) {
            setPage(page - 1);
            // Иначе обновляем данные текущей страницы
        }
        else
            await fetchItems();
    };
    // Вывод таблицы
    const renderTable = (items) => (items.length > 0 ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'relative', "data-testid": 'ProcessingDashboardTable_tableHeader' },
            renderHeader(items.map(item => item.id)),
            headerButton && (React.createElement(WithTransition, { renderCondition: selectedItemsIds.length > 0 },
                React.createElement(ProcessingDashboardHeadEdit, { button: headerButton, fetchChangeSchedulesStatus: fetchChangeItemsStatus })))),
        items.map((item, index) => renderRow({
            item, index, status, key: item.id, fetchChangeItemsStatus
        })))) : (React.createElement("div", { className: 'flex-1 flex flexCenter' },
        React.createElement("span", null, "\u041D\u0435\u0442 \u0437\u0430\u043F\u0438\u0441\u0435\u0439"))));
    const renderContent = () => (React.createElement("div", { className: 'flex flex-col relative w-full h-full' },
        itemsData && (renderTable(itemsData)),
        isLoading && (React.createElement("div", { className: 'absolute top-0 left-0 z-[2] w-full h-full bg-gray-47-dim pt-[200px]' },
            React.createElement(Loader, null)))));
    return renderContent();
};
export default ProcessingDashboardTable;
