import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
const initialState = {};
export const clinicsSlice = createSlice({
    name: 'clinics',
    initialState,
    reducers: {
        updateActiveClinic: (state, { payload }) => {
            // Установка таймзоны при смене клиники
            moment.tz.setDefault(payload.time_zone);
            return {
                ...state,
                activeClinic: payload
            };
        }
    }
});
export const { updateActiveClinic } = clinicsSlice.actions;
export default clinicsSlice.reducer;
