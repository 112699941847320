import React, { useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useParams } from 'react-router-dom';
import TableTextCell from 'components/table/TextCell';
import Tooltip from 'components/tooltips/Main';
import Layout from 'helpers/Layout';
import ProcessingDashboard from 'helpers/ProcessingDashboard';
import ProcessingDashboardTable from 'helpers/ProcessingDashboard/Table';
import { ProcessingDashboardContext } from 'helpers/ProcessingDashboard/Context';
import ProcessingDashboardHead from 'helpers/ProcessingDashboard/Head';
import ProcessingDashboardRow from 'helpers/ProcessingDashboard/Row';
import { IconReturn, IconRoundTick } from 'svg/schedules';
import { classNames } from 'functions/Main';
import { apiUrls } from 'config/api';
const columns = [
    {
        name: 'Пациент',
        width: 'w-[26%]'
    },
    {
        name: 'Номер телефона',
        width: 'w-[30%]'
    },
    {
        name: 'Дата (первый этап)',
        width: 'w-[31%]'
    }
];
const buttonTypes = {
    unprocessed: {
        tooltipText: 'Отметить обработанными',
        Icon: IconRoundTick
    },
    processed: {
        tooltipText: 'Вернуть в необработанные',
        Icon: IconReturn
    }
};
const headerButtons = {
    unprocessed: {
        text: 'Отметить обработанными',
        Icon: IconRoundTick
    },
    processed: {
        text: 'Вернуть в необработанные',
        Icon: IconReturn
    }
};
const tabs = [
    {
        status: 'unprocessed',
        statusToChange: 'processed',
        title: 'Необработанные'
    },
    {
        status: 'processed',
        statusToChange: 'unprocessed',
        title: 'Обработанные'
    }
];
const tooltipId = 'ViewsQueuesTreatScenario';
// Страница обработки пациентов по сценарию лечения
const ViewsQueuesTreatScenario = () => {
    const { scenarioId } = useParams();
    const [scenarioName] = useQueryParam('scenarioName', StringParam);
    const [tabKey, setTabKey] = useQueryParam('tab', StringParam);
    const [selectedClientsIds, setSelectedClientsIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // Вывод ряда тыблицы дашборда
    const renderRow = ({ item, index, status, key, fetchChangeItemsStatus }) => (React.createElement(ProcessingDashboardRow, { item: item, index: index, key: key, currentPathName: scenarioName, changeStatusButton: {
            ...buttonTypes[status],
            fetchChangeItemsStatus
        }, tooltipId: tooltipId },
        React.createElement(TableTextCell, { text: item.client.name, className: classNames('pr-5', {}, [columns[0].width]), textClassName: 'text-small', tooltipId: tooltipId, lines: 2, key: key }),
        React.createElement("div", { className: classNames('text-small', {}, [columns[1].width]) },
            React.createElement("span", null, item.client.mobile_phone || '-')),
        React.createElement("div", { className: classNames('text-small', {}, [columns[2].width]) },
            React.createElement("span", null, item.treat_date))));
    // Вывод таба дашборда
    const renderTab = ({ status, statusToChange }) => (React.createElement(ProcessingDashboardTable, { status: status, statusToChange: statusToChange, fetchItemsConfig: {
            url: apiUrls.treatScenarios.clients.list,
            params: { treat_scenario_id: scenarioId }
        }, fetchChangeItemsStatusUrl: apiUrls.treatScenarios.clients.changeStatus, handleResponse: data => data.treat_scenario_clients, renderHeader: ids => React.createElement(ProcessingDashboardHead, { itemsIds: ids, columns: columns }), renderRow: renderRow, headerButton: headerButtons[status] }));
    const renderContent = () => (React.createElement(ProcessingDashboardContext.Provider, { value: {
            selectedItemsIds: selectedClientsIds,
            setSelectedItemsIds: setSelectedClientsIds,
            isLoading,
            setIsLoading,
            tabKey: tabKey || tabs[0].status,
            setTabKey
        } },
        React.createElement(Layout, { testId: 'ViewsSchedulesIndex', className: 'flex flex-col overflow-auto' },
            React.createElement("h1", { className: 'mb-8 min-h-9.5' }, scenarioName),
            React.createElement(ProcessingDashboard, { tabs: tabs, renderTab: renderTab }),
            React.createElement(Tooltip, { id: tooltipId }))));
    return renderContent();
};
export default ViewsQueuesTreatScenario;
