import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonsBack from 'components/buttons/Back';
import ButtonsMain from 'components/buttons/Main';
import ModalsSuccess from 'components/modals/Success';
import TemplatesTextEditor from 'components/templates/TextEditor';
import Layout from 'helpers/Layout';
import Loader from 'helpers/Loader';
import { handleErrors, useShowError } from 'functions/Errors';
import { classNames } from 'functions/Main';
import { loadingHandler } from 'functions/data';
import useFetch from 'functions/Api';
import { apiUrls, apiWrapper } from 'config/api';
import navigationUrls from 'navigation/routes';
// Компонент страницы Редактор шаблона
const ViewsTemplatesEditor = () => {
    const [templateName, setTemplateName] = useState('');
    const [templateText, setTemplateText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSaveTemplateLoading, setIsSaveTemplateLoading] = useState(false);
    const { id } = useParams();
    const { data, loading, error } = useFetch(apiUrls.templates.show(id), 'get');
    const navigate = useNavigate();
    const setError = useShowError();
    useEffect(() => {
        if (error)
            setError(error);
    }, [error]);
    useEffect(() => {
        if (data) {
            if (data.template.name)
                setTemplateName(data.template.name);
            if (data.template.content)
                setTemplateText(data.template.content);
        }
    }, [data]);
    // Сохранение изменений шаблона
    const saveTemplate = async () => {
        const response = await apiWrapper.patch(apiUrls.templates.show(id), { name: templateName, content: templateText });
        if (response.ok)
            setIsModalOpen(true);
        else
            setError(handleErrors(response));
    };
    // При закрытии модалки с сообщением об успехе - редирект назад
    const onCloseModalSuccess = () => {
        setIsModalOpen(false);
        navigate(navigationUrls.templates.index);
    };
    // Вывод инпута названия шаблона
    const renderNameInput = () => (React.createElement("div", { className: 'mb-6 flex flex-col' },
        React.createElement("span", { className: 'mb-2 text-smaller font-semibold text-gray-main' }, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435"),
        React.createElement("input", { className: 'input w-91', placeholder: '\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435', disabled: isSaveTemplateLoading, value: templateName, onChange: e => setTemplateName(e.target.value), "data-testid": 'ViewsTemplatesEditor_nameInput' })));
    // Вывод редактора шаблона
    const renderEditor = (variables) => (React.createElement("div", { className: 'mb-4 flex flex-col' },
        React.createElement("span", { className: 'mb-2 text-smaller font-semibold text-gray-main' }, "\u0422\u0435\u043A\u0441\u0442 \u0448\u0430\u0431\u043B\u043E\u043D\u0430"),
        React.createElement(TemplatesTextEditor, { value: templateText, setValue: setTemplateText, variables: variables, isDisabled: isSaveTemplateLoading })));
    // Вывод описания редактора шаблона
    const renderEditorDescription = (variables) => (React.createElement("div", { className: 'flex flex-col', "data-testid": 'ViewsTemplatesEditor_description' },
        React.createElement("span", { className: 'mb-2 text-smaller font-semibold text-gray-50' }, "*\u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u0432\u043E\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F \u043F\u0435\u0440\u0435\u043C\u0435\u043D\u043D\u044B\u043C\u0438 \u0438\u0437 \u0441\u043F\u0438\u0441\u043A\u0430:"),
        variables.map(variable => (React.createElement("span", { key: variable, className: 'mb-2 text-smaller text-gray-50' }, variable)))));
    // Вывод шаблона
    const renderTemplate = ({ placeholder_names: variables, name }) => (React.createElement("div", { className: 'w-fit relative' },
        React.createElement("div", { className: classNames('', { 'opacity-50': isSaveTemplateLoading }) },
            React.createElement("h1", { className: 'mb-8' }, name),
            renderNameInput(),
            renderEditor(variables),
            variables.length > 0 && renderEditorDescription(variables),
            React.createElement(ButtonsMain, { className: 'mt-8 px-6 w-auto', onClick: () => loadingHandler(saveTemplate, setIsSaveTemplateLoading), "data-testid": 'Template_saveButton', disabled: isSaveTemplateLoading }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F")),
        isSaveTemplateLoading && React.createElement(Loader, { className: 'absolute h-full' })));
    const renderContent = () => (React.createElement(Layout, { testId: 'ViewsTemplatesEditor', className: 'relative' },
        React.createElement(ButtonsBack, { text: '\u0428\u0430\u0431\u043B\u043E\u043D\u044B', className: 'mb-1' }),
        (data && !loading) ? (renderTemplate(data.template)) : (React.createElement(Loader, { className: 'h-full absolute' })),
        React.createElement(ModalsSuccess, { isOpen: isModalOpen, onClose: onCloseModalSuccess, title: '\u0412\u0430\u0448 \u0448\u0430\u0431\u043B\u043E\u043D \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0438\u0437\u043C\u0435\u043D\u0435\u043D!', titleClassName: 'w-3/5 text-center' })));
    return renderContent();
};
export default ViewsTemplatesEditor;
