// Запросы получения данных для подробного описания метрик
export const description = {
    // Описания процента конверсии
    conversationPercentage: {
        // Данные для описания формулы
        formula: ({ clinicId, dateRange, doctorId }) => ({
            dimensions: [
                'treats.doctor_id'
            ],
            measures: [
                'treats.primary_treats_count',
                'treats.primary_consultations_count',
                'treats.conversion_percentage'
            ],
            timeDimensions: [
                {
                    dimension: 'treats.treat_date',
                    dateRange
                }
            ],
            filters: [
                {
                    member: 'treats.clinic_id',
                    operator: 'equals',
                    values: [
                        clinicId
                    ]
                },
                {
                    member: 'treats.doctor_id',
                    operator: 'equals',
                    values: [
                        doctorId
                    ]
                }
            ]
        }),
        // Данные для вывода таблицы элементов формулы
        table: ({ clinicId, dateRange, doctorId }, field) => {
            const categoriesFilter = {
                member: 'treats.service_category',
                operator: 'equals',
                values: field === 'consultation' ? [
                    'treat',
                    'professional_hygiene',
                    'consultation'
                ] : [
                    'treat',
                    'professional_hygiene'
                ]
            };
            const query = {
                dimensions: [
                    'treats.client_id',
                    'treats.treat_date',
                    'treats.client_name'
                ],
                timeDimensions: [
                    {
                        dimension: 'treats.treat_date',
                        dateRange
                    }
                ],
                filters: [
                    {
                        member: 'treats.clinic_id',
                        operator: 'equals',
                        values: [
                            clinicId
                        ]
                    },
                    {
                        member: 'treats.doctor_id',
                        operator: 'equals',
                        values: [
                            doctorId
                        ]
                    },
                    {
                        member: `treats.primary_${field}`,
                        operator: 'equals',
                        values: [
                            'true'
                        ]
                    },
                    categoriesFilter
                ],
                order: [
                    [
                        'treats.treat_date',
                        'desc'
                    ]
                ]
            };
            return query;
        }
    },
    doctorsWorkload: {
        // Данные для описания формулы
        formula: ({ clinicId, dateRange, doctorId }) => ({
            dimensions: [
                'doctors_schedules.doctor_id'
            ],
            measures: [
                'doctors_schedules.doctors_workload',
                'doctors_schedules.work_time',
                'doctors_schedules.sum_of_doctors_schedules_minutes'
            ],
            timeDimensions: [
                {
                    dimension: 'doctors_schedules.work_date',
                    dateRange
                }
            ],
            filters: [
                {
                    member: 'doctors_schedules.clinic_id',
                    operator: 'equals',
                    values: [
                        clinicId
                    ]
                },
                {
                    member: 'doctors_schedules.doctor_id',
                    operator: 'equals',
                    values: [
                        doctorId
                    ]
                }
            ]
        }),
        // Данные для вывода таблицы элементов формулы
        table: ({ clinicId, dateRange, doctorId }) => ({
            dimensions: [
                'doctors_schedules.work_date'
            ],
            measures: [
                'doctors_schedules.work_time',
                'doctors_schedules.sum_of_doctors_schedules_minutes'
            ],
            timeDimensions: [
                {
                    dimension: 'doctors_schedules.work_date',
                    dateRange
                }
            ],
            filters: [
                {
                    member: 'doctors_schedules.clinic_id',
                    operator: 'equals',
                    values: [
                        clinicId
                    ]
                },
                {
                    member: 'doctors_schedules.doctor_id',
                    operator: 'equals',
                    values: [
                        doctorId
                    ]
                }
            ],
            order: [
                [
                    'doctors_schedules.work_date',
                    'desc'
                ]
            ]
        })
    }
};
