import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonsIcon from 'components/buttons/Icon';
import Checkbox from 'helpers/Checkbox';
import Loader from 'helpers/Loader';
import { ProcessingDashboardContext } from 'helpers/ProcessingDashboard/Context';
import { IconChat } from 'svg/common';
import { isEven, loadingHandler } from 'functions/data';
import { classNames } from 'functions/Main';
import navigationUrls from 'navigation/routes';
// Ряд таблицы дашборда обработки
const ProcessingDashboardRow = ({ item: { id, client }, index, tooltipId, changeStatusButton, currentPathName, isSelectable = true, children }) => {
    const { selectedItemsIds, setSelectedItemsIds } = useContext(ProcessingDashboardContext);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    // Выбор записи
    const selectSchedule = (event) => {
        if (event.target.checked) {
            setSelectedItemsIds(prev => [...prev, id]);
        }
        else {
            setSelectedItemsIds(prev => prev.filter(scheduleId => scheduleId !== id));
        }
    };
    // Нажатие на кнопку чата
    const onChatClick = (clientId) => {
        navigate({ pathname: navigationUrls.patients.item(clientId), search: '?tab=messages' }, { state: { prevPathName: currentPathName } });
    };
    // Нажатие на кнопку смены статуса записи
    const onChangeStatusClick = (fetchCallback) => {
        loadingHandler(() => fetchCallback([id]), setIsLoading);
    };
    const renderContent = () => (React.createElement("div", { className: classNames('onRowHover', {}, [
            isEven(index) ? 'bg-white' : 'bg-gray-05',
            'relative flex items-center text-gray-75 h-17.5'
        ]), "data-testid": 'ProcessingDashboardRow' },
        React.createElement("div", { className: 'w-[6%] flex justify-center' }, isSelectable && (React.createElement(Checkbox, { checked: selectedItemsIds.includes(id), onClick: selectSchedule, dimensionsClassName: 'w-5 h-5' }))),
        children,
        React.createElement("div", { className: 'w-[7%] text-small' },
            React.createElement("div", { className: 'flex' },
                client && (React.createElement(ButtonsIcon, { onClick: () => onChatClick(client.id), className: 'mr-3', testId: 'ProcessingDashboardRow_chatButton', "data-tooltip-id": tooltipId, "data-tooltip-content": '\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u0432 \u0447\u0430\u0442' },
                    React.createElement(IconChat, null))),
                changeStatusButton && (React.createElement(ButtonsIcon, { onClick: () => onChangeStatusClick(changeStatusButton.fetchChangeItemsStatus), "data-tooltip-id": tooltipId, "data-tooltip-content": changeStatusButton.tooltipText, testId: 'ProcessingDashboardRow_changeStatusButton' },
                    React.createElement(changeStatusButton.Icon, null))))),
        isLoading && (React.createElement("div", { className: 'absolute flex flexCenter top-0 left-0 w-full h-full bg-gray-47-dim' },
            React.createElement(Loader, null)))));
    return renderContent();
};
export default ProcessingDashboardRow;
