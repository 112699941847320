import { createContext } from 'react';
// Контекст для хранения и передачи данных выбранной категории
export const ServicesDashboardContext = createContext({
    services: [],
    setServices: () => { },
    kind: '',
    page: 1,
    totalPages: 1,
    loadMore: () => { }
});
