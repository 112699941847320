import React, { useContext } from 'react';
import { ProcessingDashboardContext } from 'helpers/ProcessingDashboard/Context';
import Select from 'helpers/Select/Index';
import { periodsSelectOptions } from './data';
// Компонент с фильтрами для страницы Подтверждение записи
const SchedulesFilters = () => {
    const { period, setPeriod } = useContext(ProcessingDashboardContext);
    const renderContent = () => (React.createElement(Select, { containerClassName: 'mb-6 w-42.5', title: '\u041F\u0435\u0440\u0438\u043E\u0434', options: periodsSelectOptions, value: period, onChange: setPeriod }));
    return renderContent();
};
export default SchedulesFilters;
