import React from 'react';
import PatientsHistoriesTreatsTable from 'components/patients/history/treats/Table';
import Layout from 'helpers/Layout';
// Компонент вывода вкладки лечений пациента
const PatientsHistoriesTreatsIndex = () => {
    const renderContent = () => (React.createElement(Layout, null,
        React.createElement(PatientsHistoriesTreatsTable, null)));
    return renderContent();
};
export default PatientsHistoriesTreatsIndex;
