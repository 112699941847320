import React, { useEffect, useRef } from 'react';
import { classNames } from 'functions/Main';
// Компонент выезжающего уведомления
const SlideNotification = ({ text, isActive, setIsActive }) => {
    const timeout = useRef(null);
    useEffect(() => {
        if (isActive) {
            timeout.current = setTimeout(() => {
                setIsActive(false);
            }, 2000);
        }
        return () => {
            if (timeout.current)
                clearTimeout(timeout.current);
        };
    }, [isActive]);
    const renderContent = () => (React.createElement("div", { className: 'relative overflow-hidden' },
        React.createElement("span", { className: 'text-smaller opacity-0' }, text),
        React.createElement("span", { className: classNames('text-smaller text-primary absolute bottom-0 baseTransition', {}, [isActive ? 'left-0' : 'left-[100%]']), "data-testid": 'SlideNotification_dynamicText' }, text)));
    return renderContent();
};
export default SlideNotification;
