import React from 'react';
import { getValidName } from 'functions/dashboards';
// Компонент вывода легенды кругового графика
const ChartsDoughnutLegend = ({ data: { datasets, labels } }) => {
    if (!labels)
        return;
    const dataPoints = datasets[0].data;
    const dataPointsColors = datasets[0].backgroundColor;
    // Вывод элемента легенды
    const renderLegendElement = (index) => (React.createElement("div", { className: 'flex items-center gap-3', key: labels[index] },
        React.createElement("div", { className: 'w-2.5 h-2.5 rounded-full', style: { backgroundColor: dataPointsColors[index] }, "data-testid": 'ChartsDoughnutLegend_colorIndicator' }),
        React.createElement("span", { className: 'text-smaller' }, getValidName(labels[index]))));
    const renderContent = () => (React.createElement("div", { className: 'flex flex-col gap-1.5', "data-testid": 'ChartsDoughnutLegend' }, dataPoints.map((_, index) => renderLegendElement(index))));
    return renderContent();
};
export default ChartsDoughnutLegend;
