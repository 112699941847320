import React from 'react';
import { classNames } from 'functions/Main';
// Текстовое поле описания метрики
const DescriptionTextField = ({ title, children, className, contentWrapperClassName }) => {
    const renderContent = () => (React.createElement("div", { className: classNames('w-[688px] flex flex-col', {}, [className || '']), "data-testid": 'DescriptionTextField' },
        React.createElement("span", { className: 'text-smallest text-gray-50 mb-2' }, title),
        React.createElement("div", { className: classNames('border border-border rounded min-h-[47px] p-3 bg-white', {}, [contentWrapperClassName || '']), "data-testid": 'DescriptionTextField_content' }, children)));
    return renderContent();
};
export default DescriptionTextField;
