const navigationUrls = {
    auth: {
        email: '/auth/email',
        code: '/auth/code'
    },
    campaigns: {
        index: '/campaigns',
        item: (id) => `/campaigns/${id}?tab=0&page=0`,
        landing: '/campaigns/landing',
        messages: { item: (campaignId, messageId) => `/campaigns/${campaignId}/messages/${messageId}` }
    },
    dashboards: {
        index: '/dashboards',
        categories: '/dashboards/categories',
        common: '/dashboards/common',
        doctors: {
            index: '/dashboards/doctors',
            detailedStatistic: '/dashboards/doctors/detailedStatistic',
            metricDescription: '/dashboards/doctors/metricDescription'
        }
    },
    doctors: { index: '/doctors' },
    feedback: { index: '/feedback' },
    patients: {
        index: '/patients',
        item: (id) => `/patients/${id}`
    },
    root: '/',
    schedules: { index: '/schedules' },
    sectionUnavailable: '/sectionUnavailable',
    settings: {
        index: '/settings',
        serviceCategories: {
            item: {
                doctors: (id) => `/settings/serviceCategories/${id}?tab=doctors`,
                services: (id) => `/settings/serviceCategories/${id}?tab=services`
            }
        },
        treatScenarios: {
            item: {
                index: (id) => `/settings/treatScenarios/${id}`,
                stage: (scenarioId, stageId) => `/settings/treatScenarios/${scenarioId}/stage/${stageId}`
            }
        }
    },
    templates: {
        index: '/templates',
        editor: (id) => `/templates/editor/${id}`
    },
    queues: {
        list: '/queues',
        campaignTrigger: (triggerId, triggerName) => (`/queues/campaignTrigger/${triggerId}?tab=unprocessed${triggerName ? `&triggerName=${triggerName}` : ''}`),
        treatScenario: (scenarioId, scenarioName) => (`/queues/treatScenario/${scenarioId}?tab=unprocessed&scenarioName=${scenarioName}`)
    }
};
export default navigationUrls;
