import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Checkbox from 'helpers/Checkbox';
import { handleErrors, useShowError } from 'functions/Errors';
import { loadingHandler } from 'functions/data';
import { apiUrls, apiWrapper } from 'config/api';
// Компонент настроек в шапке страницы пациента
const PatientsShowHeaderSettings = ({ isNotificationsAllowedInitial }) => {
    const { id: clientId } = useParams();
    const [isNotificationsAllowed, setIsNotificationsAllowed] = useState(isNotificationsAllowedInitial);
    const [isToggleDisabled, setIsToggleDisabled] = useState(false);
    const setError = useShowError();
    // Запрос на переключение запрета на рассылки
    const requestToggleNotificationAllowed = async () => {
        const response = await apiWrapper.patch(apiUrls.clients.changeNotificationAllowance(clientId));
        if (response.ok) {
            setIsNotificationsAllowed(prev => !prev);
        }
        else {
            setError(handleErrors(response));
        }
    };
    // Переключение запрета на рассылки
    const toggleNotificationAllowed = (condition) => {
        if (condition) {
            loadingHandler(requestToggleNotificationAllowed, setIsToggleDisabled);
        }
    };
    const renderContent = () => (React.createElement("div", { className: 'flex flex-col gap-3', "data-testid": 'PatientsShowHeaderSettings' },
        React.createElement(Checkbox, { label: '\u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0438 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043D\u044B', onClick: () => toggleNotificationAllowed(!isNotificationsAllowed), checked: isNotificationsAllowed, variant: 'round', isDisabled: isToggleDisabled }),
        React.createElement(Checkbox, { label: '\u0418\u0441\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u0438\u0437 \u0432\u0441\u0435\u0445 \u0440\u0430\u0441\u0441\u044B\u043B\u043E\u043A', onClick: () => toggleNotificationAllowed(isNotificationsAllowed), checked: !isNotificationsAllowed, variant: 'round', isDisabled: isToggleDisabled })));
    return renderContent();
};
export default PatientsShowHeaderSettings;
