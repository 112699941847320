import React, { useEffect, useState } from 'react';
import Tooltip from 'components/tooltips/Main';
import Loader from 'helpers/Loader';
import Select from 'helpers/Select/Index';
import { IconTooltip } from 'svg/common';
import { handleErrors, useShowError } from 'functions/Errors';
import { convertRepetitionPeriodsToSelectType } from 'functions/repetitionPeriods/convertRepetitionPeriodsToSelectType';
import { findActivePeriods } from 'functions/repetitionPeriods/findActivePeriods';
import useFetch from 'functions/Api';
import { useAppSelector } from 'redux/hooks';
import { apiUrls, apiWrapper } from 'config/api';
const tooltip = {
    id: 'PeriodSelector',
    content: 'Отражает через какое время после последнего лечения пациент вновь считается первичным'
};
// Компонент блока выбора периода повторности
const PeriodSelector = () => {
    const { account_id: accountId } = useAppSelector(state => state.user.data);
    const [currentPeriod, setCurrentPeriod] = useState({
        value: 'twelve_months',
        label: '12 месяцев'
    });
    const setError = useShowError();
    const { data, error } = useFetch(apiUrls.accounts.repetitionPeriods.list(accountId), 'get');
    useEffect(() => {
        if (error)
            setError(error);
    }, [error]);
    useEffect(() => {
        if (data && data.repetition_periods) {
            const activePeriod = findActivePeriods(data.repetition_periods);
            setCurrentPeriod(convertRepetitionPeriodsToSelectType(activePeriod)[0]);
        }
    }, [data]);
    // Обработка выбора периода
    const handleSelectChange = async (newValue) => {
        const response = await apiWrapper.patch(apiUrls.accounts.repetitionPeriods.update(accountId), { repetition_period: newValue?.value });
        if (response.ok)
            setCurrentPeriod(newValue);
        else
            setError(handleErrors(response));
    };
    // Вывод заголовка селекта
    const renderTitle = () => (React.createElement("div", { className: 'flex items-center gap-1 mb-2' },
        React.createElement("span", { className: 'text-smaller text-gray-main' }, "\u041F\u0435\u0440\u0438\u043E\u0434 \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E\u0441\u0442\u0438"),
        React.createElement("div", { "data-tooltip-id": tooltip.id, "data-tooltip-content": tooltip.content, className: 'h-4' },
            React.createElement(IconTooltip, null))));
    // Вывод селекта периода повторностей
    const renderSelect = (repetitionPeriods) => (React.createElement(React.Fragment, null,
        renderTitle(),
        React.createElement(Select, { options: convertRepetitionPeriodsToSelectType(repetitionPeriods), onChange: handleSelectChange, value: currentPeriod, className: 'w-42.5' })));
    const renderContent = () => (React.createElement("div", { className: 'flex mb-4 p-6 rounded-lg bg-white', "data-testid": 'PeriodSelector' }, data && data.repetition_periods ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex-1' }, data && data.repetition_periods && renderSelect(data.repetition_periods)),
        React.createElement("div", { className: 'flex-2' }),
        React.createElement(Tooltip, { id: tooltip.id }))) : (React.createElement("div", { className: 'flex flex-1 py-3' },
        React.createElement(Loader, null)))));
    return renderContent();
};
export default PeriodSelector;
