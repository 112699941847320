export const periodsSelectOptions = [
    {
        label: 'Сегодня',
        value: 'today'
    },
    {
        label: 'Завтра',
        value: 'tomorrow'
    },
    {
        label: 'Эта неделя',
        value: 'this_week'
    },
    {
        label: 'Следующие 7 дней',
        value: 'next_seven_days'
    },
    {
        label: 'Следующие 30 дней',
        value: 'next_thirty_days'
    }
];
export const defaultPeriod = periodsSelectOptions[1];
