import React from 'react';
import TableStatsHeader from 'components/table/stats/Header';
import TableStatsHeaderElement from 'components/table/stats/HeaderElement';
const getTooltip = (text) => ({
    id: 'DashboardsSemenovTable',
    content: text
});
const headerElements = [
    {
        text: 'Конверсия',
        tooltip: getTooltip('Показатель отражает количество пациентов, начавших лечение относительно ' +
            'количества пациентов, пришедших на консультацию.')
    },
    {
        text: 'Количество визитов на пациента',
        tooltip: getTooltip('Визитов на физлицо - среднее количество лечений, оказанных данным доктором ' +
            'одному пациенту за выбранный период.')
    },
    // TODO: метрика "Показатель повторности" временно убрана
    // {
    //   text: 'Показатель повторности',
    //   tooltip: getTooltip('Показатель отражает количество визитов на один курс лечения одного пациента.')
    // },
    {
        text: 'Выручка',
        tooltip: getTooltip('Сумма денежных средств, поступивших в кассу клиники.')
    },
    {
        text: 'Выручка за консультацию',
        tooltip: getTooltip('Выручка, которую приносит данному доктору один прошедший первичную консультацию пациент.')
    },
    {
        text: 'Загрузка по факту',
        tooltip: getTooltip(`Процент времени графика врача, занятый фактическим приёмом пациента. Показатель
      ориентируется на отметки прихода и ухода пациента в МИС.`)
    },
    {
        text: 'Загрузка по расписанию',
        tooltip: getTooltip('Процент времени графика врача, занятый пациентами по расписанию.')
    },
    {
        text: 'Средняя стоимость часа работы',
        tooltip: getTooltip('Выручка у данного доктора за один час работы.')
    },
    {
        text: 'Средняя стоимость посещения',
        tooltip: getTooltip('Средняя выручка у данного врача за один приём пациента.')
    }
];
// Компонент шапки таблицы Семёнова
const DashboardsSemenovTableHeader = () => {
    const renderContent = () => (React.createElement(TableStatsHeader, { className: 'mb-2' },
        React.createElement(TableStatsHeaderElement, null,
            React.createElement("p", { className: 'text-smaller font-semibold text-left text-gray-75' }, "\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0437\u0430\u0446\u0438\u044F/\u0432\u0440\u0430\u0447")),
        headerElements.map(({ text, tooltip }) => (React.createElement(TableStatsHeaderElement, { tooltip: tooltip, key: text },
            React.createElement("p", { className: 'text-smaller font-semibold text-left text-gray-75' }, text))))));
    return renderContent();
};
export default DashboardsSemenovTableHeader;
