import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';
import ButtonsBack from 'components/buttons/Back';
import CampaignsMessagesDetail from 'components/campaigns/messages/Detail';
import CardsBase from 'components/cards/Base';
import Layout from 'helpers/Layout';
import Loader from 'helpers/Loader';
import { IconWhatsApp } from 'svg/common';
import { classNames } from 'functions/Main';
import useFetch from 'functions/Api';
import { apiUrls } from 'config/api';
import { useAppSelector } from 'redux/hooks';
// Страница с подробной ифнормацией о сообщении рассылки
const ViewsCampaignsMessagesItem = () => {
    const { campaignId, messageId } = useParams();
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const { data, isDataFetched, refetch } = useFetch(apiUrls.campaigns.messages.show(campaignId, messageId), 'get', { clinic_id: clinicId });
    useEffect(() => {
        if (isDataFetched)
            refetch();
    }, [clinicId]);
    // Вывод карточек с данными
    const renderCards = ({ client_name: name, phone_number: phone, status_name: status, message_content: message, created_at: createdAt, notifiable: { clinic_name: clinicName, doctor_name: doctorName, appointment_date: appointmentDate, start_time: startTime } }) => (React.createElement("div", { className: 'flex gap-5' },
        React.createElement(CardsBase, { className: 'flex-1 p-6' },
            React.createElement("h2", { className: 'mb-6' }, "\u041E \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0435"),
            name && React.createElement(CampaignsMessagesDetail, { title: '\u041F\u0430\u0446\u0438\u0435\u043D\u0442', text: name, className: 'mb-5' }),
            phone && React.createElement(CampaignsMessagesDetail, { title: '\u0421\u043F\u043E\u0441\u043E\u0431 \u043E\u0442\u043F\u0440\u0430\u0432\u043A\u0438', text: phone, Icon: IconWhatsApp, className: 'mb-5' }),
            React.createElement(CampaignsMessagesDetail, { title: '\u0414\u0430\u0442\u0430 \u0438 \u0432\u0440\u0435\u043C\u044F \u043E\u0442\u043F\u0440\u0430\u0432\u043A\u0438', text: `${moment(createdAt).format('DD.MM.YY')} ${moment(createdAt).format('HH:mm')}`, className: 'mb-5' }),
            React.createElement(CampaignsMessagesDetail, { title: '\u0421\u0442\u0430\u0442\u0443\u0441 \u043E\u0442\u043F\u0440\u0430\u0432\u043A\u0438', text: status, className: 'mb-5' }),
            message && React.createElement(CampaignsMessagesDetail, { title: '\u0422\u0435\u043A\u0441\u0442 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439', text: message, className: 'mb-5' })),
        React.createElement(CardsBase, { className: classNames('h-fit', {}, ['flex-1 p-6']) },
            React.createElement("h2", { className: 'mb-6' }, "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u0438\u0451\u043C\u0435"),
            React.createElement(CampaignsMessagesDetail, { title: '\u0414\u0430\u0442\u0430 \u0438 \u0432\u0435\u0440\u043C\u044F \u043F\u0440\u0438\u0435\u043C\u0430', text: `${moment(appointmentDate).format('DD.MM.YY')} ${moment(startTime).format('HH:mm')}`, className: 'mb-5' }),
            React.createElement(CampaignsMessagesDetail, { title: '\u041A\u043B\u0438\u043D\u0438\u043A\u0430', text: clinicName, className: 'mb-5' }),
            React.createElement(CampaignsMessagesDetail, { title: '\u0412\u0440\u0430\u0447', text: doctorName }))));
    const renderContent = () => (React.createElement(Layout, null,
        React.createElement(ButtonsBack, { text: '\u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0438/\u0417\u0430\u043F\u0438\u0441\u044C \u043D\u0430 \u043F\u0440\u0438\u0451\u043C', className: 'mb-1' }),
        React.createElement("h1", { className: 'mb-8' }, "\u0414\u0435\u0442\u0430\u043B\u0438 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438"),
        data ? renderCards(data.conversation_message) : React.createElement(Loader, { className: 'h-1/2' })));
    return renderContent();
};
export default ViewsCampaignsMessagesItem;
