import React from 'react';
import moment from 'moment-timezone';
import { useSearchParams } from 'react-router-dom';
import ButtonsBack from 'components/buttons/Back';
import ConversionPercentageFormula from 'components/dashboards/semenov/metricDescription/metrics/conversionPercentage/Formula';
import ConversionPercentageTable from 'components/dashboards/semenov/metricDescription/metrics/conversionPercentage/Table';
import DescriptionTextField from 'components/dashboards/semenov/metricDescription/TextFiled';
import DoctorsWorkloadFormula from 'components/dashboards/semenov/metricDescription/metrics/doctorsWorkload/Formula';
import DoctorsWorkloadTable from 'components/dashboards/semenov/metricDescription/metrics/doctorsWorkload/Table';
import Layout from 'helpers/Layout';
const data = {
    conversionPercentage: {
        title: 'Конверсия',
        description: 'Показатель отражает количество пациентов, начавших лечение ' +
            'относительно количества пациентов, пришедших на приём.',
        formulaDescription: '(количество первичных лечений / количество первичных консультаций) × 100',
        renderFormula: (dateRange, doctorId) => React.createElement(ConversionPercentageFormula, { dateRange: dateRange, doctorId: doctorId }),
        renderTable: (dateRange, doctorId) => React.createElement(ConversionPercentageTable, { dateRange: dateRange, doctorId: doctorId })
    },
    doctorsWorkload: {
        title: 'Загрузка врача',
        description: 'Показывает занятость данного доктора приёмом в процентах от его графика.',
        formulaDescription: '(сумма часов потраченных на лечение (фактически) / сумма часов в графике врача) × 100',
        renderFormula: (dateRange, doctorId) => React.createElement(DoctorsWorkloadFormula, { dateRange: dateRange, doctorId: doctorId }),
        renderTable: (dateRange, doctorId) => React.createElement(DoctorsWorkloadTable, { dateRange: dateRange, doctorId: doctorId })
    }
};
// Страница описания метрики
const ViewsDashboardsSemenovMetricDescription = () => {
    const [searchParams] = useSearchParams();
    const metricName = searchParams.get('metricName');
    const dateRange = searchParams.get('dateRange')?.split(',');
    const doctorName = searchParams.get('doctorName');
    const doctorId = searchParams.get('doctorId');
    const isAllQueryPresent = metricName && dateRange && doctorName && doctorId;
    const metricData = data[metricName || 'conversionPercentage'];
    // Вывод текста описания
    const renderDescriptionText = (text) => (React.createElement("p", { className: 'text-big font-normal text-gray-85' }, text));
    // Вывод динамического контента
    const renderDynamicContent = ({ title, description, formulaDescription, renderFormula, renderTable }) => (isAllQueryPresent && (React.createElement(React.Fragment, null,
        React.createElement("h1", { className: 'mb-8' }, title),
        React.createElement("div", { className: 'flex flex-col gap-5 mb-6.5' },
            React.createElement(DescriptionTextField, { title: '\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u043F\u043E\u043A\u0430\u0437\u0430\u0442\u0435\u043B\u044F' }, renderDescriptionText(description)),
            React.createElement(DescriptionTextField, { title: '\u0424\u043E\u0440\u043C\u0443\u043B\u0430' }, renderDescriptionText(formulaDescription)),
            React.createElement(DescriptionTextField, { title: '\u041A\u0430\u043A \u043F\u043E\u043B\u0443\u0447\u0438\u043B\u043E\u0441\u044C \u0447\u0438\u0441\u043B\u043E \u0432 \u043C\u0435\u0442\u0440\u0438\u043A\u0430\u0445?', contentWrapperClassName: 'relative' }, renderFormula(dateRange, doctorId)),
            React.createElement(DescriptionTextField, { title: '\u0424\u0418\u041E \u0432\u0440\u0430\u0447\u0430' }, renderDescriptionText(doctorName)),
            React.createElement(DescriptionTextField, { title: '\u041F\u0435\u0440\u0438\u043E\u0434' },
                React.createElement("p", { className: 'text-big font-normal text-gray-85' }, Array.from(dateRange).map(date => moment(date).format('DD.MM.YYYY')).join('–')))),
        renderTable(dateRange, doctorId))));
    const renderContent = () => (React.createElement(Layout, { className: 'overflow-auto' },
        React.createElement(ButtonsBack, { text: '\u041C\u0435\u0442\u0440\u0438\u043A\u0438', className: 'mb-1' }),
        metricData && renderDynamicContent(metricData)));
    return renderContent();
};
export default ViewsDashboardsSemenovMetricDescription;
