import React from 'react';
import { Link } from 'react-router-dom';
import { IconFolder } from 'svg/common';
import { pluralizeServices } from 'functions/pluralize';
// Вывод элемента списка категорий услуг
const SettingsServiceCategoriesItemLink = ({ to, name, servicesCount }) => {
    const renderContent = () => (React.createElement(Link, { to: to, className: 'p-3 flex cursor-pointer gap-6 no-underline onRowHover' },
        React.createElement(IconFolder, { className: 'w-6 h-6' }),
        React.createElement("div", { className: 'text-big flex flex-col' },
            React.createElement("span", { className: 'text-black' }, name),
            React.createElement("span", { className: 'text-gray-50 mt-1' },
                servicesCount,
                " ",
                pluralizeServices(servicesCount)))));
    return renderContent();
};
export default SettingsServiceCategoriesItemLink;
