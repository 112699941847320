import React, { useEffect, useState } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import ButtonsIcon from 'components/buttons/Icon';
import { TableWrapper } from 'components/table/Wrapper';
import Layout from 'helpers/Layout';
import Loader from 'helpers/Loader';
import Paginator from 'helpers/Paginator';
import { IconChat, IconDots, IconWhatsApp } from 'svg/common';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { apiUrls } from 'config/api';
import navigationUrls from 'navigation/routes';
// Шапка таблицы
const tableHead = (React.createElement("tr", null,
    React.createElement("th", { className: 'text-smallest font-semibold text-gray-main pl-5.5' }, "\u0414\u0430\u0442\u0430"),
    React.createElement("th", { className: 'text-smallest font-semibold text-gray-main' }, "\u0412\u0440\u0435\u043C\u044F"),
    React.createElement("th", { className: 'text-smallest font-semibold text-gray-main' }, "\u041F\u0430\u0446\u0438\u0435\u043D\u0442"),
    React.createElement("th", { className: 'text-smallest font-semibold text-gray-main' }, "\u041E\u0442\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u043E \u043D\u0430"),
    React.createElement("th", { className: 'text-smallest font-semibold text-gray-main' }, "\u0421\u0442\u0430\u0442\u0443\u0441"),
    React.createElement("th", null)));
// Компонент истории рассылок
const CampaignsMessagesList = () => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const { campaignId } = useParams();
    const [page, setPage] = useQueryParam('page', NumberParam);
    const [totalPages, setTotalPages] = useState(0);
    const { data, loading, error: fetchMessagesError, refetch, isDataFetched } = useFetch(apiUrls.campaigns.messages.list(campaignId), 'get', { page: (page || 0) + 1, clinic_id: clinicId });
    const setShowError = useShowError();
    const navigate = useNavigate();
    // Установка кол-ва страниц при получении данных
    useEffect(() => {
        if (data?.pagination?.pages)
            setTotalPages(data.pagination.pages);
    }, [data]);
    // Обработка ошибки
    useEffect(() => {
        if (fetchMessagesError)
            setShowError(fetchMessagesError);
    }, [fetchMessagesError]);
    useEffect(() => {
        if (isDataFetched) {
            // Перезапрос данных при смене клиники и нахождении на первой странице
            if (page === 0)
                refetch();
            // Обнуление страниц при смене клиники и нахождении на ненулевой странице
            else {
                setTotalPages(0);
                setPage(0);
            }
        }
    }, [clinicId]);
    // Нажатие на кнопку чата
    const onChatClick = (clientId, clientName) => {
        navigate(navigationUrls.patients.item(clientId), { state: { prevPathName: 'Рассылки', clientName } });
    };
    // Вывод статуса элемента истории рассылки
    const renderStatus = (status) => {
        let className = '';
        let text = 'Доставлено';
        if (status === 'sent') {
            className = 'text-gray-main';
            text = 'Отправлено';
        }
        if (status === 'failed') {
            className = 'text-red';
            text = 'Ошибка';
        }
        return React.createElement("td", { className: `text-small ${className}`, "data-testid": 'CampaignsMessages_message_status' }, text);
    };
    // Вывод элемента истории рассылки
    const renderMessage = ({ id, created_at: createdAt, status, phone_number: phoneNumber, client: { name, id: clientId } }) => (React.createElement("tr", { key: id, className: 'text-gray-75', "data-testid": 'CampaignsMessages_message' },
        React.createElement("td", { className: 'text-small pl-5.5' }, moment(createdAt).format('DD.MM.YY')),
        React.createElement("td", { className: 'text-small' }, moment(createdAt).format('HH:mm')),
        React.createElement("td", { className: 'text-small' }, name),
        React.createElement("td", { className: 'text-small' },
            React.createElement("div", { className: 'flex items-center' },
                React.createElement(IconWhatsApp, { className: 'mr-1 fill-whatsapp' }),
                React.createElement("span", null, phoneNumber))),
        renderStatus(status),
        React.createElement("td", { className: 'text-small pr-5.5' },
            React.createElement("div", { className: 'flex justify-end' },
                React.createElement(ButtonsIcon, { onClick: () => onChatClick(clientId, name), className: 'mr-3' },
                    React.createElement(IconChat, null)),
                React.createElement(ButtonsIcon, { onClick: () => navigate(navigationUrls.campaigns.messages.item(campaignId, id)) },
                    React.createElement(IconDots, null))))));
    // Вывод истории рассылок
    const renderMessages = (messages) => (messages.length > 0 ? (React.createElement(TableWrapper, { thead: tableHead, tableClass: 'campaignsMessagesList', isLoading: loading, wrapperClass: 'overflow-auto' }, messages.map(renderMessage))) : (React.createElement("div", { className: 'flex flex-1 flexCenter' }, "\u0418\u0441\u0442\u043E\u0440\u0438\u044F \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438 \u043F\u0443\u0441\u0442\u0430")));
    const renderContent = () => (React.createElement(Layout, { className: 'flex flex-col overflow-hidden' }, data ? (React.createElement("div", { className: 'flex-1 flex flex-col bg-white rounded-lg overflow-hidden' },
        renderMessages(data.conversation_messages),
        (data.conversation_messages.length > 0 && totalPages > 1) && (React.createElement(Paginator, { page: page, setPage: setPage, pageCount: totalPages, className: 'py-7' })))) : (React.createElement(Loader, { className: 'h-full' }))));
    return renderContent();
};
export default CampaignsMessagesList;
