import React from 'react';
import ButtonsSquareWithIcon from 'components/buttons/SquareWithIcon';
import { IconArrowAddLeft, IconArrowAddRight } from 'svg/settings';
// Кнопки для переноса элементов сервисов из одной категории в другую
const ServicesDashboardChangeButtons = ({ addButton, removeButton }) => {
    const buttons = [
        {
            onClick: addButton.onClick,
            disabled: addButton.isDisabled,
            icon: React.createElement(IconArrowAddRight, null),
            key: 'addToCategory'
        },
        {
            onClick: removeButton.onClick,
            disabled: removeButton.isDisabled,
            icon: React.createElement(IconArrowAddLeft, null),
            key: 'removeFromCategory'
        }
    ];
    const renderContent = () => (React.createElement("div", { className: 'flex flex-col gap-2 mx-4 mt-4' }, buttons.map(({ onClick, disabled, icon, key }) => (React.createElement(ButtonsSquareWithIcon, { onClick: onClick, disabled: disabled, className: 'disabled:bgGradientGray', testId: `SettingsServicesChangeButtons_${key}`, key: `button${key}` }, icon)))));
    return renderContent();
};
export default ServicesDashboardChangeButtons;
