import React, { useCallback, useContext, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ServicesDashboardContext } from 'helpers/Services/Dashboard/Context';
import ServicesDashboardService from 'helpers/Services/Dashboard/Item';
// Вывод списка услуг в категории
const ServicesDashboardList = ({ placeholder }) => {
    const scrollRef = useRef(null);
    const { services, kind, page = 1, loadMore = () => { }, totalPages = 1 } = useContext(ServicesDashboardContext);
    // Вывод информации если услуг нет или не найдено
    const renderPlaceholder = () => (React.createElement("p", null, placeholder));
    const handleScroll = useCallback((event) => {
        if (scrollRef.current) {
            const el = event.target;
            const isFetchMore = totalPages > page;
            // Смена даты на предыдущие 3 дня в истории сообщений
            if (el.scrollHeight - el.scrollTop === el.clientHeight && isFetchMore) {
                loadMore();
            }
        }
    }, [page, totalPages]);
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef?.current?.addEventListener('scroll', handleScroll);
            return () => {
                scrollRef?.current?.removeEventListener('scroll', handleScroll);
            };
        }
    }, [scrollRef.current, handleScroll]);
    // Вывод услуг
    const renderServices = () => (React.createElement(InfiniteScroll, { pageStart: 0, loadMore: () => { }, className: 'flex flex-col gap-6', initialLoad: false }, services.map(item => React.createElement(ServicesDashboardService, { service: item, key: `${kind}${item.id}` }))));
    const renderContent = () => (React.createElement("div", { className: 'bg-white flex flex-1 overflow-auto p-4 mb-2', "data-testid": 'ServicesDashboardList', ref: scrollRef }, services.length !== 0 ? renderServices() : renderPlaceholder()));
    return renderContent();
};
export default ServicesDashboardList;
