import React, { useMemo, useState } from 'react';
import DashboardsCategoriesChart from 'components/dashboards/categories/Chart';
import DashboardsCategoriesHorizontalBarChart from 'components/dashboards/categories/HorizontalBarChart';
import DashboardsCategoriesSelectors from 'components/dashboards/categories/Selectors';
import { options } from 'components/dashboards/selectors/Period';
import Loader from 'helpers/Loader';
import { getDateRange } from 'functions/date';
import { getGranularity } from 'functions/dashboards';
const metricsToShow = [
    'sumOfRevenue',
    'treatsTotalCount'
];
const horizontalBarMetricsToShow = [
    'sumOfRevenueByDoctors',
    'treatsCountByDoctors'
];
const periodDefaultOption = options[2];
// Дашборд по категориям
const DashboardsCategoriesIndex = () => {
    const [dateRange, setDateRange] = useState(getDateRange(periodDefaultOption.value));
    const [categories, setCategories] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [isCategoriesOptionsLoading, setIsCategoriesOptionsLoading] = useState(true);
    // Степень детализации по времени
    const granularity = useMemo(() => getGranularity(dateRange), [dateRange]);
    // Элементы, по которым выводится график
    const activeElements = doctors && doctors.length > 0 ? doctors : categories;
    // Вывод графиков
    const renderCharts = () => (categories.length === 0 ? (React.createElement("span", { className: 'text-gray-bi', "data-testid": 'DashboardsCategoriesIndex_placeholder' }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044E")) : (React.createElement(React.Fragment, null,
        metricsToShow.map(metricName => (React.createElement(DashboardsCategoriesChart, { metricName: metricName, metricElementsNames: activeElements.map(item => item.label), dateRange: dateRange, granularity: granularity, categoriesNames: categories.map(item => item.value), doctorsIds: doctors.length > 0 ? doctors.map(item => item.value) : null, key: metricName }))),
        categories.length === 1 && doctors.length === 0 && (React.createElement("div", { className: 'flex gap-2', "data-testid": 'DashboardsCategoriesIndex_horizontalBarChats' }, horizontalBarMetricsToShow.map(metricName => (React.createElement(DashboardsCategoriesHorizontalBarChart, { metricName: metricName, dateRange: dateRange, categoryName: categories[0].value, key: metricName }))))))));
    const renderContent = () => (React.createElement(React.Fragment, null,
        React.createElement(DashboardsCategoriesSelectors, { categories: categories, setCategories: setCategories, setDateRange: setDateRange, periodDefaultOption: periodDefaultOption, dateRange: dateRange, doctors: doctors, setDoctors: setDoctors, setIsCategoriesOptionsLoading: setIsCategoriesOptionsLoading }),
        categories.length === 0 && isCategoriesOptionsLoading ? (React.createElement(Loader, { className: 'flex-1' })) : (renderCharts())));
    return renderContent();
};
export default DashboardsCategoriesIndex;
