import React, { useEffect, useState } from 'react';
import TableStatsHeaderElement from 'components/table/stats/HeaderElement';
import { IconArrowSort } from 'svg/common';
import { classNames } from 'functions/Main';
// Компонент ячейки шапки таблицы пациентов
const PatientsIndexStatsTableHeaderCell = ({ text, field, orderField, setOrderField, orderBy, setOrderBy, tooltip }) => {
    const [localOrder, setLocalOrder] = useState(orderField === field && orderBy ? orderBy : 'desc');
    useEffect(() => {
        if (orderField !== field)
            setLocalOrder('desc');
    }, [orderField]);
    useEffect(() => {
        if (orderField === field)
            setOrderBy(localOrder);
    }, [localOrder]);
    // Нажатие на ячейку
    const onCellClick = () => {
        // Установка новых поля и порядка сортировки
        if (orderField !== field) {
            setOrderField(field);
            setOrderBy(localOrder);
            // Смена порядка сортировки
        }
        else {
            setLocalOrder((prev) => {
                if (prev === 'asc')
                    return 'desc';
                return 'asc';
            });
        }
    };
    const renderContent = () => (React.createElement(TableStatsHeaderElement, { tooltip: tooltip, className: classNames('baseTransition', { '!border-primary': field === orderField }), onClick: onCellClick, "data-testid": 'PatientsIndexStatsTableHeaderCell' },
        React.createElement("div", { className: 'flex gap-0.5' },
            React.createElement("p", { className: 'text-smaller font-semibold text-left text-gray-75' }, text),
            React.createElement(IconArrowSort, { className: classNames('baseTransition min-w-4.5 min-h-4.5 fill-primary', { 'rotate-180': localOrder === 'asc' }), "data-testid": 'PatientsIndexStatsTableHeaderCell_IconArrowSort' }))));
    return renderContent();
};
export default PatientsIndexStatsTableHeaderCell;
