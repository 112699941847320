import React, { useEffect, useState } from 'react';
import Select from 'helpers/Select/Index';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { updateActiveClinic } from 'redux/slices/clinics';
// Компонент выбора клиники
const ClinicsSelector = () => {
    const { user_clinics: clinics } = useAppSelector(state => state.user.data);
    const { activeClinic } = useAppSelector(state => state.clinics);
    const [selectedClinic, setSelectedClinic] = useState({
        label: activeClinic.name,
        value: activeClinic.id
    });
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (clinics.length > 0) {
            const selectedClinicId = selectedClinic.value;
            const newClinic = clinics.find(clinic => clinic.clinic.id === selectedClinicId);
            dispatch(updateActiveClinic(newClinic.clinic));
        }
    }, [selectedClinic]);
    // Функция изменения значения в селекте
    const handleSelectChange = (newValue) => {
        setSelectedClinic(newValue);
    };
    const renderContent = () => (clinics.length > 0 ? (React.createElement(Select, { options: clinics.map(clinic => ({ label: clinic.clinic.name, value: clinic.clinic.id })), value: selectedClinic, onChange: handleSelectChange })) : null);
    return renderContent();
};
export default ClinicsSelector;
