import { horizontalBarMetrics } from 'config/charts/horizontalBar';
import { linearCategoriesMetrics } from 'config/charts/linear';
export const categories = {
    categories: ({ dateRange }) => ({
        dimensions: [
            'treats.service_category_name'
        ],
        timeDimensions: [
            {
                dimension: 'treats.treat_date',
                dateRange
            }
        ]
    }),
    doctors: ({ clinicId, dateRange, category }) => ({
        dimensions: [
            'treats.doctor_id',
            'treats.doctor_full_name'
        ],
        filters: [
            {
                member: 'treats.clinic_id',
                operator: 'equals',
                values: [
                    clinicId
                ]
            },
            {
                member: 'treats.service_category_name',
                operator: 'equals',
                values: [
                    category
                ]
            }
        ],
        timeDimensions: [
            {
                dimension: 'treats.treat_date',
                dateRange
            }
        ]
    }),
    linearMetrics: ({ clinicId, dateRange, metricName, categoriesNames, doctorsIds, granularity }) => {
        const { stats: { cubeKey, valueKey, dateValueKey } } = linearCategoriesMetrics[metricName];
        const baseConfig = {
            measures: [`${cubeKey}.${valueKey}`],
            timeDimensions: [
                {
                    dimension: `${cubeKey}.${dateValueKey}`,
                    dateRange,
                    granularity
                }
            ],
            filters: [
                {
                    member: `${cubeKey}.clinic_id`,
                    operator: 'equals',
                    values: [
                        clinicId
                    ]
                }
            ]
        };
        if (categoriesNames) {
            baseConfig.filters?.push({
                member: `${cubeKey}.service_category_name`,
                operator: 'equals',
                values: categoriesNames
            });
            if (doctorsIds) {
                baseConfig.dimensions = [`${cubeKey}.doctor_full_name`];
                baseConfig.filters?.push({
                    member: `${cubeKey}.doctor_id`,
                    operator: 'equals',
                    values: doctorsIds
                });
            }
            else {
                baseConfig.dimensions = [`${cubeKey}.service_category_name`];
            }
        }
        return baseConfig;
    },
    horizontalBarMetrics: ({ dateRange, clinicId, categoryName, metricName }) => {
        const { stats: { valueKey } } = horizontalBarMetrics[metricName];
        return {
            dimensions: [
                'treats.doctor_id',
                'treats.doctor_full_name'
            ],
            measures: [
                `treats.${valueKey}`
            ],
            filters: [
                {
                    member: 'treats.clinic_id',
                    operator: 'equals',
                    values: [
                        clinicId
                    ]
                },
                {
                    member: 'treats.service_category_name',
                    operator: 'equals',
                    values: [
                        categoryName
                    ]
                }
            ],
            timeDimensions: [
                {
                    dimension: 'treats.treat_date',
                    dateRange
                }
            ]
        };
    }
};
