import React, { useMemo, useState } from 'react';
import { ArcElement, Chart as ChartJS, DoughnutController, Tooltip } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import TooltipsBase from 'components/tooltips/Base';
import { getChartJsDoughnutOptions } from 'functions/dashboards';
ChartJS.register(ArcElement, DoughnutController, Tooltip);
// Основное поле графика
const ChartsDoughnutField = ({ id, data, getTooltipText, centerLabel }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const chartOptions = useMemo(() => getChartJsDoughnutOptions({
        getTooltipText,
        tooltip: {
            tooltipElementId: `${id}_tooltipElement`,
            setIsTooltipOpen
        }
    }), []);
    const renderContent = () => (React.createElement("div", { className: 'h-54 flex flexCenter relative' },
        React.createElement(Chart, { type: 'doughnut', data: data, options: chartOptions, plugins: [ChartDataLabels] }),
        !!centerLabel && React.createElement("h6", { className: 'absolute', "data-testid": 'ChartsDoughnutField_label' }, centerLabel),
        React.createElement(TooltipsBase, { id: id, isOpen: isTooltipOpen }),
        React.createElement("div", { id: `${id}_tooltipElement`, "data-tooltip-id": id, "data-testid": 'ChartsDoughnutField_tooltipElement' })));
    return renderContent();
};
export default ChartsDoughnutField;
