// Массив дынных для отрисовки элементов логотипа(треугольников)
// Каждый объект - отдельный треугольник
export const triangles = [
    {
        // Цвет компонента логотипа - треугольника
        color: '#B6EDF5',
        positions: {
            // Начальное положение треугольника (по краям экрана)
            // 1 - высота/ширина экрана
            initial: {
                x: 0,
                y: 1
            },
            // Конечное положение треугольника (внутри собранного логотипа)
            // 1 - высота/ширина треугольника
            final: {
                x: 0,
                y: 2
            }
        },
        invert: true
    },
    {
        color: '#A9E8F1',
        positions: {
            initial: {
                x: 0,
                y: 0.7
            },
            final: {
                x: 0,
                y: 1.5
            }
        },
        invert: false
    },
    {
        color: '#98E1EC',
        positions: {
            initial: {
                x: 0,
                y: 0.5
            },
            final: {
                x: 0,
                y: 1
            }
        },
        invert: true
    },
    {
        color: '#83DFE9',
        positions: {
            initial: {
                x: 0,
                y: 0.3
            },
            final: {
                x: 0,
                y: 0.5
            }
        },
        invert: false
    },
    {
        color: '#5ADAE9',
        positions: {
            initial: {
                x: 0,
                y: 0
            },
            final: {
                x: 0,
                y: 0
            }
        },
        invert: true
    },
    {
        color: '#4FD8E8',
        positions: {
            initial: {
                x: 0.22,
                y: 0
            },
            final: {
                x: 1,
                y: 0
            }
        },
        invert: false
    },
    {
        color: '#2ED5E8',
        positions: {
            initial: {
                x: 0.44,
                y: 0
            },
            final: {
                x: 1,
                y: 0.5
            }
        },
        invert: true
    },
    {
        color: '#1FCEE2',
        positions: {
            initial: {
                x: 0.56,
                y: 0
            },
            final: {
                x: 2,
                y: 0.5
            }
        },
        invert: false
    },
    {
        color: '#1BC7DB',
        positions: {
            initial: {
                x: 0.78,
                y: 0
            },
            final: {
                x: 2,
                y: 0
            }
        },
        invert: true
    },
    {
        color: '#14C0D3',
        positions: {
            initial: {
                x: 1,
                y: 0
            },
            final: {
                x: 3,
                y: 0
            }
        },
        invert: false
    },
    {
        color: '#16BBCD',
        positions: {
            initial: {
                x: 1,
                y: 0.3
            },
            final: {
                x: 3,
                y: 0.5
            }
        },
        invert: true
    },
    {
        color: '#13B2C3',
        positions: {
            initial: {
                x: 1,
                y: 0.5
            },
            final: {
                x: 3,
                y: 1
            }
        },
        invert: false
    },
    {
        color: '#13A5B5',
        positions: {
            initial: {
                x: 1,
                y: 0.7
            },
            final: {
                x: 3,
                y: 1.5
            }
        },
        invert: true
    },
    {
        color: '#0F9FAE',
        positions: {
            initial: {
                x: 1,
                y: 1
            },
            final: {
                x: 3,
                y: 2
            }
        },
        invert: false
    }
];
export const logoData = {
    width: 196, // Ширина логотипа
    height: 174, // Высота логотипа
    elementWidth: 49, // Ширина элемента логотипа
    elementHeight: 58, // Высота элемента логотипа
    elementScaleRange: {
        from: 1.5,
        to: 3
    },
    elementAdditionalOffsetRange: {
        from: 0,
        to: 60
    },
    marginBottom: 40, // Нижний отступ логотипа
    renderDuration: 2000, // Длительность отрисовки
    finishDelay: 500 // Задержка после отрисовки
};
