import React from 'react';
import Tooltip from 'components/tooltips/Main';
import { IconTooltip } from 'svg/common';
const tooltipContents = {
    consultation: 'Все пункты прайса, относящиеся к консультированию пациентов.',
    professional_hygiene: 'Все пункты прайса, применяемые врачами и гигиенистами при прохождении ' +
        'пациентом профессиональной чистки зубов.',
    medical_research: 'Пункты прайса, относящиеся к рентгенологическим и лабораторным исследованиям и анализам.',
    other: 'Пункты прайса, относящиеся к немедицинской деятельности (продажи товаров, печать ' +
        'или запись исследований на диск и т.д.).'
};
// Вывод хэдера списка выбранной категории услуг
const ServicesDashboardHeader = ({ title, kind }) => {
    const renderTooltip = (text) => (React.createElement("div", { "data-tooltip-id": 'ServicesDashboardHeader', "data-tooltip-content": text, className: 'h-4', "data-testid": 'ServicesHeader_tooltip' },
        React.createElement(IconTooltip, null)));
    const renderContent = () => (React.createElement("div", { className: 'flex gap-1 p-4' },
        React.createElement("span", { className: 'text-smaller font-semibold' }, title),
        kind && !!tooltipContents[kind] && renderTooltip(tooltipContents[kind]),
        React.createElement(Tooltip, { id: 'ServicesDashboardHeader' })));
    return renderContent();
};
export default ServicesDashboardHeader;
