import React from 'react';
import Button from 'helpers/Button';
import { IconCross } from 'svg/common';
// Бэйдж выбранного элемента в селекторе множественного выбора
const SelectMultiBadge = ({ text, onRemoveClick }) => {
    const renderContent = () => (React.createElement("div", { className: 'rounded bg-primary-light px-2 py-1 flex items-center gap-1', "data-testid": 'SelectMultiBadge' },
        React.createElement("span", { className: 'text-smallest font-semibold text-gray-75' }, text),
        React.createElement("div", { className: 'w-3.5 h-3.5 flex' },
            React.createElement(Button, { onClick: onRemoveClick, testId: 'SelectMultiBadge_clearButton' },
                React.createElement(IconCross, { className: 'fill-gray-75 w-full h-full' })))));
    return renderContent();
};
export default SelectMultiBadge;
