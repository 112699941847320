import React from 'react';
import Button from 'helpers/Button';
import { classNames } from 'functions/Main';
import { IconTooltip } from 'svg/common';
// Компонет элемента шапки таблицы статистики
const TableStatsHeaderElement = ({ tooltip, children, className = '', ...otherProps }) => {
    const renderContent = () => (React.createElement(Button, { className: classNames('flex justify-between border border-border gap-0.5 h-18 ' +
            'flex-1 p-3 rounded bg-white shadow-[0_4px_4px_rgba(214,214,214,0.25)]', {}, [className]), ...otherProps },
        children,
        tooltip && (React.createElement("div", { "data-tooltip-id": tooltip.id, "data-tooltip-content": tooltip.content, "data-testid": 'TableStatsHeaderElement__iconTooltip' },
            React.createElement(IconTooltip, null)))));
    return renderContent();
};
export default TableStatsHeaderElement;
