import { getRubFormattedNumber } from 'functions/data';
export const linearCommonMetrics = {
    sumOfRevenue: {
        title: 'Выручка',
        tooltipText: 'Сумма всех оплат за выбранный период',
        tickText: value => getRubFormattedNumber(+value),
        stats: {
            tooltipText: 'Выручка',
            valueKey: 'sum_of_revenue',
            dateValueKey: 'treat_date',
            cubeKey: 'treats'
        }
    },
    averageBill: {
        title: 'Средний чек',
        tooltipText: 'Средняя сумма оплаты за выбранный период',
        tickText: value => getRubFormattedNumber(+value),
        stats: {
            tooltipText: 'Чек',
            valueKey: 'average_bill',
            dateValueKey: 'treat_date',
            cubeKey: 'treats'
        }
    },
    chairsWorkload: {
        title: 'Загрузка кресел',
        tooltipText: 'Сколько процентов времени от рабочего врачи были заняты приёмом пациентов',
        tickText: value => `${value}%`,
        stats: {
            tooltipText: 'Загрузка',
            valueKey: 'doctors_workload',
            dateValueKey: 'work_date',
            cubeKey: 'doctors_schedules'
        }
    },
    visitsCount: {
        title: 'Количество посещений',
        tooltipText: 'Количество приёмов за выбранный период времени',
        tickText: value => String(value),
        stats: {
            tooltipText: 'Количество',
            valueKey: 'total_count',
            dateValueKey: 'treat_date',
            cubeKey: 'treats'
        }
    }
};
