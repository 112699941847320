import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { cubeApi } from 'config/cubeApi';
import useCubeFetch from './useCubeFetch';
// Получение общих данных для доктора и специальности
const getCommonData = ({ treat, doctorsSchedules, schedules }) => ({
    speciality: treat['treats.doctor_speciality'],
    conversionPercentage: treat['treats.conversion_percentage'],
    treatsCountPerClient: treat['treats.count_per_client'],
    // TODO: метрика "Показатель повторности" временно убрана
    // repetitionRate: treat['treats.repetition_rate'],
    sumOfRevenueByDoctor: treat['treats.sum_of_revenue'],
    averageCostPerVisit: treat['treats.average_cost_per_visit'],
    consultationRevenue: treat['treats.consultation_revenue'],
    doctorsWorkload: doctorsSchedules?.['doctors_schedules.doctors_workload'] || null,
    doctorsWorkloadBySchedule: doctorsSchedules?.['doctors_schedules.doctors_workload_by_schedule'] || null,
    averageHourlyCost: schedules?.['schedules.average_hourly_cost'] || null
});
// Хук для запроса метрик по Семёнову из cubejs
export default function useSemenovFetch({ dateRange, dimension = 'doctors' }) {
    const { activeClinic: { id: clinicId } } = useAppSelector(state => state.clinics);
    const [data, setData] = useState(null);
    const [error, setError] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    // Запросы метрик Семенова из трех кубов treats, doctorsSchedules, schedules
    const { resultSet: resultTreats, error: errorTreats, isLoading: isTreatsLoading } = useCubeFetch(cubeApi.semenov.treats({ clinicId, dateRange, dimension }));
    const { resultSet: resultDoctorsSchedules, error: errorDoctorsSchedules, isLoading: isDoctorsSchedulesLoading } = useCubeFetch(cubeApi.semenov.doctorsSchedules({ clinicId, dateRange, dimension }));
    const { resultSet: resultSchedules, error: errorSchedules, isLoading: isSchedulesLoading } = useCubeFetch(cubeApi.semenov.schedules({ clinicId, dateRange, dimension }));
    // Получение сырых данных метрик
    const dataTreats = resultTreats?.rawData();
    const dataDoctorsSchedules = resultDoctorsSchedules?.rawData();
    const dataSchedules = resultSchedules?.rawData();
    // Формирование данных для докторов или специальностей
    useEffect(() => {
        if (dataTreats && dataDoctorsSchedules && dataSchedules) {
            if (dimension === 'doctors') {
                const collectedData = dataTreats.map((treat) => {
                    const doctorsSchedules = dataDoctorsSchedules.find(doctorsSchedule => doctorsSchedule['doctors_schedules.doctor_id'] === treat['treats.doctor_id']);
                    const schedules = dataSchedules.find(schedule => schedule['schedules.doctor_id'] === treat['treats.doctor_id']);
                    return {
                        id: treat['treats.doctor_id'],
                        fullName: treat['treats.doctor_full_name'],
                        ...getCommonData({
                            treat,
                            doctorsSchedules,
                            schedules
                        })
                    };
                });
                setData(collectedData);
            }
            else {
                const collectedData = dataTreats.map((treat) => {
                    const doctorsSchedules = dataDoctorsSchedules.find(doctorsSchedule => doctorsSchedule['doctors_schedules.doctor_speciality'] === treat['treats.doctor_speciality']);
                    const schedules = dataSchedules.find(schedule => schedule['schedules.doctor_speciality'] === treat['treats.doctor_speciality']);
                    return getCommonData({
                        treat,
                        doctorsSchedules,
                        schedules
                    });
                });
                setData(collectedData);
            }
            setIsLoading(false);
        }
    }, [dataTreats, dataDoctorsSchedules, dataSchedules]);
    useEffect(() => {
        if (errorTreats || errorDoctorsSchedules || errorSchedules) {
            setError((errorTreats || errorDoctorsSchedules || errorSchedules)?.message);
        }
    }, [errorTreats, errorDoctorsSchedules, errorSchedules]);
    useEffect(() => {
        if (isTreatsLoading || isDoctorsSchedulesLoading || isSchedulesLoading) {
            setIsLoading(true);
        }
    }, [isTreatsLoading, isDoctorsSchedulesLoading, isSchedulesLoading]);
    return { data, error, isLoading };
}
