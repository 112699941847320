import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CampaignsStatsDynamicsChart from 'components/campaigns/stats/DinamicsChart';
import CardsStats from 'components/cards/Stats';
import Layout from 'helpers/Layout';
import Loader from 'helpers/Loader';
import Stats from 'helpers/Stats';
import { getCampaignMessagesStatsFormatted } from 'functions/campaigns';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { apiUrls } from 'config/api';
// Компонент вкладки статистики рассылки
const CampaignsStatsTab = () => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const { campaignId } = useParams();
    const setError = useShowError();
    const { data, error, refetch, isDataFetched, loading } = useFetch(apiUrls.campaigns.statistic(campaignId), 'get', { clinic_id: clinicId });
    useEffect(() => {
        if (error)
            setError(error);
    }, [error]);
    useEffect(() => {
        if (isDataFetched)
            refetch();
    }, [clinicId]);
    // Вывод статистики рассылки за все время
    const renderAllTimeStats = (messagesCount) => {
        const stats = getCampaignMessagesStatsFormatted(messagesCount);
        return Object.values(stats).map(stat => (React.createElement(CardsStats, { className: 'w-full h-full flexCenter', key: stat.additionalValueType },
            React.createElement(Stats, { ...stat, valueClassName: 'text-title-small font-bold', additionalValueClassName: 'text-smaller font-semibold', descriptionClassName: 'text-smaller font-bold' }))));
    };
    const renderContent = () => (React.createElement(Layout, null,
        React.createElement("div", { className: 'h-22.5 mb-9 flex flexCenter gap-2' }, (data && !loading) ? (renderAllTimeStats(data.messages_count)) : (React.createElement(Loader, null))),
        React.createElement(CampaignsStatsDynamicsChart, { campaignId: campaignId })));
    return renderContent();
};
export default CampaignsStatsTab;
