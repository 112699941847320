import React, { useEffect } from 'react';
import Loader from 'helpers/Loader';
import { useCubeFetch } from 'functions/cubeApi';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { cubeApi } from 'config/cubeApi';
// Формула процента конверсии
const ConversionPercentageFormula = ({ dateRange, doctorId }) => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const { resultSet, error, isLoading } = useCubeFetch(cubeApi.description.conversationPercentage.formula({ clinicId, dateRange, doctorId }));
    const data = resultSet?.rawData()[0];
    const setError = useShowError();
    useEffect(() => {
        if (error)
            setError(error.message);
    }, [error]);
    // Получение формулы
    const getFormula = (treatsData) => {
        const treats = treatsData['treats.primary_treats_count'];
        const consultations = treatsData['treats.primary_consultations_count'];
        const conversion = treatsData['treats.conversion_percentage'];
        return `(${treats}/${consultations})×100=${conversion}%`;
    };
    const renderContent = () => (isLoading ? (React.createElement("div", { className: 'absolute left-0 top-0 h-full w-full flex flexCenter' },
        React.createElement(Loader, { className: 'h-6 w-6' }))) : (React.createElement("p", { className: 'text-big font-normal text-gray-85' }, data ? (getFormula(data)) : ('Нет данных'))));
    return renderContent();
};
export default ConversionPercentageFormula;
