import React from 'react';
import Modal from 'react-modal';
import Button from 'helpers/Button';
import { IconCross } from 'svg/common';
const modalStyles = {
    overlay: { backgroundColor: 'rgba(40, 40, 40, .7)' },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 0,
        border: 0,
        background: 0,
        borderRadius: 0,
        padding: 0,
        height: 'fit-content',
        transform: 'translate(-50%, -50%)'
    }
};
Modal.setAppElement('body');
// Лэйаут для модалок
const ModalsLayout = ({ children, isOpen, onClose, title, titleClassName = 'mb-4', withButtonClose = true }) => {
    // Вывод кнопки закрытия модалки
    const renderButtonClose = () => (React.createElement(Button, { className: 'absolute top-0 right-0 w-6 h-6', onClick: onClose, "data-testid": 'ModalsLayout_closeButton' },
        React.createElement(IconCross, { className: 'w-full h-full fill-gray-50' })));
    const renderContent = () => (React.createElement(Modal, { isOpen: isOpen, style: modalStyles },
        React.createElement("div", { className: 'rounded-lg bg-white py-6 px-8' },
            React.createElement("div", { className: 'relative flex flex-col items-center pt-10 pb-4 w-[400px]', "data-testid": 'ModalsLayout' },
                withButtonClose && renderButtonClose(),
                React.createElement("h2", { "data-testid": 'ModalsLayout_title', className: titleClassName }, title),
                children))));
    return renderContent();
};
export default ModalsLayout;
