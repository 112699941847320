// Функция для подсчета суммы внутри метода reduce
const getTotalSum = (total, datapoint) => {
    const checkedTotal = total || 0;
    const checkedDatapoint = datapoint || 0;
    return +checkedTotal + +checkedDatapoint;
};
// Получение значения графика в процентах
const getChartValueAsPercent = (value, datapoints) => {
    const totalValue = datapoints.reduce(getTotalSum, 0);
    const checkedValue = value || 0;
    const percentageValue = (+checkedValue / totalValue) * 100;
    return percentageValue;
};
export default getChartValueAsPercent;
