import React, { useState } from 'react';
import DashboardsSelectorsPeriod, { options } from 'components/dashboards/selectors/Period';
import DashboardsSemenovTable from 'components/dashboards/semenov/table/Index';
import Layout from 'helpers/Layout';
import { getDateRange } from 'functions/date';
const periodDefaultOption = options[2];
// Страница метрик по Семёнову
const ViewsDashboardsSemenovIndex = () => {
    const [dateRange, setDateRange] = useState(getDateRange(periodDefaultOption.value));
    const renderContent = () => (React.createElement(Layout, { className: 'flex flex-col' },
        React.createElement("h1", { className: 'mb-8' }, "\u0414\u0430\u0448\u0431\u043E\u0440\u0434 \u043F\u043E \u0432\u0440\u0430\u0447\u0430\u043C"),
        React.createElement(DashboardsSelectorsPeriod, { onChange: setDateRange, defaultOption: periodDefaultOption }),
        dateRange && React.createElement(DashboardsSemenovTable, { dateRange: dateRange })));
    return renderContent();
};
export default ViewsDashboardsSemenovIndex;
