import { classNames } from 'functions/Main';
import { getDashboardStatusColor } from 'functions/data';
import React from 'react';
const statusText = {
    notRated: 'параметр не оценивается',
    good: 'отличный результат',
    average: 'средний результат',
    bad: 'требуется коррекция'
};
// Компонент описания цвета характеристики дэшборда
const DashboardsColorDescription = ({ status, className }) => {
    const renderContent = () => (React.createElement("div", { className: `flex items-center ${className}`, "data-testid": 'DashboardsColorDescription' },
        React.createElement("div", { className: classNames('w-5 h-5 mr-3 rounded', {}, [getDashboardStatusColor(status).main]), "data-testid": 'DashboardsColorDescription_color' }),
        React.createElement("span", { className: 'text-smallest text-gray-50' }, statusText[status])));
    return renderContent();
};
export default DashboardsColorDescription;
