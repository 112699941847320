import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';
const transitionDuration = 300;
const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 }
};
const defaultStyle = {
    transition: `opacity ${transitionDuration}ms ease`,
    opacity: 0
};
// Компонент-обертка для плавного перехода при монтировании/размонтировании компонента
const WithTransition = ({ children, renderCondition }) => {
    const nodeRef = useRef(null);
    const renderContent = () => (React.createElement(Transition, { in: renderCondition, timeout: transitionDuration, nodeRef: nodeRef, mountOnEnter: true, unmountOnExit: true }, state => (React.createElement("div", { style: { ...defaultStyle, ...transitionStyles[state] }, ref: nodeRef }, children))));
    return renderContent();
};
export default WithTransition;
