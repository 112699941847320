import React, { useContext } from 'react';
import Checkbox from 'helpers/Checkbox';
import { ProcessingDashboardContext } from 'helpers/ProcessingDashboard/Context';
import { classNames } from 'functions/Main';
// Шапка дашборда обработки рассылок
const ProcessingDashboardHead = ({ itemsIds, columns, isSelectable = true }) => {
    const { selectedItemsIds, setSelectedItemsIds } = useContext(ProcessingDashboardContext);
    // Добавление/удаление id всех записей
    const toggleAllSelectedSchedulesIds = (event) => {
        if (event.target.checked) {
            setSelectedItemsIds(itemsIds);
        }
        else {
            setSelectedItemsIds([]);
        }
    };
    const renderContent = () => (React.createElement("div", { className: 'h-16 flex items-center', "data-testid": 'ProcessingDashboardHead' },
        React.createElement("div", { className: 'w-[6%] flex justify-center' }, isSelectable && (React.createElement(Checkbox, { checked: selectedItemsIds.length === itemsIds.length, onClick: toggleAllSelectedSchedulesIds, containerClassName: 'z-[1]' }))),
        [...columns, { name: 'Действие', width: 'w-[7%]' }].map(({ name, width }) => (React.createElement("div", { className: classNames('text-smallest font-semibold text-left text-gray-main', {}, [width]), key: name }, name)))));
    return renderContent();
};
export default ProcessingDashboardHead;
