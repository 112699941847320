import React, { useContext } from 'react';
import CardsBase from 'components/cards/Base';
import Paginator from 'helpers/Paginator';
import TabsWrapper from 'helpers/TabsWrapper';
import { ProcessingDashboardContext } from './Context';
// Компонент дашборда обработки
const ProcessingDashboard = ({ tabs, renderTab }) => {
    const { tabKey, setTabKey, totalPages, page, setPage } = useContext(ProcessingDashboardContext);
    const isPaginatorAvailable = setPage && totalPages && totalPages > 1;
    const renderContent = () => (React.createElement(React.Fragment, null,
        React.createElement(CardsBase, { className: 'pt-3 flex flex-1' },
            React.createElement(TabsWrapper, { tabKey: tabKey, tabs: tabs.map(({ status, statusToChange, title }) => ({
                    key: status,
                    title,
                    renderComponent: () => renderTab({ status, statusToChange })
                })), setTabKey: setTabKey, className: 'rounded-lg' })),
        isPaginatorAvailable && React.createElement(Paginator, { page: page, setPage: setPage, pageCount: totalPages, className: 'mt-7' })));
    return renderContent();
};
export default ProcessingDashboard;
