import { linearCommonMetrics, linearSemenovMetrics } from 'config/charts/linear';
export const semenov = {
    doctorsSchedules: ({ clinicId, dateRange, dimension = 'doctors' }) => ({
        dimensions: dimension === 'doctors' ? [
            'doctors_schedules.doctor_id',
            'doctors_schedules.doctor_full_name',
            'doctors_schedules.doctor_speciality'
        ] : [
            'doctors_schedules.doctor_speciality'
        ],
        measures: [
            'doctors_schedules.doctors_workload',
            'doctors_schedules.doctors_workload_by_schedule'
        ],
        timeDimensions: [
            {
                dimension: 'doctors_schedules.work_date',
                dateRange
            }
        ],
        filters: [
            {
                member: 'doctors_schedules.clinic_id',
                operator: 'equals',
                values: [
                    clinicId
                ]
            }
        ]
    }),
    treats: ({ clinicId, dateRange, dimension = 'doctors' }) => ({
        dimensions: dimension === 'doctors' ? [
            'treats.doctor_id',
            'treats.doctor_full_name',
            'treats.doctor_speciality'
        ] : [
            'treats.doctor_speciality'
        ],
        measures: [
            'treats.conversion_percentage',
            'treats.count_per_client',
            // TODO: метрика "Показатель повторности" временно убрана
            // 'treats.repetition_rate',
            'treats.sum_of_revenue',
            'treats.average_cost_per_visit',
            'treats.consultation_revenue'
        ],
        timeDimensions: [
            {
                dimension: 'treats.treat_date',
                dateRange
            }
        ],
        filters: [
            {
                member: 'treats.clinic_id',
                operator: 'equals',
                values: [
                    clinicId
                ]
            }
        ]
    }),
    schedules: ({ clinicId, dateRange, dimension = 'doctors' }) => ({
        dimensions: dimension === 'doctors' ? [
            'schedules.doctor_id',
            'schedules.doctor_full_name',
            'schedules.doctor_speciality'
        ] : [
            'schedules.doctor_speciality'
        ],
        measures: [
            'schedules.average_hourly_cost'
        ],
        timeDimensions: [
            {
                dimension: 'schedules.appointment_date',
                dateRange
            }
        ],
        filters: [
            {
                member: 'schedules.clinic_id',
                operator: 'equals',
                values: [
                    clinicId
                ]
            }
        ]
    }),
    detailed: ({ clinicId, dateRange, metricName, specialities, doctorsIds, granularity }) => {
        const { stats: { cubeKey, valueKey, dateValueKey } } = {
            ...linearCommonMetrics,
            ...linearSemenovMetrics
        }[metricName];
        const baseConfig = {
            measures: [`${cubeKey}.${valueKey}`],
            timeDimensions: [
                {
                    dimension: `${cubeKey}.${dateValueKey}`,
                    dateRange,
                    granularity
                }
            ],
            filters: [
                {
                    member: `${cubeKey}.clinic_id`,
                    operator: 'equals',
                    values: [
                        clinicId
                    ]
                }
            ]
        };
        if (specialities) {
            baseConfig.filters?.push({
                member: `${cubeKey}.doctor_speciality`,
                operator: 'equals',
                values: specialities
            });
            if (doctorsIds) {
                baseConfig.dimensions = [`${cubeKey}.doctor_full_name`];
                baseConfig.filters?.push({
                    member: `${cubeKey}.doctor_id`,
                    operator: 'equals',
                    values: doctorsIds
                });
            }
            else {
                baseConfig.dimensions = [`${cubeKey}.doctor_speciality`];
            }
        }
        return baseConfig;
    },
    specialities: ({ clinicId, dateRange }) => ({
        dimensions: [
            'treats.doctor_speciality'
        ],
        filters: [
            {
                member: 'treats.clinic_id',
                operator: 'equals',
                values: [
                    clinicId
                ]
            }
        ],
        timeDimensions: [
            {
                dimension: 'treats.treat_date',
                dateRange
            }
        ]
    }),
    doctors: ({ clinicId, dateRange, speciality }) => ({
        dimensions: [
            'treats.doctor_id',
            'treats.doctor_full_name'
        ],
        filters: [
            {
                member: 'treats.clinic_id',
                operator: 'equals',
                values: [
                    clinicId
                ]
            },
            {
                member: 'treats.doctor_speciality',
                operator: 'equals',
                values: [
                    speciality
                ]
            }
        ],
        timeDimensions: [
            {
                dimension: 'treats.treat_date',
                dateRange
            }
        ]
    })
};
