import React, { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import ButtonsCopy from 'components/buttons/Copy';
// Компонент настроек виджета
const SettingsWidget = () => {
    const [phone, setPhone] = useState('');
    // Шаблон с кодом для копирования
    const codeTemplate = `<script src="${window.location.origin}/assets/widgetsWhatsapp.js"></script>\n` +
        '<script type="text/javascript">\n' +
        `\tMetaclinincWidget(${phone.replace(/\s/g, '')})\n` +
        '</script>';
    const renderContent = () => (React.createElement("div", { className: 'py-6 px-8' },
        React.createElement("div", { className: 'mb-8 py-2.5 px-4 rounded-lg bg-blue-cold' },
            React.createElement("span", null,
                "\u0417\u0430\u043F\u043E\u043B\u043D\u0438\u0442\u0435 \u043F\u043E\u043B\u044F \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u0443\u0435\u043C\u044B\u0445 \u043A\u0430\u043D\u0430\u043B\u043E\u0432 \u0441\u0432\u044F\u0437\u0438, \u0441\u0441\u044B\u043B\u043A\u0438 \u0441\u043E\u0437\u0434\u0430\u0434\u0443\u0442\u0441\u044F \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u0435\u0441\u043A\u0438.",
                React.createElement("br", null),
                "\u041F\u043E\u043B\u0443\u0447\u0435\u043D\u043D\u044B\u0439 \u043A\u043E\u0434 \u0432\u0438\u0434\u0436\u0435\u0442\u0430 \u0432\u0441\u0442\u0430\u0432\u044C\u0442\u0435 \u0432\u043D\u0443\u0442\u0440\u044C \u0442\u044D\u0433\u0430\u00A0",
                React.createElement("mark", { className: 'text-orange bgNone' }, "<body> </body>"),
                " \u0432\u0430\u0448\u0435\u0433\u043E \u0441\u0430\u0439\u0442\u0430.")),
        React.createElement("div", { className: 'flex' },
            React.createElement("div", { className: 'mr-13 w-91 flex flex-col' },
                React.createElement("span", { className: 'text-smaller font-semibold text-gray-main mb-2' }, "WhatsApp"),
                React.createElement(PatternFormat, { format: '+7 ### ### ####', value: phone, mask: '_', className: 'input', displayType: 'input', placeholder: '+7 XXX XXX XX XX', type: 'text', onChange: ({ target: { value } }) => setPhone(value), "data-testid": 'SettingsWidget_phoneInput' })),
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("span", { className: 'text-smaller font-semibold text-gray-main mb-2' }, "\u041A\u043E\u0434 \u0432\u0438\u0434\u0436\u0435\u0442\u0430"),
                React.createElement("div", { className: 'p-3 flex rounded-lg bg-gray-15' },
                    React.createElement("div", { className: 'w-6 relative mr-3' },
                        React.createElement(ButtonsCopy, { textToCopy: codeTemplate })),
                    React.createElement("h5", { style: { whiteSpace: 'pre-wrap' }, className: 'text-gray-85', "data-testid": 'SettingsWidget_codeTemplate' }, codeTemplate))))));
    return renderContent();
};
export default SettingsWidget;
