import { getRubFormattedNumber } from 'functions/data';
// Получение статуса метрики
export const getMetricStatus = (metric, averageMetric) => {
    if (!metric)
        return 'notRated';
    if (!averageMetric)
        return 'average';
    const metricNumber = +metric;
    const averageMetricNumber = +averageMetric;
    const averageRangePercent = 0.1;
    const averageRange = averageMetricNumber * averageRangePercent;
    const lowerExtremeValue = averageMetricNumber - averageRange;
    const greaterExtremeValue = averageMetricNumber + averageRange;
    let status = 'average';
    if (metricNumber < lowerExtremeValue)
        status = 'bad';
    if (metricNumber > greaterExtremeValue)
        status = 'good';
    return status;
};
// Получение отформатированных метрик по Семёнову
export default function getFormattedSemenovMetrics(metrics, averageMetrics) {
    const { conversionPercentage, treatsCountPerClient, 
    // TODO: метрика "Показатель повторности" временно убрана
    // repetitionRate,
    sumOfRevenueByDoctor, consultationRevenue, doctorsWorkload, doctorsWorkloadBySchedule, averageHourlyCost, averageCostPerVisit } = metrics;
    const resultData = {
        conversionPercentage: {
            text: conversionPercentage == null ? '' : `${conversionPercentage}%`,
            status: getMetricStatus(conversionPercentage, averageMetrics?.conversionPercentage)
        },
        treatsCountPerClient: {
            text: treatsCountPerClient == null ? '' : String(treatsCountPerClient),
            status: getMetricStatus(treatsCountPerClient, averageMetrics?.treatsCountPerClient)
        },
        // TODO: метрика "Показатель повторности" временно убрана
        // repetitionRate: {
        //   text: repetitionRate == null ? '' : (+repetitionRate).toFixed(1),
        //   status: getMetricStatus(repetitionRate, averageMetrics?.repetitionRate)
        // },
        sumOfRevenueByDoctor: {
            text: sumOfRevenueByDoctor ? getRubFormattedNumber(sumOfRevenueByDoctor) : '',
            status: getMetricStatus(sumOfRevenueByDoctor, averageMetrics?.sumOfRevenueByDoctor)
        },
        consultationRevenue: {
            text: consultationRevenue ? getRubFormattedNumber(consultationRevenue) : '',
            status: getMetricStatus(consultationRevenue, averageMetrics?.consultationRevenue)
        },
        doctorsWorkload: {
            text: doctorsWorkload == null ? '' : `${doctorsWorkload}%`,
            status: getMetricStatus(doctorsWorkload, averageMetrics?.doctorsWorkload)
        },
        doctorsWorkloadBySchedule: {
            text: doctorsWorkloadBySchedule == null ? '' : `${doctorsWorkloadBySchedule}%`,
            status: getMetricStatus(doctorsWorkloadBySchedule, averageMetrics?.doctorsWorkloadBySchedule)
        },
        averageHourlyCost: {
            text: averageHourlyCost ? getRubFormattedNumber(averageHourlyCost) : '',
            status: getMetricStatus(averageHourlyCost, averageMetrics?.averageHourlyCost)
        },
        averageCostPerVisit: {
            text: averageCostPerVisit ? getRubFormattedNumber(averageCostPerVisit) : '',
            status: getMetricStatus(averageCostPerVisit, averageMetrics?.averageCostPerVisit)
        }
    };
    return resultData;
}
