import React from 'react';
import Button from 'helpers/Button';
import { classNames } from 'functions/Main';
// Компонент опции селектора
const SelectOption = ({ data, selectOption, selectProps, isSelected, maxItems }) => {
    const isDisabled = !!(!isSelected && selectProps.value instanceof Array && maxItems && selectProps.value.length >= maxItems);
    return (React.createElement(Button, { className: classNames('py-2 px-3 flex items-center selectOption', { selectOption_selected: isSelected }, []), disabled: isDisabled, onClick: () => selectOption(data) },
        React.createElement("span", { className: 'text-gray-75 text-smaller' }, data.label)));
};
export default SelectOption;
