import React, { useMemo, useState } from 'react';
import { BarController, BarElement, CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineController, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import ChartsLegend from 'components/charts/base/Legend';
import ChartsPlaceholder from 'components/charts/Placeholder';
import TooltipsBase from 'components/tooltips/Base';
import getChartJsOptions from 'functions/dashboards/getChartJsOptions';
ChartJS.register(CategoryScale, LinearScale, BarController, BarElement, PointElement, LineController, LineElement, Filler, Title, Tooltip, Legend);
// Добавление кастомного позиционирования тултипа
Tooltip.positioners.custom = function custom(elements, position) {
    const { chartArea: { bottom }, scales: { x, y } } = this.chart;
    const barHeight = elements.reduce((acc, item) => acc + item.element.height, 0);
    return {
        x: x.getPixelForValue(x.getValueForPixel(position.x) || 0),
        y: y.getPixelForValue(y.getValueForPixel(bottom - barHeight) || 0),
        xAlign: 'center',
        yAlign: 'bottom'
    };
};
// Основное поле графика
const ChartsField = ({ id, data, type, getTooltipText, legend, chartColor, getYTickText, getXTickText, options }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const chartOptions = useMemo(() => getChartJsOptions({
        chartType: type,
        getTooltipText,
        tooltip: {
            tooltipElementId: `${id}_tooltipElement`,
            setIsTooltipOpen
        },
        chartColor,
        getYTickText,
        getXTickText,
        options
    }), [type, id, chartColor, options, getXTickText, getYTickText]);
    // Вывод графика
    const renderChart = () => (React.createElement(React.Fragment, null,
        legend && React.createElement(ChartsLegend, { items: legend }),
        React.createElement("div", { className: 'h-64 relative' },
            React.createElement(Chart, { type: type, data: data, options: chartOptions }),
            React.createElement(TooltipsBase, { id: id, isOpen: isTooltipOpen }),
            React.createElement("div", { id: `${id}_tooltipElement`, "data-tooltip-id": id, "data-testid": 'ChartsField_tooltipElement' }))));
    const renderContent = () => (React.createElement("div", { className: 'flex flex-col w-full' }, data.labels && data.labels.length > 1 ? (renderChart()) : (React.createElement(ChartsPlaceholder, null))));
    return renderContent();
};
export default ChartsField;
