import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { classNames } from 'functions/Main';
import 'react-tabs/style/react-tabs.css';
// Компонент обёртки табов
const TabsWrapper = ({ tabs, tabKey, setTabKey, className }) => {
    const tabIndex = tabs.findIndex(item => item.key === tabKey);
    const selectedTabIndex = tabIndex === -1 ? 0 : tabIndex;
    // Обработка переключения таба
    const onSelect = (index) => {
        setTabKey(tabs[index].key);
        return true;
    };
    const renderContent = () => (React.createElement(Tabs, { className: classNames('tabs', {}, [className || '']), selectedTabClassName: 'tabs__listWrapper__list__tab_selected', "data-testid": 'TabsWrapper', selectedIndex: selectedTabIndex, onSelect: onSelect },
        React.createElement("div", { className: 'tabs__listWrapper' },
            React.createElement(TabList, { className: 'tabs__listWrapper__list', "data-testid": 'TabsWrapper_tabsList' }, tabs.map(({ title }) => React.createElement(Tab, { className: 'tabs__listWrapper__list__tab', key: title }, title))),
            React.createElement("div", { className: 'tabs__listWrapper__border' })),
        tabs.map(({ title, renderComponent }) => (React.createElement(TabPanel, { key: title, selectedClassName: 'tabs__panel' }, renderComponent())))));
    return renderContent();
};
export default TabsWrapper;
