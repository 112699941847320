import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ButtonsBack from 'components/buttons/Back';
import InputSearch from 'helpers/Input/Search';
import ServicesDashboardAvailableItems from 'helpers/Services/Dashboard/AvailableItems';
import ServicesDashboardChangeButtons from 'helpers/Services/Dashboard/ChangeButtons';
import ServicesDashboardItemsInCategory from 'helpers/Services/Dashboard/ItemsInCategory';
import Layout from 'helpers/Layout';
import ServicesDashboardWrapper from 'helpers/Services/Dashboard/Wrapper';
import { handleErrors, useShowError } from 'functions/Errors';
import { isSomeSelected } from 'functions/services';
import { loadingHandler } from 'functions/data';
import useFetch from 'functions/Api';
import { apiUrls, apiWrapper } from 'config/api';
import navigationUrls from 'navigation/routes';
// Страница настройки этапа сценария лечения
const ViewsSettingsTreatScenariosStage = () => {
    const { scenarioId, stageId } = useParams();
    const [searchParams] = useSearchParams();
    const stageName = searchParams.get('stageName');
    const scenarioName = searchParams.get('scenarioName');
    const [searchValue, setSearchValue] = useQueryParam('serviceName', StringParam);
    const [availableServices, setAvailableServices] = useState([]);
    const [availableServicesPage, setAvailableServicesPage] = useState(1);
    const [servicesInStage, setServicesInStage] = useState([]);
    const [servicesInStagePage, setServicesInStagePage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const setError = useShowError();
    const navigate = useNavigate();
    const { data: availableServicesData, error: availableServicesError, refetch: refetchAvailableServices, loading: isAvailableServicesLoading } = useFetch(apiUrls.services.notInStage, 'get', { treat_scenario_stage_id: stageId, service_name: searchValue, page: availableServicesPage });
    const { data: servicesInStageData, error: servicesInStageError, refetch: refetchServicesInStage, loading: isServicesInStageLoading } = useFetch(apiUrls.treatScenarios.services.list(scenarioId, stageId), 'get', { page: servicesInStagePage });
    useEffect(() => {
        const error = availableServicesError || servicesInStageError;
        if (error)
            setError(error);
    }, [availableServicesError, servicesInStageError]);
    useEffect(() => {
        setIsLoading(isAvailableServicesLoading || isServicesInStageLoading);
    }, [isAvailableServicesLoading, isServicesInStageLoading]);
    useEffect(() => {
        if (servicesInStageData) {
            setServicesInStage((prev) => {
                const services = servicesInStagePage === 1 ?
                    servicesInStageData.services :
                    [...prev, ...servicesInStageData.services];
                return services;
            });
        }
    }, [servicesInStageData]);
    useEffect(() => {
        if (availableServicesData) {
            setAvailableServices((prev) => {
                const services = availableServicesPage === 1 ?
                    availableServicesData.services :
                    [...prev, ...availableServicesData.services];
                return services;
            });
        }
    }, [availableServicesData]);
    // Если в поле поиска активно, запросить найденые сервисы
    useEffect(() => {
        if (searchValue) {
            handleRefetchAvailableServices();
        }
    }, [searchValue]);
    const handleRefetchAvailableServices = () => {
        if (availableServicesPage === 1) {
            refetchAvailableServices();
        }
        else {
            setAvailableServicesPage(1);
        }
    };
    const handleRefetchServicesInStage = () => {
        if (servicesInStagePage === 1) {
            refetchServicesInStage();
        }
        else {
            setServicesInStagePage(1);
        }
    };
    // Добавление/удаление услуг из этапа сценария лечения
    const changeServices = async (isAvailableServicesToChange) => {
        const servicesIds = (isAvailableServicesToChange ? availableServices : servicesInStage)
            .reduce((acc, { id: serviceId, isChecked }) => {
            if (isChecked)
                acc.push(serviceId);
            return acc;
        }, []);
        const formData = { new_service_ids: servicesIds };
        const response = await apiWrapper.patch(apiUrls.treatScenarios.services.changeService(scenarioId, stageId), formData);
        if (response.ok) {
            handleRefetchAvailableServices();
            handleRefetchServicesInStage();
        }
        else
            setError(handleErrors(response));
    };
    // Вывод кнопок переноса сервисов
    const renderChangeButtons = () => {
        const addButtonParams = {
            onClick: () => loadingHandler(() => changeServices(true), setIsLoading),
            isDisabled: !isSomeSelected(availableServices)
        };
        const removeButtonPros = {
            onClick: () => loadingHandler(() => changeServices(false), setIsLoading),
            isDisabled: !isSomeSelected(servicesInStage)
        };
        return (React.createElement(ServicesDashboardChangeButtons, { addButton: addButtonParams, removeButton: removeButtonPros }));
    };
    // Вывод инпута для поиска услуг
    const renderSearchInput = () => (React.createElement(InputSearch, { value: searchValue, onChangeSearch: setSearchValue, testId: 'ServicesSearch', wrapperClassName: 'mt-4', minSymbolsToSearch: 0 }));
    // Вывод дашборда для переноса сервисов
    const renderServicesDashboard = () => (React.createElement(ServicesDashboardWrapper, { isLoading: isLoading }, (availableServicesData && servicesInStageData) && (React.createElement(React.Fragment, null,
        React.createElement(ServicesDashboardAvailableItems, { availableItems: availableServices, setAvailableItems: setAvailableServices, title: '\u0421\u043F\u0440\u0430\u0432\u043E\u0447\u043D\u0438\u043A \u0443\u0441\u043B\u0443\u0433', renderButtons: renderChangeButtons, renderSearchInput: renderSearchInput, page: availableServicesPage, kind: 'available', totalPages: availableServicesData.pagination?.pages, loadMore: () => setAvailableServicesPage(prev => prev + 1) }),
        React.createElement(ServicesDashboardItemsInCategory, { itemsInCategory: servicesInStage, setItemsInCategory: setServicesInStage, title: `Услуги в ${stageName}`, placeholder: '\u041D\u0435\u0442 \u0443\u0441\u043B\u0443\u0433 \u0432 \u044D\u0442\u043E\u043C \u044D\u0442\u0430\u043F\u0435', page: servicesInStagePage, kind: 'in_category', totalPages: servicesInStageData.pagination?.pages, loadMore: () => setServicesInStagePage(prev => prev + 1) })))));
    const renderContent = () => (React.createElement(Layout, { className: 'flex flex-col' },
        React.createElement(ButtonsBack, { text: scenarioName || 'Список', className: 'mb-1', onClick: () => navigate(navigationUrls.settings.treatScenarios.item.index(scenarioId)) }),
        React.createElement("h1", { className: 'mb-8' }, stageName),
        renderServicesDashboard()));
    return renderContent();
};
export default ViewsSettingsTreatScenariosStage;
