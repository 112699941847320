import React from 'react';
import { components } from 'react-select';
import { IconCross } from 'svg/common';
// Вывод индикатора очистки селекта
const ClearIndicator = (props) => {
    const renderContent = () => (React.createElement(components.ClearIndicator, { ...props, className: 'cursor-pointer' },
        React.createElement(IconCross, { className: 'fill-primary w-3 h-3', "data-testid": 'ClearIndicator_icon' })));
    return renderContent();
};
export default ClearIndicator;
