import React from 'react';
import Button from 'helpers/Button';
import { IconWhatsApp } from 'svg/common';
import { classNames } from 'functions/Main';
// Вывод кнопки мессенджера в истории сообщений с пациентом
const PatientsHistoriesMessagesMessengerButton = ({ title, isSelected, onClick }) => {
    const renderContent = () => (React.createElement(Button, { onClick: onClick, className: classNames('border-b-2 border-white', { 'border-primary': isSelected }, ['px-3 py-2.5 flex items-center']), "data-testid": 'MessengerButton' },
        React.createElement(IconWhatsApp, { className: classNames('w-6 h-6 mr-4', {}, [isSelected ? 'fill-primary' : 'fill-gray-bi']) }),
        React.createElement("h5", { className: 'font-semibold text-gray-bi' }, title)));
    return renderContent();
};
export default PatientsHistoriesMessagesMessengerButton;
