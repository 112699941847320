import React from 'react';
import Layout from 'helpers/Layout';
import NavList from 'helpers/NavList';
import navigationUrls from 'navigation/routes';
// Дашборды
const dashboards = [
    {
        id: 'common',
        name: 'Общий',
        to: navigationUrls.dashboards.common
    },
    {
        id: 'semenov',
        name: 'Дашборд по врачам',
        to: navigationUrls.dashboards.doctors.index
    },
    {
        id: 'categories',
        name: 'Дашборд по категориям',
        to: navigationUrls.dashboards.categories
    }
];
// Компонент страницы Дашборды
const ViewsDashboardsIndex = () => {
    const renderContent = () => (React.createElement(Layout, { testId: 'ViewsTemplatesIndex', className: 'flex flex-col' },
        React.createElement("h1", { className: 'mb-8' }, "\u0414\u0430\u0448\u0431\u043E\u0440\u0434\u044B"),
        React.createElement(NavList, { list: dashboards })));
    return renderContent();
};
export default ViewsDashboardsIndex;
