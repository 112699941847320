import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import MainApp from 'main/App';
import setMoment from 'config/moment';
import './index_style.pcss';
const entrypointElement = document.getElementById('app_entrypoint');
if (!entrypointElement)
    throw new Error('Root element not found');
const root = ReactDOMClient.createRoot(entrypointElement);
setMoment();
root.render(React.createElement(MainApp, null));
