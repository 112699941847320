import React, { useEffect } from 'react';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { useNavigate } from 'react-router-dom';
import CardsBase from 'components/cards/Base';
import Button from 'helpers/Button';
import Loader from 'helpers/Loader';
import { IconArrowSort } from 'svg/common';
import { classNames } from 'functions/Main';
import { momentUtcToLocal } from 'functions/date';
import { useCubeFetch } from 'functions/cubeApi';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { cubeApi } from 'config/cubeApi';
import navigationUrls from 'navigation/routes';
// Таблица с метриками на странице описания процента конверсии
const ConversionPercentageTable = ({ dateRange, doctorId }) => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const { resultSet: treatsResultSet, error: treatsError, isLoading: isTreatsLoadings } = useCubeFetch(cubeApi.description.conversationPercentage.table({ clinicId, dateRange, doctorId }, 'treat'));
    const treatsData = treatsResultSet?.rawData();
    const treatsClientsIds = treatsData?.map(treat => treat['treats.client_id']);
    const { resultSet: consultationsResultSet, error: consultationsError, isLoading: isConsultationsLoadings } = useCubeFetch(cubeApi.description.conversationPercentage.table({ clinicId, dateRange, doctorId }, 'consultation'));
    const consultationsData = consultationsResultSet?.rawData();
    const setError = useShowError();
    const navigate = useNavigate();
    const isLoading = isTreatsLoadings || isConsultationsLoadings;
    useEffect(() => {
        const error = treatsError || consultationsError;
        if (error)
            setError(error.message);
    }, [treatsError, consultationsError]);
    // Вывод шапки аккордеона
    const renderAccordionHeader = (text) => (React.createElement("div", { className: 'h-12.5 w-full bg-gray-10 flex', "data-testid": 'ConversionPercentageTable_accordionHeader' },
        React.createElement("div", { className: 'w-17 h-full flex flexCenter' },
            React.createElement(IconArrowSort, { className: 'accordionHeaderIcon w-5 h-5 fill-gray-bi' })),
        React.createElement("div", { className: 'flex items-center' },
            React.createElement("span", { className: 'text-normal font-semibold text-gray-bi' }, text))));
    // Вывод ряда с датой лечения и ФИО
    const renderRow = (treat, highlighted = false) => {
        const date = treat['treats.treat_date'];
        const clientName = treat['treats.client_name'];
        const clientId = treat['treats.client_id'];
        return (React.createElement(Button, { className: classNames('h-9 flex items-center onRowHover', { 'bg-blue-cold': highlighted }), key: date + clientId, onClick: () => navigate(navigationUrls.patients.item(clientId)), testId: 'ConversionPercentageTable_row' },
            React.createElement("div", { className: 'w-78 pl-17 flex' },
                React.createElement("span", { className: 'text-small text-gray-75' }, momentUtcToLocal(date).format('DD.MM.YYYY'))),
            React.createElement("div", null,
                React.createElement("span", { className: 'text-small text-gray-75' }, clientName))));
    };
    // Вывод тела таблицы
    const renderTableBody = () => (React.createElement("div", { className: 'dashboardsSemenovMetricDescriptionTable__dynamicContent relative flex flexCenter' },
        (treatsData && consultationsData) && (React.createElement("div", { className: 'w-full' },
            React.createElement(Accordion, { transition: true },
                React.createElement(AccordionItem, { header: renderAccordionHeader('Первичные консультации') }, consultationsData.map(treat => (renderRow(treat, treatsClientsIds?.includes(treat['treats.client_id'])))))),
            React.createElement(Accordion, { transition: true },
                React.createElement(AccordionItem, { header: renderAccordionHeader('Первичные лечения') }, treatsData.map(treat => renderRow(treat)))))),
        isLoading && React.createElement(Loader, { className: 'absolute h-full' })));
    // Вывод подсказки
    const renderHint = () => (React.createElement("div", { className: 'mb-6.5 flex' },
        React.createElement("div", { className: 'mr-4 w-4.5 h-4.5 rounded-full bg-blue-cold' }),
        React.createElement("span", { className: 'text-small text-gray-75' }, "\u041F\u0430\u0446\u0438\u0435\u043D\u0442\u044B, \u043E\u0441\u0442\u0430\u0432\u0448\u0438\u0435\u0441\u044F \u043D\u0430 \u043B\u0435\u0447\u0435\u043D\u0438\u0435")));
    const renderContent = () => (React.createElement(React.Fragment, null,
        renderHint(),
        React.createElement(CardsBase, { className: 'pb-4' },
            React.createElement("div", { className: 'h-15 pt-6.5 flex' },
                React.createElement("div", { className: 'w-78 pl-17' },
                    React.createElement("span", { className: 'text-smallest font-semibold text-gray-main' }, "\u0414\u0430\u0442\u0430 \u043F\u0440\u0438\u0435\u043C\u0430 \u043F\u0430\u0446\u0438\u0435\u043D\u0442\u0430")),
                React.createElement("div", null,
                    React.createElement("span", { className: 'text-smallest font-semibold text-gray-main' }, "\u0424\u0418\u041E \u043F\u0430\u0446\u0438\u0435\u043D\u0442\u0430"))),
            renderTableBody())));
    return renderContent();
};
export default ConversionPercentageTable;
