import React, { useEffect, useState } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useParams } from 'react-router-dom';
import CardsBase from 'components/cards/Base';
import TableTextCell from 'components/table/TextCell';
import TableWrapperFlex from 'components/table/WrapperFlex';
import Loader from 'helpers/Loader';
import Paginator from 'helpers/Paginator';
import { getShortenName } from 'functions/data';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { apiUrls } from 'config/api';
import { useAppSelector } from 'redux/hooks';
// Шапка таблицы
const tableHead = (React.createElement("div", { className: 'tableRow' },
    React.createElement("div", { className: 'text-smallest font-semibold text-gray-main pl-6' }, "\u0414\u0430\u0442\u0430 \u0438 \u0432\u0440\u0435\u043C\u044F \u043F\u0440\u0438\u0435\u043C\u0430"),
    React.createElement("div", { className: 'text-smallest font-semibold text-gray-main' }, "\u0412\u0440\u0430\u0447"),
    React.createElement("div", { className: 'text-smallest font-semibold text-gray-main' }, "\u041A\u043B\u0438\u043D\u0438\u043A\u0430"),
    React.createElement("div", { className: 'text-smallest font-semibold text-gray-main' }, "\u041F\u0440\u043E\u0446\u0435\u0434\u0443\u0440\u044B"),
    React.createElement("div", { className: 'text-smallest font-semibold text-gray-main pr-6' }, "\u0421\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C")));
// Вывод таблицы с лечениями пациента
const PatientsHistoriesTreatsTable = () => {
    const { id: clientId } = useParams();
    const [page, setPage] = useQueryParam('page', NumberParam);
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const [totalPages, setTotalPages] = useState(0);
    const { data, loading, error: fetchTreatsError, isDataFetched, refetch } = useFetch(apiUrls.clients.treats(clientId), 'get', { page: (page || 0) + 1, clinic_id: clinicId });
    const setShowError = useShowError();
    useEffect(() => {
        if (isDataFetched)
            refetch();
    }, [clinicId]);
    // Установка кол-ва страниц при получении данных
    useEffect(() => {
        if (data?.pagination?.pages)
            setTotalPages(data.pagination.pages);
    }, [data]);
    // Обработка ошибки
    useEffect(() => {
        if (fetchTreatsError)
            setShowError(fetchTreatsError);
    }, [fetchTreatsError]);
    // Вывод лечения
    const renderTreat = ({ id, treat_datetime: treatDate, doctor: { full_name: doctorName }, clinic: { name: clinicName }, services, cost }) => (React.createElement("div", { key: id, className: 'tableRow text-gray-75' },
        React.createElement("div", { className: 'text-small pl-6' }, treatDate),
        React.createElement("div", { className: 'text-small' }, getShortenName(doctorName)),
        React.createElement("div", { className: 'text-small' }, clinicName),
        React.createElement(TableTextCell, { text: services, textClassName: 'text-small', tooltipId: 'ViewsPatientsItem' }),
        React.createElement("div", { className: 'text-small pr-6' }, cost)));
    // Вывод лечений
    const renderTreats = (treats) => (React.createElement(CardsBase, { className: 'overflow-hidden py-4' }, treats.length > 0 ? (React.createElement(TableWrapperFlex, { thead: tableHead, className: 'patientsHistoriesTreatsTable__table', bodyClassName: 'gap-3', isLoading: loading }, treats.map(renderTreat))) : (React.createElement("div", { className: 'flex flex-1 flexCenter' }, "\u0418\u0441\u0442\u043E\u0440\u0438\u044F \u043F\u043E\u0441\u0435\u0449\u0435\u043D\u0438\u0439 \u043F\u0443\u0441\u0442\u0430"))));
    const renderContent = () => (data ? (React.createElement(React.Fragment, null,
        renderTreats(data.treats),
        (data.treats.length > 0 && totalPages > 1) && (React.createElement(Paginator, { page: page, setPage: setPage, pageCount: totalPages, className: 'py-7' })))) : (React.createElement(Loader, { className: 'h-full' })));
    return renderContent();
};
export default PatientsHistoriesTreatsTable;
