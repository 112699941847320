import React from 'react';
import ButtonsMain from 'components/buttons/Main';
import ModalsLayout from './Layout';
// Модалка с сообщением об успешном действии
const ModalsSuccess = ({ onClose, ...otherProps }) => {
    const renderContent = () => (React.createElement(ModalsLayout, { onClose: onClose, ...otherProps, withButtonClose: false },
        React.createElement(ButtonsMain, { className: 'mt-8 px-12 w-auto', onClick: onClose, "data-testid": 'ModalsSuccess_button' }, "\u0425\u043E\u0440\u043E\u0448\u043E")));
    return renderContent();
};
export default ModalsSuccess;
