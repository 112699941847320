import React from 'react';
import Layout from 'helpers/Layout';
import CampaignsLandingAdvantage from 'components/campaigns/landing/advantage/Index';
import CampaignsLandingHeader from 'components/campaigns/landing/Header';
import ImageAwakeSleepersPng from 'images/campaigns/AwakeSleepers.png';
import ImageAwakeSleepersWebp from 'images/campaigns/AwakeSleepers.png?as=webp';
import ImageIncreaseIncomePng from 'images/campaigns/IncreaseIncome.png';
import ImageIncreaseIncomeWebp from 'images/campaigns/IncreaseIncome.png?as=webp';
import ImageOptimizeResourcesPng from 'images/campaigns/OptimizeResources.png';
import ImageOptimizeResourcesWebp from 'images/campaigns/OptimizeResources.png?as=webp';
import ImagePatientLoyaltyPng from 'images/campaigns/PatientLoyalty.png';
import ImagePatientLoyaltyWebp from 'images/campaigns/PatientLoyalty.png?as=webp';
import ImagePatientReachPng from 'images/campaigns/PatientReach.png';
import ImagePatientReachWebp from 'images/campaigns/PatientReach.png?as=webp';
const campaignsAdvantages = [
    {
        id: 1,
        title: 'Увеличить доход клиники:',
        text: 'Увеличение доходов вашей клиники благодаря эффективному ' +
            'использованию рассылок для привлечения новых клиентов и удержания существующих.',
        image: {
            png: ImageIncreaseIncomePng,
            webp: ImageIncreaseIncomeWebp
        }
    },
    {
        id: 2,
        title: 'Оптимизировать ресурсы клиники:',
        text: 'Экономия ресурсов вашей клиники с помощью автоматизированных рассылок, ' +
            'сокращающих временные и финансовые затраты на взаимодействие с пациентами.',
        image: {
            png: ImageOptimizeResourcesPng,
            webp: ImageOptimizeResourcesWebp
        },
        isReverse: true
    },
    {
        id: 3,
        title: 'Повысить доходимость пациентов:',
        text: 'Повышение доходимости клиники с помощью эффективных коммуникаций, напоминающих ' +
            'о приёме и автоматической обработки ответов пациентов.',
        image: {
            png: ImagePatientReachPng,
            webp: ImagePatientReachWebp
        }
    },
    {
        id: 4,
        title: 'Укрепить лояльность пациентов:',
        text: 'Увеличение уровня лояльности пациентов благодаря персонализированным рассылкам, ' +
            'которые позволяют создать более тесные связи и почувствовать заботу и внимание к каждому клиенту.',
        image: {
            png: ImagePatientLoyaltyPng,
            webp: ImagePatientLoyaltyWebp,
            width: 250,
            height: 325
        },
        isReverse: true
    },
    {
        id: 5,
        title: 'Пробудить «спящих» пациентов:',
        text: 'Оживление «спящих» пациентов, возвращение их в вашу клинику через эффективные и заботливые рассылки.',
        image: {
            png: ImageAwakeSleepersPng,
            webp: ImageAwakeSleepersWebp
        }
    }
];
// Страница лэндинга рассылок
const ViewsCampaignsLanding = () => {
    const renderContent = () => (React.createElement(Layout, { className: 'overflow-auto' },
        React.createElement("h1", { className: 'mb-8' }, "\u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0438"),
        React.createElement("div", { className: 'bg-white' },
            React.createElement("div", { className: 'px-3 pt-6 mb-20' },
                React.createElement(CampaignsLandingHeader, null)),
            React.createElement("div", { className: 'mb-25 px-10 flex flex-col' },
                React.createElement("span", { className: 'text-title-normal mb-10' }, "\u041D\u0430\u0448\u0438 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438 \u043F\u043E\u043C\u043E\u0433\u0443\u0442:"),
                React.createElement("div", { className: 'flex flex-col gap-20' }, campaignsAdvantages.map(({ id, title, text, isReverse, image }) => (React.createElement(CampaignsLandingAdvantage, { key: id, title: title, text: text, image: image, isReverse: isReverse }))))),
            React.createElement("div", { className: 'bg-primary-lighter h-50 w-full flex flexCenter' },
                React.createElement("span", { className: 'text-label-big' }, "\u041F\u0440\u0438\u0441\u043E\u0435\u0434\u0438\u043D\u044F\u0439\u0442\u0435\u0441\u044C \u043A \u043D\u0430\u0448\u0438\u043C \u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0430\u043C")))));
    return renderContent();
};
export default ViewsCampaignsLanding;
