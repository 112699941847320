import { doughnutMaxCount } from 'config/charts/doughnut';
export const topList = {
    specialitiesRevenue: ({ clinicId, dateRange }) => ({
        dimensions: ['treats.doctor_speciality'],
        measures: ['treats.sum_of_revenue'],
        timeDimensions: [
            {
                dimension: 'treats.treat_date',
                dateRange
            }
        ],
        filters: [
            {
                member: 'treats.clinic_id',
                operator: 'equals',
                values: [
                    clinicId
                ]
            }
        ],
        limit: doughnutMaxCount,
        order: [
            [
                'treats.sum_of_revenue',
                'desc'
            ]
        ]
    }),
    doctorsRevenue: ({ clinicId, dateRange }) => ({
        dimensions: [
            'treats.doctor_id',
            'treats.doctor_full_name'
        ],
        measures: ['treats.sum_of_revenue'],
        timeDimensions: [
            {
                dimension: 'treats.treat_date',
                dateRange
            }
        ],
        filters: [
            {
                member: 'treats.clinic_id',
                operator: 'equals',
                values: [
                    clinicId
                ]
            }
        ],
        limit: doughnutMaxCount,
        order: [
            [
                'treats.sum_of_revenue',
                'desc'
            ]
        ]
    })
};
