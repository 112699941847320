import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'helpers/Button';
import { IconArrowLeft } from 'svg/common';
// Компонент кнопки возвращения на предыдущую страницу
const ButtonsBack = ({ text, className, onClick }) => {
    const navigate = useNavigate();
    // Обработка нажатия на кнопку
    const onButtonClick = () => {
        if (onClick)
            onClick();
        else
            navigate(-1);
    };
    const renderContent = () => (React.createElement(Button, { onClick: onButtonClick, className: `buttonsBack flex items-center ${className}`, testId: 'ButtonBack' },
        React.createElement(IconArrowLeft, { className: 'fill-primary' }),
        React.createElement("span", { className: 'text-smaller text-primary' }, text)));
    return renderContent();
};
export default ButtonsBack;
