import { getRubFormattedNumber } from 'functions/data';
export const linearCategoriesMetrics = {
    sumOfRevenue: {
        title: 'Выручка',
        tickText: value => getRubFormattedNumber(+value),
        stats: {
            tooltipText: 'Выручка',
            valueKey: 'sum_of_revenue',
            dateValueKey: 'treat_date',
            cubeKey: 'treats'
        }
    },
    treatsTotalCount: {
        title: 'Количество оказанных услуг',
        tickText: value => value.toString(),
        stats: {
            tooltipText: 'Количество услуг',
            valueKey: 'total_count',
            dateValueKey: 'treat_date',
            cubeKey: 'treats'
        }
    }
};
