import React, { useEffect } from 'react';
import TemplatesList from 'components/templates/List';
import Layout from 'helpers/Layout';
import Loader from 'helpers/Loader';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { apiUrls } from 'config/api';
import { useAppSelector } from 'redux/hooks';
// Компонент страницы Шаблоны
const ViewsTemplatesIndex = () => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const { data, error, isDataFetched, refetch, loading } = useFetch(apiUrls.templates.list, 'get', { clinic_id: clinicId });
    const setError = useShowError();
    useEffect(() => {
        if (error)
            setError(error);
    }, [error]);
    useEffect(() => {
        if (isDataFetched)
            refetch();
    }, [clinicId]);
    const renderContent = () => (React.createElement(Layout, { testId: 'ViewsTemplatesIndex', className: 'flex flex-col' },
        React.createElement("h1", { className: 'mb-8' }, "\u0428\u0430\u0431\u043B\u043E\u043D\u044B"),
        data && !loading ? (React.createElement(TemplatesList, { templates: data.templates })) : (React.createElement(Loader, { className: 'h-1/2' }))));
    return renderContent();
};
export default ViewsTemplatesIndex;
