import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import MainAppLayout from 'main/AppLayout';
import ViewsAuthCode from 'views/auth/Code';
import ViewsAuthEmail from 'views/auth/Email';
export const appRouterAuth = [
    {
        element: React.createElement(MainAppLayout, null),
        children: [
            {
                path: 'auth/email',
                element: React.createElement(ViewsAuthEmail, null)
            },
            {
                path: 'auth/code',
                element: React.createElement(ViewsAuthCode, null)
            },
            {
                path: '*',
                element: React.createElement(Navigate, { to: 'auth/email' })
            }
        ]
    }
];
export const routerAuth = createBrowserRouter(appRouterAuth);
