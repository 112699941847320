import ImageMetaclinic from 'images/ImageMetaclinic.png';
import { easeInOut } from 'functions/canvas';
import randomizeTriangleProps from './randomizeTriangleProps';
import renderImage from './renderImage';
import renderTriangle from './renderTriangle';
import { logoData, triangles } from './data';
// Отрисовка логотипа с анимацией
export default function renderLogo(canvas, onComplete) {
    const { renderDuration, finishDelay } = logoData;
    const img = new Image();
    img.src = ImageMetaclinic;
    const randomizedTriangles = randomizeTriangleProps(triangles);
    img.onload = () => {
        const startTime = performance.now();
        function animate(currentTime) {
            const ctx = canvas.getContext('2d');
            if (!ctx)
                return;
            // Определение прогресса анимации
            const timeDelta = currentTime - startTime;
            const fraction = Math.min(timeDelta / renderDuration, 1);
            const progress = easeInOut(fraction);
            // Очистка холста перед новой отрисовкой
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            // Отрисовка элементов логотипа
            randomizedTriangles.forEach(rect => renderTriangle(canvas, rect, progress));
            // Отрисовка картинки логотипа
            renderImage(canvas, img, progress);
            if (progress < 1) {
                requestAnimationFrame(animate);
            }
            else {
                setTimeout(() => {
                    onComplete();
                }, finishDelay);
            }
        }
        requestAnimationFrame(animate);
    };
}
