import React from 'react';
import DashboardsColorDescription from 'components/dashboards/ColorDescription';
import DashboardsSemenovTableHeader from 'components/dashboards/semenov/table/Header';
import DashboardsSemenovTableRow from 'components/dashboards/semenov/table/Row';
import Tooltip from 'components/tooltips/Main';
import Loader from 'helpers/Loader';
import { getFormattedSpeciality, sortSpecialtiesAlphabetically } from 'functions/dashboards';
import { useSemenovFetch } from 'functions/cubeApi';
const dashboardStatuses = ['notRated', 'good', 'average', 'bad'];
// Таблица метрик по Семёнову
const DashboardsSemenovTableIndex = ({ dateRange }) => {
    const { data: dataSpecialities, error: errorSpecialities, isLoading: isSpecialitiesLoading } = useSemenovFetch({ dateRange, dimension: 'specialities' });
    const { data: dataDoctors, error: errorDoctors, isLoading: isDoctorsLoading } = useSemenovFetch({ dateRange });
    const isLoading = isSpecialitiesLoading || isDoctorsLoading;
    const error = errorSpecialities || errorDoctors;
    // Вывод специализации
    const renderSpecialization = (speciality, doctors) => (React.createElement("div", { key: speciality.speciality, className: 'mb-4' },
        React.createElement(DashboardsSemenovTableRow, { metrics: getFormattedSpeciality(speciality, doctors), dateRange: dateRange }),
        doctors.map(doctor => (React.createElement(DashboardsSemenovTableRow, { metrics: doctor, averageMetrics: getFormattedSpeciality(speciality, doctors), key: doctor.id, dateRange: dateRange })))));
    // Вывод тела таблицы статистики
    const renderTableBody = () => {
        const isDataFetched = dataSpecialities && dataDoctors && !isLoading;
        if (error)
            return React.createElement("span", null, error);
        if (isDataFetched && dataSpecialities.length === 0) {
            return React.createElement("span", null, "\u041D\u0435\u0442 \u0434\u0430\u043D\u043D\u044B\u0445 \u0437\u0430 \u0443\u043A\u0430\u0437\u0430\u043D\u043D\u044B\u0439 \u043F\u0435\u0440\u0438\u043E\u0434");
        }
        if (isDataFetched && dataSpecialities.length > 0) {
            return sortSpecialtiesAlphabetically(dataSpecialities).map(speciality => renderSpecialization(speciality, dataDoctors.filter(doctor => doctor.speciality === speciality.speciality)));
        }
        return React.createElement(Loader, { className: 'h-1/2' });
    };
    const renderContent = () => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex mb-4 gap-6' }, dashboardStatuses.map(status => React.createElement(DashboardsColorDescription, { status: status, key: status }))),
        React.createElement("div", { className: 'flex-1 flex flex-col overflow-hidden' },
            React.createElement(DashboardsSemenovTableHeader, null),
            React.createElement("div", { className: 'flex-1 overflow-auto hideScrollbar' }, renderTableBody())),
        React.createElement(Tooltip, { id: 'DashboardsSemenovTable' })));
    return renderContent();
};
export default DashboardsSemenovTableIndex;
