import { createSlice } from '@reduxjs/toolkit';
const initialState = { isLoggedIn: false };
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        makeLogged: state => ({
            ...state,
            isLoggedIn: true
        }),
        updateUserAuth: (state, { payload }) => ({
            ...state,
            auth: { ...payload }
        }),
        updateUserData: (state, { payload }) => ({
            ...state,
            data: { ...state.data, ...payload }
        }),
        remove: () => initialState
    }
});
export const { updateUserAuth, updateUserData, remove: removeUser, makeLogged } = userSlice.actions;
export default userSlice.reducer;
