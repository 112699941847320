const status = {
    noAnswer: 'text-gray-47 bg-gray-47-dim px-3',
    notRecognized: 'text-orange-secondary bg-orange-secondary-dim px-3',
    bad: 'text-red bg-red-dim px-4',
    average: 'text-orange-secondary bg-orange-secondary-dim px-4',
    good: 'text-green bg-green-dim px-4'
};
// Получение класса для распознанного ответа
const getAnsweredClassName = (answer) => {
    switch (true) {
        case +answer < 6:
            return status.bad;
        case +answer < 9:
            return status.average;
        case +answer >= 9:
            return status.good;
        default:
            return status.noAnswer;
    }
};
// Получение класса для отображения ответа клиента
const getAnswerClassName = (answerStatus, answer) => {
    switch (answerStatus) {
        case 'unrecognized':
            return status.notRecognized;
        case 'answered':
            return getAnsweredClassName(answer);
        default:
            return status.noAnswer;
    }
};
export default getAnswerClassName;
