import React from 'react';
import CampaignsLandingAdvantageImage from 'components/campaigns/landing/advantage/Image';
import { classNames } from 'functions/Main';
// Блок с описанием преимущества рассылок
const CampaignsLandingAdvantage = ({ title, text, isReverse, className, image: { png, webp, width, height } }) => {
    const renderContent = () => (React.createElement("div", { className: classNames('flex items-center gap-12', { 'flex-row-reverse': isReverse }, [className || '']), "data-testid": 'CampaignsLandingAdvantage' },
        React.createElement("div", { className: 'flex-1 flex flexCenter' },
            React.createElement(CampaignsLandingAdvantageImage, { srcPng: png, srcWebp: webp, width: width, height: height })),
        React.createElement("div", { className: 'flex-1 flex flex-col' },
            React.createElement("span", { className: 'text-title-normal mb-4' }, title),
            React.createElement("p", { className: 'text-title-smaller font-normal text-gray-75' }, text))));
    return renderContent();
};
export default CampaignsLandingAdvantage;
