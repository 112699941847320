import React from 'react';
import { classNames } from 'functions/Main';
import loader from 'images/loader.gif';
// Компонент лоадера
const Loader = ({ className = '', iconClassName = 'w-11', testId = 'Loader' }) => {
    const renderContent = () => (React.createElement("div", { className: classNames('flex items-center justify-center w-full loader', {}, [className]), "data-testid": testId },
        React.createElement("img", { src: loader, alt: 'loader', className: iconClassName, "data-testid": 'Loader_icon' })));
    return renderContent();
};
export default Loader;
