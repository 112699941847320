import moment from 'moment-timezone';
// Сдвигаем дату на первое число следующего месяца,
// если дата не является первым числом текущего
const getMonthStartDate = (date) => {
    // Проверить, является ли дата первым числом месяца
    if (!date.isSame(date.clone().startOf('month'), 'day')) {
        // Если дата не является первым числом месяца, перенести ее на первое число следующего месяца
        date.add(1, 'month').startOf('month');
    }
    return date;
};
// Получение диапазона дат
const getDateRange = (period) => {
    let startDate = moment();
    let endDate = moment();
    switch (period) {
        case 'thisMonth': {
            startDate = moment().startOf('month');
            break;
        }
        case 'lastMonth': {
            startDate = moment().subtract(1, 'months').startOf('month');
            endDate = moment().startOf('month').subtract(1, 'day');
            break;
        }
        case 'thisQuarter': {
            startDate = moment().startOf('quarter');
            break;
        }
        case 'lastQuarter': {
            startDate = moment().subtract(1, 'quarters').startOf('quarter');
            endDate = moment().startOf('quarter').subtract(1, 'day');
            break;
        }
        case 'halfYear': {
            // Получить дату полгода назад
            const sixMonthsAgo = moment().subtract(6, 'months');
            startDate = getMonthStartDate(sixMonthsAgo);
            break;
        }
        case 'year': {
            // Получить дату год назад
            const yearAgo = moment().subtract(1, 'year');
            startDate = getMonthStartDate(yearAgo);
            break;
        }
    }
    return [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')];
};
export default getDateRange;
