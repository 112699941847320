// Получение имен элементов метрик
// *для отображения нескольких метрик на одном графике
const getMetricElementsNames = (elements, specialityQueryValue) => {
    let metricElementsNames;
    if (elements.length > 0) {
        metricElementsNames = elements.map(item => item.label);
    }
    else {
        metricElementsNames = [specialityQueryValue];
    }
    return metricElementsNames;
};
export default getMetricElementsNames;
