import React, { useCallback, useEffect, useState } from 'react';
import ChartsField from 'components/charts/base/Field';
import ChartsLayout from 'components/charts/base/Layout';
import { getChartJsData, getChartType, getTooltipDateText, getValidName, getXTickDateText } from 'functions/dashboards';
import { useCubeFetch } from 'functions/cubeApi';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { chartTargetLabels, linearMultiMetricsColors } from 'config/charts/linear/settings';
import { cubeApi } from 'config/cubeApi';
import { linearSemenovMetrics } from 'config/charts/linear';
// График детальной статистики метрик Семёнова
const DashboardsSemenovDetailedStatisticChart = ({ metricName, metricElementsNames, dateRange, tooltipId, granularity, specialities, doctorsIds }) => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const [chartJsData, setChartJsData] = useState(null);
    const setError = useShowError();
    const { resultSet, error, isLoading } = useCubeFetch(cubeApi.semenov.detailed({
        clinicId, dateRange, metricName, specialities, doctorsIds, granularity
    }));
    const { tickText, stats: { tooltipText: statsTooltipText }, title, tooltipText } = linearSemenovMetrics[metricName];
    const chartType = getChartType(metricName);
    const legend = metricElementsNames.map((item, index) => ({ label: item, color: linearMultiMetricsColors[index][chartType] }));
    useEffect(() => {
        if (resultSet) {
            setChartJsData(getChartJsData(resultSet, metricName, { fieldNames: metricElementsNames }));
        }
    }, [resultSet, metricName, metricElementsNames]);
    useEffect(() => {
        if (error)
            setError(error.message);
    }, [error]);
    // Получение текста для отображения на тултипе
    const getTooltipText = ({ dataPoints }) => {
        // Значение графика по оси X
        const pointXValue = dataPoints[0].label;
        // Значение графика по оси Y
        const pointYValue = dataPoints[0].raw;
        const stats = [
            {
                name: statsTooltipText,
                value: tickText(pointYValue)
            },
            {
                name: 'Дата',
                value: getTooltipDateText(pointXValue, granularity)
            }
        ];
        // Добавление поля с именем объекта метрики,
        // если на графике отображается несколько объектов
        if (metricElementsNames.length > 1) {
            const chartLabel = dataPoints[0].dataset.label;
            stats.unshift({
                name: chartTargetLabels[(doctorsIds && doctorsIds.length > 0) ? 'doctor' : 'speciality'],
                value: getValidName(chartLabel)
            });
        }
        return stats;
    };
    // Получение текста элементов по оси X
    const getXTickText = useCallback((value) => getXTickDateText(value, granularity), [granularity]);
    // Получение текста элементов по оси Y
    const getYTickText = useCallback((value) => tickText(value), []);
    const renderContent = () => (React.createElement(ChartsLayout, { title: title, isLoading: isLoading, isWithLegend: true, headerTooltip: tooltipText ? { id: tooltipId, text: tooltipText } : undefined }, chartJsData && (React.createElement(ChartsField, { id: `DashboardsSemenovDetailedStatisticChart_${metricName}`, data: chartJsData, type: chartType, legend: legend, getTooltipText: getTooltipText, getXTickText: getXTickText, getYTickText: getYTickText }))));
    return renderContent();
};
export default DashboardsSemenovDetailedStatisticChart;
