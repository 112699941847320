import moment from 'moment-timezone';
import { getMonthNameWithCapitalLetter } from 'functions/date';
// Получение текста тултипа для даты
const getTooltipDateText = (date, granularity) => {
    if (granularity === 'day')
        return moment(date).format('DD.MM.YY');
    const monthName = getMonthNameWithCapitalLetter(date);
    const year = moment(date).format('YYYY');
    return `${monthName} ${year}`;
};
export default getTooltipDateText;
