import React from 'react';
import ModalsError from 'components/modals/Error';
import Layout from 'helpers/Layout';
// Страница с ошибкой
const ViewsErrorIndex = ({ error, resetErrorBoundary }) => {
    const renderContent = () => (React.createElement(Layout, null,
        React.createElement(ModalsError, { isOpen: true, onClose: resetErrorBoundary, text: error.message })));
    return renderContent();
};
export default ViewsErrorIndex;
