import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import SettingsServiceCategoriesItemLink from 'components/settings/serviceCategories/ItemLink';
import navigationUrls from 'navigation/routes';
// TODO: переделать пагинацию в компоненте.
// Сейчас пагинация отличается от основной в дизайне.
// Вывод списка категорий услуг
const SettingsServiceCategoriesList = ({ loadMore, totalPages, page, serviceCategories }) => (React.createElement("div", { className: 'rounded-lg mb-3 py-4 bg-white flex overflow-auto', "data-testid": 'SettingsServiceCategoriesList' },
    React.createElement(InfiniteScroll, { pageStart: 1, loadMore: loadMore, hasMore: totalPages > page, className: 'flex flex-col flex-1' }, serviceCategories.map(({ id, name, services_count: servicesCount }) => (React.createElement(SettingsServiceCategoriesItemLink, { to: navigationUrls.settings.serviceCategories.item.services(id), name: name, servicesCount: servicesCount || 0, key: id }))))));
export default SettingsServiceCategoriesList;
