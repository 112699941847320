import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PatientsHistoriesMessagesChatsIndex from 'components/patients/history/messages/ChatsIndex';
import CardsBase from 'components/cards/Base';
import Loader from 'helpers/Loader';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { apiUrls } from 'config/api';
// Компонент вывода вкладки истории сообщений на странице пациенты
const PatientsHistoriesMessagesIndex = () => {
    const { id: clientId } = useParams();
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const { data, loading, error, refetch, isDataFetched } = useFetch(apiUrls.clients.conversationsList(clientId), 'get', { clinic_id: clinicId });
    const setError = useShowError();
    useEffect(() => {
        if (error)
            setError(error);
    }, [error]);
    useEffect(() => {
        if (isDataFetched)
            refetch();
    }, [clinicId]);
    const renderContent = () => (React.createElement(CardsBase, { className: `m-8 flex overflow-auto relative ${data && (data.conversations.length > 0) && 'flex-1'}` }, data && (data.conversations.length > 0 ? (React.createElement(PatientsHistoriesMessagesChatsIndex, { data: data })) : (React.createElement("div", { className: 'text-gray-bi p-6' }, "\u0423 \u0432\u0430\u0441 \u0435\u0449\u0435 \u043D\u0435\u0442 \u0447\u0430\u0442\u043E\u0432 \u0441 \u044D\u0442\u0438\u043C \u043F\u0430\u0446\u0438\u0435\u043D\u0442\u043E\u043C")))));
    return loading ? React.createElement(Loader, { className: 'h-full lighter' }) : renderContent();
};
export default PatientsHistoriesMessagesIndex;
