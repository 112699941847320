import renderChartJsTooltip from './renderChartJsTooltip';
const chartColors = {
    gray10: '#F6F7F8FF',
    gray30: '#A3ABB1FF',
    white: '#FFFFFF'
};
const chartFont = {
    family: 'Lato',
    size: 12,
    weight: 800
};
// Получение опций для отрисовки графика
const getChartJsOptions = ({ chartType, tooltip, chartColor, getTooltipText, getYTickText, getXTickText, options }) => ({
    backgroundColor: chartColor,
    responsive: true,
    maintainAspectRatio: false,
    layout: { padding: { bottom: -10 } },
    interaction: options?.intersection,
    plugins: {
        legend: { display: false },
        tooltip: {
            position: options?.tooltipPosition || 'average',
            enabled: false,
            external: context => renderChartJsTooltip(context, tooltip, {
                chartType,
                getTooltipText
            })
        }
    },
    elements: {
        line: {
            borderWidth: 1,
            borderColor: chartColor
        },
        point: {
            radius: 0,
            hoverRadius: 9,
            hitRadius: 9,
            backgroundColor: chartColors.white,
            borderColor: chartColor,
            hoverBorderWidth: 6
        }
    },
    scales: {
        y: {
            stacked: options?.stacked,
            grid: {
                drawTicks: false,
                color: chartColors.gray10
            },
            border: { color: chartColors.gray10 },
            ticks: {
                callback(tickValue) {
                    if (getYTickText) {
                        return getYTickText(String(tickValue));
                    }
                    return tickValue;
                },
                color: chartColors.gray30,
                font: chartFont,
                padding: 8
            }
        },
        x: {
            stacked: options?.stacked,
            grid: {
                drawTicks: false,
                color: chartColors.gray10
            },
            border: { color: chartColors.gray10 },
            ticks: {
                callback(tickValue) {
                    const label = this.getLabelForValue(tickValue);
                    if (getXTickText) {
                        return getXTickText(label);
                    }
                    return label;
                },
                align: chartType === 'bar' ? 'center' : 'inner',
                color: chartColors.gray30,
                font: chartFont,
                padding: 8
            }
        }
    }
});
export default getChartJsOptions;
