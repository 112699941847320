import React from 'react';
import Image from 'helpers/Image';
import ImageCampaignsSectionsPng from 'images/campaigns/Sections.png';
import ImageCampaignsSectionsWebp from 'images/campaigns/Sections.png?as=webp';
// Шапка лэндинга рассылок
const CampaignsLandingHeader = () => {
    const renderContent = () => (React.createElement("div", { className: 'campaignsLandingHeader flex items-center px-10' },
        React.createElement("div", { className: 'flex flex-col mr-12.5' },
            React.createElement("span", { className: 'text-label-biggest mb-6' }, "\u041F\u0440\u0438\u0441\u043E\u0435\u0434\u0438\u043D\u044F\u0439\u0442\u0435\u0441\u044C \u043A \u043D\u0430\u0448\u0438\u043C \u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0430\u043C"),
            React.createElement("span", { className: 'text-title-small font-normal' }, "\u0412 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0451\u043D\u043D\u044B\u0445 \u043A \u043D\u0430\u0448\u0435\u0439 \u0441\u0438\u0441\u0442\u0435\u043C\u0435 \u043A\u043B\u0438\u043D\u0438\u043A\u0430\u0445 \u0443\u0436\u0435 \u0441\u0435\u0433\u043E\u0434\u043D\u044F 34% \u0432\u0441\u0435\u0439 \u0432\u044B\u0440\u0443\u0447\u043A\u0438 \u0433\u0435\u043D\u0435\u0440\u0438\u0440\u0443\u044E\u0442 \u0438\u043D\u043D\u043E\u0432\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0435 \u0430\u043B\u0433\u043E\u0440\u0438\u0442\u043C\u044B \u043F\u043E \u0440\u0430\u0431\u043E\u0442\u0435 \u0441 \u0431\u0430\u0437\u043E\u0439 \u043F\u0430\u0446\u0438\u0435\u043D\u0442\u043E\u0432.")),
        React.createElement(Image, { srcPng: ImageCampaignsSectionsPng, srcWebp: ImageCampaignsSectionsWebp, width: 465, height: 415 })));
    return renderContent();
};
export default CampaignsLandingHeader;
