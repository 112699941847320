import React, { useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { useNavigate } from 'react-router-dom';
import AuthReleaseVersion from 'components/auth/ReleaseVersion';
import ButtonsMain from 'components/buttons/Main';
import InputBlock from 'helpers/Input/Block';
import { IconAuth, IconLogo } from 'svg/common';
import { handleErrors } from 'functions/Errors';
import { loadingHandler } from 'functions/data';
import navigationUrls from 'navigation/routes';
import { updateUserData } from 'redux/slices/user';
import { useAppDispatch } from 'redux/hooks';
import { apiUrls, apiWrapper } from 'config/api';
// Страница ввода email
const ViewsAuthEmail = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    // Отправка email
    const sendEmail = async () => {
        const response = await apiWrapper.post(apiUrls.auth.confirmationCode, { email });
        if (response.ok) {
            dispatch(updateUserData({ id: response.data.user, email }));
            navigate(navigationUrls.auth.code);
        }
        else
            setError(handleErrors(response));
    };
    // Обработка нажатия на кнопку "Войти"
    const onEnterClick = async () => {
        if (isEmail(email))
            loadingHandler(sendEmail, setIsLoading);
        else
            setError('Неверный формат e-mail');
    };
    // Обработка измнения инпута email
    const handleEmailChange = (event) => {
        if (error)
            setError(null);
        setEmail(event.target.value);
    };
    // Вывод инпута для email
    const renderEmailInput = () => (React.createElement(InputBlock, { className: 'w-full mb-3' },
        React.createElement(InputBlock.Field, { value: email, onChange: handleEmailChange, type: 'email' }),
        React.createElement(InputBlock.ErrorTooltip, { error: error }),
        React.createElement(InputBlock.Loader, { isLoading: isLoading })));
    const renderContent = () => (React.createElement("div", { className: 'flex-1 bg-white flex items-center justify-center', "data-testid": 'ViewsAuthEmail' },
        React.createElement("div", { className: 'flex flex-col items-center' },
            React.createElement(IconAuth, { className: 'mb-10' }),
            React.createElement(IconLogo, { className: 'mb-9 w-56.5 h-10' }),
            React.createElement("div", { className: 'w-83' },
                renderEmailInput(),
                React.createElement(ButtonsMain, { onClick: onEnterClick, disabled: isLoading || !email, className: 'mb-18' }, "\u0412\u043E\u0439\u0442\u0438")),
            React.createElement(AuthReleaseVersion, { isInitial: true }))));
    return renderContent();
};
export default ViewsAuthEmail;
