import React, { useEffect } from 'react';
import CardsBase from 'components/cards/Base';
import Loader from 'helpers/Loader';
import { getHoursMinutes, momentUtcToLocal } from 'functions/date';
import { useCubeFetch } from 'functions/cubeApi';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { cubeApi } from 'config/cubeApi';
// Таблица с метриками на странице описания загрузки врача
const DoctorsWorkloadTable = ({ dateRange, doctorId }) => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const { resultSet: doctorWorkloadResultSet, error: doctorWorkloadError, isLoading: isDoctorWorkload } = useCubeFetch(cubeApi.description.doctorsWorkload.table({ clinicId, dateRange, doctorId }));
    const doctorWorkloadData = doctorWorkloadResultSet?.rawData();
    const setError = useShowError();
    const isLoading = isDoctorWorkload;
    useEffect(() => {
        const error = doctorWorkloadError;
        if (error)
            setError(error.message);
    }, [doctorWorkloadError]);
    // Вывод ячейки шапки таблицы
    const renderHeaderCell = (text, className = 'flex-2') => (React.createElement("div", { className: className },
        React.createElement("span", { className: 'text-smallest font-semibold text-gray-main' }, text)));
    // Вывод ячейки ряда таблицы
    const renderRowCell = (text, className = 'flex-2') => (React.createElement("div", { className: className },
        React.createElement("span", { className: 'text-small text-gray-75' }, text)));
    // Вывод ряда таблицы
    const renderRow = (day) => {
        const date = day['doctors_schedules.work_date'];
        const doctorMinutesSchedules = day['doctors_schedules.sum_of_doctors_schedules_minutes'];
        const doctorMinutesSpent = day['doctors_schedules.work_time'];
        return (React.createElement("div", { className: 'h-9 flex items-center onRowHover', key: date },
            renderRowCell(momentUtcToLocal(date).format('DD.MM.YYYY')),
            renderRowCell(getHoursMinutes(doctorMinutesSchedules)),
            renderRowCell(getHoursMinutes(doctorMinutesSpent), 'flex-1')));
    };
    // Вывод тела таблицы
    const renderTableBody = () => (React.createElement("div", { className: 'dashboardsSemenovMetricDescriptionTable__dynamicContent relative flex flexCenter' },
        doctorWorkloadData && (React.createElement("div", { className: 'w-full flex flex-col gap-3' }, doctorWorkloadData.map(day => renderRow(day)))),
        isLoading && React.createElement(Loader, { className: 'absolute h-full' })));
    const renderContent = () => (React.createElement(CardsBase, { className: 'p-6' },
        React.createElement("div", { className: 'flex mb-4' },
            renderHeaderCell('Дата'),
            renderHeaderCell('Кол-во часов по графику'),
            renderHeaderCell('Кол-во часов фактического приема', 'flex-1')),
        renderTableBody()));
    return renderContent();
};
export default DoctorsWorkloadTable;
