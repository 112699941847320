import React from 'react';
import InputErrorTooltip from 'helpers/Input/ErrorTooltip';
import InputField from 'helpers/Input/Field';
import InputLoader from 'components/helpers/Input/Loader';
// Компонент блока инпута
const InputBlock = ({ className, children }) => {
    const renderContent = () => (React.createElement("div", { className: `relative ${className}`, "data-testid": 'InputBlock' }, children));
    return renderContent();
};
InputBlock.Field = InputField;
InputBlock.ErrorTooltip = InputErrorTooltip;
InputBlock.Loader = InputLoader;
export default InputBlock;
