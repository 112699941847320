// Получение градиента для бэкграунда графика
const getChartJsBackgroundGradient = (context, fillColors) => {
    if (!context.chart.chartArea) {
        return;
    }
    const { ctx, chartArea: { top, bottom } } = context.chart;
    const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
    gradientBg.addColorStop(0, fillColors[0]);
    gradientBg.addColorStop(1, fillColors[1]);
    return gradientBg;
};
export default getChartJsBackgroundGradient;
