import React from 'react';
import SelectMultiBadge from './MultiBadge';
// Выбранное значение селектора с множественным выбором
const SelectMultiValue = ({ data, selectProps, index, clearValue }) => {
    const values = selectProps.value;
    if (values instanceof Array && values.length > 1) {
        if (index === 0) {
            const badgeText = `Выбрано ${values.length}`;
            return React.createElement(SelectMultiBadge, { text: badgeText, onRemoveClick: clearValue });
        }
        return null;
    }
    return (React.createElement("span", { className: 'selectValue text-smaller font-semibold text-gray-75', "data-testid": 'SelectMultiValue' }, data.label));
};
export default SelectMultiValue;
