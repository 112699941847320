import React from 'react';
// Компонент ответа пациента на рассылку
const SchedulesPatientAnswer = ({ status, statusName }) => {
    let className = 'text-gray-50';
    // Определение стиля текста ответа
    if (status === 'failed') {
        className = 'text-red';
    }
    const renderContent = () => (React.createElement("div", { className: className }, statusName));
    return renderContent();
};
export default SchedulesPatientAnswer;
