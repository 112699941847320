import React from 'react';
// Компонент вывода легенды графика
const ChartsLegend = ({ items }) => {
    // Вывод элемента легенды
    const renderLegendElement = ({ label, color }) => (React.createElement("div", { className: 'flex items-center', key: label + color },
        React.createElement("div", { className: 'w-2.5 h-2.5 rounded-full mr-2', style: { backgroundColor: color }, "data-testid": 'ChartsLegend_colorIndicator' }),
        React.createElement("span", { className: 'text-smaller' }, label)));
    const renderContent = () => (React.createElement("div", { className: 'flex gap-3 h-4.5 mb-5', "data-testid": 'ChartsLegend' }, items.map(item => renderLegendElement(item))));
    return renderContent();
};
export default ChartsLegend;
