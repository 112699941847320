import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import ViewsErrorIndex from 'views/error/Index';
import Sidebar from 'helpers/Sidebar';
// Компонент обертки приложения, используется в роутере
const MainAppLayout = ({ withSidebar = false }) => (React.createElement(ErrorBoundary, { FallbackComponent: ViewsErrorIndex },
    React.createElement(QueryParamProvider, { adapter: ReactRouter6Adapter },
        withSidebar && React.createElement(Sidebar, null),
        React.createElement(Outlet, null))));
export default MainAppLayout;
