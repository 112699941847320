import React, { useEffect, useState } from 'react';
import NavList from 'helpers/NavList';
import Layout from 'helpers/Layout';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { apiUrls } from 'config/api';
import navigationUrls from 'navigation/routes';
// Компонент страницы Дашборды
const ViewsQueuesList = () => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const [campaignTriggersList, setCampaignTriggersList] = useState(null);
    const [treatScenariosList, setTreatScenariosList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { data: campaignTriggersData, error: campaignTriggersError, isDataFetched: isCampaignTriggersFetched, refetch: refetchCampaignTriggers, loading: isCampaignTriggersLoading } = useFetch(apiUrls.campaigns.triggers, 'get', { clinic_id: clinicId });
    const { data: treatScenariosData, error: treatScenariosError, refetch: refetchTreatScenarios, isDataFetched: isTreatScenariosFetched, loading: isTreatScenariosLoading } = useFetch(apiUrls.treatScenarios.list, 'get', { clinic_id: clinicId });
    const setError = useShowError();
    useEffect(() => {
        const error = campaignTriggersError || treatScenariosError;
        if (error) {
            setError(error);
        }
    }, [campaignTriggersError, treatScenariosError]);
    useEffect(() => {
        setIsLoading(isCampaignTriggersLoading || isTreatScenariosLoading);
    }, [isCampaignTriggersLoading, isTreatScenariosLoading]);
    useEffect(() => {
        if (isCampaignTriggersFetched)
            refetchCampaignTriggers();
        if (isTreatScenariosFetched)
            refetchTreatScenarios();
    }, [clinicId]);
    useEffect(() => {
        if (campaignTriggersData) {
            setCampaignTriggersList(campaignTriggersData.triggers.map(({ trigger, trigger_name: triggerName }) => ({
                id: trigger,
                name: triggerName,
                to: navigationUrls.queues.campaignTrigger(trigger, triggerName)
            })));
        }
    }, [campaignTriggersData]);
    useEffect(() => {
        if (treatScenariosData) {
            setTreatScenariosList(treatScenariosData.treat_scenarios.map(({ id, name }) => ({
                id,
                name,
                to: navigationUrls.queues.treatScenario(id, name)
            })));
        }
    }, [treatScenariosData]);
    // Получение списка навигации
    const getNavList = () => {
        if (campaignTriggersList || treatScenariosList) {
            return [...(campaignTriggersList || []), ...(treatScenariosList || [])];
        }
        return null;
    };
    const renderContent = () => (React.createElement(Layout, { testId: 'ViewsTemplatesIndex', className: 'flex flex-col' },
        React.createElement("h1", { className: 'mb-8' }, "\u041E\u0447\u0435\u0440\u0435\u0434\u0438"),
        React.createElement(NavList, { list: getNavList(), isLoading: isLoading, placeholderText: '\u041D\u0435\u0442 \u043E\u0447\u0435\u0440\u0435\u0434\u0435\u0439' })));
    return renderContent();
};
export default ViewsQueuesList;
