import { getShortenSum } from 'functions/data';
export const horizontalBarMetrics = {
    sumOfRevenueByDoctors: {
        title: 'Выручка по врачам',
        tickText: value => getShortenSum(value),
        stats: {
            cubeKey: 'treats',
            valueKey: 'sum_of_revenue'
        }
    },
    treatsCountByDoctors: {
        title: 'Количество оказанных услуг по врачам',
        tickText: value => String(value),
        stats: {
            cubeKey: 'treats',
            valueKey: 'total_count'
        }
    }
};
