import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appConfigSlice from 'redux/slices/appConfig';
import clinicsSlice from 'redux/slices/clinics';
import userSlice from 'redux/slices/user';
const persistConfig = {
    key: 'root',
    storage
};
const rootReducer = combineReducers({ appConfig: appConfigSlice, clinics: clinicsSlice, user: userSlice });
const persistedReducer = persistReducer(persistConfig, rootReducer);
// При использовании Redux-Persist, необходимо специально игнорировать все типы действий, которые он отправляет
// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
const ignoredActions = [FLUSH, PAUSE, PERSIST, PURGE, REGISTER];
export const setupStore = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: { ignoredActions } }).prepend()
});
export const persistor = persistStore(setupStore);
