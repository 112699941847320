import React, { createContext, useState } from 'react';
import ModalsError from 'components/modals/Error';
export const ErrorContext = createContext({});
// Компонент-обертка, предостовляющий котекст ошибки
// и отображающий модалку с ошибкой
const ErrorContextProvider = ({ children }) => {
    const [error, setError] = useState(null);
    const renderContent = () => (React.createElement(ErrorContext.Provider, { value: { setError } },
        children,
        React.createElement(ModalsError, { isOpen: !!error, onClose: () => setError(null), text: error || '' })));
    return renderContent();
};
export default ErrorContextProvider;
