import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ButtonsMain from 'components/buttons/Main';
import CardsBase from 'components/cards/Base';
import Loader from 'helpers/Loader';
import SlideNotification from 'helpers/SlideNotification';
import TextField from 'helpers/TextField';
import { handleErrors, useShowError } from 'functions/Errors';
import { loadingHandler } from 'functions/data';
import { apiUrls, apiWrapper } from 'config/api';
// Редактор шаблона ответа на рассылку
const CampaignsSystemAnswersItem = ({ template: { id, name, content } }) => {
    const { campaignId } = useParams();
    const [templateContent, setTemplateContent] = useState(content);
    const [isLoading, setIsLoading] = useState(false);
    const [isTemplateChanged, setIsTemplateChanged] = useState(false);
    const [isNotificationActive, setIsNotificationActive] = useState(false);
    const setError = useShowError();
    // Обновление шаблона
    const updateTemplate = async () => {
        const response = await apiWrapper.patch(apiUrls.campaigns.replyTemplates.show(campaignId, id), { reply_template: { content: templateContent } });
        if (response.ok) {
            setIsTemplateChanged(false);
            setIsNotificationActive(true);
        }
        else
            setError(handleErrors(response));
    };
    // Обработчик изменения шаблона
    const onTemplateChange = (e) => {
        if (!isTemplateChanged)
            setIsTemplateChanged(true);
        setTemplateContent(e.target.value);
    };
    // Вывод заголовка абзаца
    const renderTitle = (text) => (React.createElement("span", { className: 'text-gray-main text-smaller font-semibold mb-2' }, text));
    const renderContent = () => (React.createElement(CardsBase, { className: 'flex flexCenter relative overflow-hidden' },
        React.createElement("div", { className: 'flex flex-col p-6 gap-6 flex-1' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", { className: 'flex justify-between items-center' },
                    renderTitle('Название'),
                    React.createElement(SlideNotification, { text: '\u0421\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u043E', isActive: isNotificationActive, setIsActive: setIsNotificationActive })),
                React.createElement("span", null, name)),
            React.createElement("div", { className: 'flex flex-col' },
                renderTitle('Текст шаблона'),
                React.createElement(TextField, { value: templateContent, onChange: onTemplateChange, className: 'h-[150px]', disabled: isLoading })),
            React.createElement("div", { className: 'w-[212px]' },
                React.createElement(ButtonsMain, { disabled: !isTemplateChanged || isLoading, onClick: () => loadingHandler(updateTemplate, setIsLoading) }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F"))),
        isLoading && React.createElement(Loader, { className: 'absolute h-full bg-gray-47-dim' })));
    return renderContent();
};
export default CampaignsSystemAnswersItem;
