import { extendTailwindMerge } from 'tailwind-merge';
import config from '../../../tailwind.config.js';
// Конфигурация для tailwind-merge,
// чтобы библиотека корректно работала с расширенной конфигурацией tailwind
const twMerge = extendTailwindMerge({
    extend: {
        classGroups: {
            'font-size': Object.keys(config.theme.extend.fontSize).map(key => `text-${key}`)
        }
    }
});
export default twMerge;
