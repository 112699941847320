import React from 'react';
import CardsBase from 'components/cards/Base';
import Loader from 'helpers/Loader';
const fields = {
    birthday: {
        title: 'Дата рождения',
        key: 'birth_date'
    },
    gender: {
        title: 'Пол',
        key: 'gender'
    },
    phone: {
        title: 'Номер телефона',
        key: 'mobile_phone'
    },
    firstVisit: {
        title: 'Первое посещение',
        key: 'first_treat_date'
    }
};
// Компонент информации о пациенте в шапке страницы пациента
const PatientsShowHeaderInfo = ({ data }) => {
    // Вывод поля информации о пациенте
    const renderField = ({ title, key }, value) => {
        let textToShow = value;
        if (key === 'gender') {
            if (value === 'Male')
                textToShow = 'муж';
            else if (value === 'Female')
                textToShow = 'жен';
        }
        return (React.createElement("div", { key: key },
            React.createElement("div", { className: 'text-smaller text-gray-main mb-2' }, title),
            React.createElement("div", { className: 'text-small' }, textToShow || '—')));
    };
    const renderContent = () => (React.createElement(CardsBase, { className: 'p-6 h-[154px] w-[354px] flex flex-wrap gap-7' }, data ? (Object.values(fields).map(field => renderField(field, data.client[field.key]))) : (React.createElement(Loader, null))));
    return renderContent();
};
export default PatientsShowHeaderInfo;
