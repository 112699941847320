import React from 'react';
import ServicesDashboardBlock from 'helpers/Services/Dashboard/Block';
import { ServicesDashboardContext } from 'helpers/Services/Dashboard/Context';
// Элементы сервисов внутри категории в разделе настроек
const ServicesDashboardItemsInCategory = ({ itemsInCategory, setItemsInCategory, title, placeholder, kind, page = 1, loadMore = () => { }, totalPages = 1 }) => {
    const renderContent = () => (React.createElement("div", { className: 'flex flex-5', "data-testid": 'ServicesDashboardItemsInCategory' },
        React.createElement(ServicesDashboardContext.Provider, { value: {
                services: itemsInCategory,
                setServices: setItemsInCategory,
                kind,
                page,
                totalPages,
                loadMore
            } },
            React.createElement(ServicesDashboardBlock, null,
                React.createElement(ServicesDashboardBlock.Header, { title: title, kind: kind }),
                React.createElement(ServicesDashboardBlock.ServicesDashboardSelectAll, null),
                React.createElement(ServicesDashboardBlock.List, { placeholder: placeholder })))));
    return renderContent();
};
export default ServicesDashboardItemsInCategory;
