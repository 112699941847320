import { classNames } from 'functions/Main';
import Image from 'helpers/Image';
import React from 'react';
// Картинка в блоке преимуществ лэндинга рассылок
const CampaignsLandingAdvantageImage = ({ srcPng, srcWebp, className, width, height }) => {
    const renderContent = () => (React.createElement("div", { className: classNames('flex flexCenter relative [&_picture]:z-1 w-fit', {}, [className || '']) },
        React.createElement(Image, { srcPng: srcPng, srcWebp: srcWebp, width: width || 526, height: height || 325, alt: '\u041F\u0440\u0435\u0438\u043C\u0443\u0449\u0435\u0441\u0442\u0432\u0430 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438' }),
        React.createElement("div", { className: 'absolute rounded-[46%] shadow-[0_0_20px_20px] shadow-primary-dim bg-primary-dim w-[90%] h-[90%]' })));
    return renderContent();
};
export default CampaignsLandingAdvantageImage;
