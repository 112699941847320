import React, { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonsBack from 'components/buttons/Back';
import SettingsServicesTabsDoctors from 'components/settings/services/tabs/Doctors';
import SettingsServicesTabsServices from 'components/settings/services/tabs/Services';
import TabsWrapper from 'helpers/TabsWrapper';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { apiUrls } from 'config/api';
import navigationUrls from 'navigation/routes';
const tabs = [
    {
        key: 'services',
        title: 'Услуги',
        renderComponent: () => React.createElement(SettingsServicesTabsServices, null)
    },
    {
        key: 'doctors',
        title: 'Врачи',
        renderComponent: () => React.createElement(SettingsServicesTabsDoctors, null)
    }
];
// Компонент вывода услуг в категории услуг
const ViewsSettingsServicesItem = () => {
    const { categoryId } = useParams();
    const [tabKey, setTabKey] = useQueryParam('tab', StringParam);
    const setError = useShowError();
    const navigate = useNavigate();
    const { data, error } = useFetch(apiUrls.serviceCategories.show(categoryId), 'get');
    useEffect(() => {
        if (error)
            setError(error);
    }, [error]);
    const renderContent = () => (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'm-8' },
            React.createElement(ButtonsBack, { text: '\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438/\u0423\u0441\u043B\u0443\u0433\u0438', className: 'mb-1', onClick: () => navigate(navigationUrls.settings.index) }),
            React.createElement("div", { className: 'h-9.5' }, data && React.createElement("h1", null, data.service_category.name))),
        React.createElement(TabsWrapper, { tabs: tabs, setTabKey: setTabKey, tabKey: tabKey })));
    return renderContent();
};
export default ViewsSettingsServicesItem;
