import React from 'react';
import moment from 'moment-timezone';
import Button from 'helpers/Button';
import { IconDropdown } from 'svg/common';
// Шапка календаря
const CalendarHeader = ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
    const renderContent = () => (React.createElement("div", { className: 'py-2.5 mb-4 flex justify-between border-b border-gray-15' },
        React.createElement("div", { className: 'w-4' },
            React.createElement(Button, { onClick: decreaseMonth, disabled: prevMonthButtonDisabled },
                React.createElement(IconDropdown, { className: 'rotate-90' }))),
        React.createElement("span", { className: 'text-smaller font-semibold text-gray-bi' }, moment(date).format('MMMM YYYY')),
        React.createElement("div", { className: 'w-4' },
            React.createElement(Button, { onClick: increaseMonth, disabled: nextMonthButtonDisabled },
                React.createElement(IconDropdown, { className: '-rotate-90' })))));
    return renderContent();
};
export default CalendarHeader;
