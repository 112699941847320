export const patientsStatsOrderField = {
    totalCount: 'total_count',
    averageCostPerVisit: 'average_cost_per_visit',
    sumOfRevenue: 'sum_of_revenue',
    sumOfRevenueLastSixMonths: 'sum_of_revenue_last_six_months',
    lastTreatDate: 'last_treat_date'
};
export const patients = {
    count: ({ clinicId }) => ({
        measures: [
            'treats.clients_count'
        ],
        filters: [
            {
                member: 'treats.clinic_id',
                operator: 'equals',
                values: [clinicId]
            }
        ]
    }),
    stats: ({ clinicId, limit, offset, orderField, orderBy, clientName }) => {
        const config = {
            measures: [
                'treats.total_count',
                'treats.average_cost_per_visit',
                'treats.sum_of_revenue',
                'treats.sum_of_revenue_last_six_months',
                'treats.last_treat_date'
            ],
            dimensions: [
                'treats.client_id',
                'treats.client_name'
            ],
            filters: [
                {
                    member: 'treats.clinic_id',
                    operator: 'equals',
                    values: [clinicId]
                }
            ],
            limit,
            offset
        };
        if (clientName) {
            config.filters?.push({
                member: 'treats.client_name',
                operator: 'contains',
                values: [clientName]
            });
        }
        if (orderField && orderBy) {
            let finalOrderBy = orderBy;
            // Инвертируем порядок для имени
            if (orderField === 'client_name') {
                finalOrderBy = orderBy === 'asc' ? 'desc' : 'asc';
            }
            config.order = [
                [
                    `treats.${orderField}`,
                    finalOrderBy
                ]
            ];
        }
        return config;
    }
};
