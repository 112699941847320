import React from 'react';
import moment from 'moment-timezone';
import TableTextCell from 'components/table/TextCell';
import ProcessingDashboardRow from 'helpers/ProcessingDashboard/Row';
import { classNames } from 'functions/Main';
import { getAnswerClassName } from 'functions/feedback';
// Ряд в таблице дашборда отзывов
const FeedbackDashboardTableRow = ({ clientAnswerData, index, changeStatusButton, isSelectable, columns, tooltipId, currentPathName }) => {
    const { treat_date: treatDate, client, doctor, client_answer: clientAnswer, client_answer_status: clientAnswerStatus } = clientAnswerData;
    // Вывод ячеек с именами
    const renderCellsWithName = () => [
        { text: doctor?.full_name, key: 'doctorName', width: columns[1].width },
        { text: client?.name, key: 'clientName', width: columns[2].width }
    ].map(({ text, key, width }) => (React.createElement(TableTextCell, { text: text, className: classNames('pr-5', {}, [width]), textClassName: 'text-small', tooltipId: tooltipId, lines: 2, key: key })));
    const renderContent = () => (React.createElement(ProcessingDashboardRow, { item: clientAnswerData, index: index, changeStatusButton: changeStatusButton, currentPathName: currentPathName, tooltipId: tooltipId, isSelectable: isSelectable },
        React.createElement("div", { className: classNames('text-small', {}, [columns[0].width]) }, moment(treatDate).format('DD.MM.YY')),
        renderCellsWithName(),
        React.createElement("div", { className: columns[3].width },
            React.createElement("div", { className: classNames('pb-1 pt-0.5 rounded-[44px] w-fit', {}, [getAnswerClassName(clientAnswerStatus, clientAnswer)]) },
                React.createElement("span", { className: 'text-smallest font-semibold' }, clientAnswer)))));
    return renderContent();
};
export default FeedbackDashboardTableRow;
