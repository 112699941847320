import { getShortenName } from 'functions/data';
// Список цветов для круговых графиков
export const doughnutColors = [
    '#65D9E8',
    '#22B8CE',
    '#1198AD',
    '#FFC078',
    '#FF922C',
    '#F76707',
    '#52CF66',
    '#E599F7',
    '#CC5DE8',
    '#FFA8A8'
];
// Максимальное кол-во метрик на круговом графике
export const doughnutMaxCount = doughnutColors.length;
// Параметры для отображения метрик на круговых графиках
export const doughnutMetrics = {
    doctors: {
        cubeApiName: 'doctorsRevenue',
        getLegendName: name => getShortenName(name),
        stats: {
            tooltipText: 'Врач',
            cubeKey: 'treats',
            xValueKey: 'doctor_full_name',
            yValueKey: 'sum_of_revenue'
        }
    },
    specialities: {
        cubeApiName: 'specialitiesRevenue',
        getLegendName: name => name,
        stats: {
            tooltipText: 'Специальность',
            cubeKey: 'treats',
            xValueKey: 'doctor_speciality',
            yValueKey: 'sum_of_revenue'
        }
    }
};
