import moment from 'moment-timezone';
// Получение массива из сообщений, сгруппированных по дням
const getMessagesGroupedByDay = (messages) => messages.reduce((result, message) => {
    const date = moment(message.created_at).startOf('day');
    // Если массив пустой или дата послденего элемента массива не равна дате текущего сообщения,
    // то создаем новый элемент массива с новой датой
    if (result.length === 0 || !result[result.length - 1].date.isSame(date)) {
        result.push({
            date,
            messages: [message]
        });
        // Иначе пушим сообщение к уже существующей дате
    }
    else {
        result[result.length - 1].messages.push(message);
    }
    return result;
}, []);
export default getMessagesGroupedByDay;
