import React from 'react';
import ServicesDashboardBlock from 'helpers/Services/Dashboard/Block';
import { ServicesDashboardContext } from 'helpers/Services/Dashboard/Context';
// Доступные элементы сервисов в разделе настроек
const ServicesDashboardAvailableItems = ({ availableItems, setAvailableItems, title, renderButtons, placeholder = 'В справочнике не осталось услуг, подходящих под данный запрос', renderSearchInput, kind, page = 1, loadMore = () => { }, totalPages = 1 }) => {
    const renderContent = () => (React.createElement(ServicesDashboardContext.Provider, { value: {
            services: availableItems,
            setServices: setAvailableItems,
            kind,
            page,
            totalPages,
            loadMore
        } },
        React.createElement("div", { className: 'flex flex-col flex-2', "data-testid": 'ServicesDashboardAvailableItems' },
            React.createElement("div", { className: 'p-4' },
                React.createElement("span", { className: 'text-smaller font-semibold' }, title),
                renderSearchInput && renderSearchInput()),
            React.createElement("div", { className: 'flex flex-1 overflow-hidden' },
                React.createElement("div", { className: 'flex flex-1' },
                    React.createElement(ServicesDashboardBlock, null,
                        React.createElement(ServicesDashboardBlock.ServicesDashboardSelectAll, null),
                        React.createElement(ServicesDashboardBlock.List, { placeholder: placeholder }))),
                renderButtons()))));
    return renderContent();
};
export default ServicesDashboardAvailableItems;
