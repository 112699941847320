import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAvailableSections } from 'functions/data';
import navigationUrls from 'navigation/routes';
// Вывод компонента с проверкой доступности
const ProtectedRoute = ({ component, section, redirectTo }) => {
    const availableSections = useAvailableSections();
    // Вывод доступного компонента
    const renderAvailableComponent = () => (availableSections.includes(section) ? component : React.createElement(Navigate, { to: redirectTo || navigationUrls.sectionUnavailable }));
    const renderContent = () => (availableSections.length ? renderAvailableComponent() : component);
    return renderContent();
};
export default ProtectedRoute;
