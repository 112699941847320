import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import PatientsHistoriesMessagesChatDayMessages from 'components/patients/history/messages/ChatDayMessages';
import Loader from 'helpers/Loader';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { apiUrls } from 'config/api';
import { getMessagesGroupedByDay } from 'functions/data';
// Компонент вывода чатов с пациентом
const PatientsHistoriesMessagesChat = ({ selectedConversation }) => {
    const { id: conversationId } = selectedConversation;
    const [messages, setMessages] = useState([]);
    const [page, setPage] = useState(1);
    const chatRef = useRef(null);
    const { data, loading, error, isDataFetched } = useFetch(apiUrls.conversations.show(conversationId), 'get', { page });
    const setError = useShowError();
    useEffect(() => {
        if (error)
            setError(error);
    }, [error]);
    useEffect(() => {
        if (data) {
            setMessages(prev => [...data.conversation_messages, ...prev]);
        }
    }, [data]);
    // Прокрутка до последних сообщений
    useEffect(() => {
        if (chatRef.current && page === 1) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [chatRef.current, messages]);
    useEffect(() => {
        if (chatRef.current && page === 1) {
            chatRef?.current?.addEventListener('scroll', handleScroll);
            return () => {
                chatRef?.current?.removeEventListener('scroll', handleScroll);
            };
        }
    }, [chatRef.current, messages]);
    // Обработчик события скролла в истории сообщений
    const handleScroll = () => {
        if (chatRef.current && data) {
            const currentScrollPosition = chatRef.current.scrollTop;
            const isFetchMore = data.pagination.pages > page;
            // Смена даты на предыдущие 3 дня в истории сообщений
            if (currentScrollPosition === 0 && isFetchMore) {
                setPage(prev => prev + 1);
            }
        }
    };
    // Вывод сообщений за день
    const renderDayMessages = () => ((data && messages.length > 0) ? (React.createElement("div", { className: 'p-6 overflow-auto', ref: chatRef, "data-testid": 'PatientChat' },
        React.createElement(InfiniteScroll, { pageStart: 0, loadMore: () => { }, hasMore: data.pagination.count > messages.length, useWindow: false, loader: React.createElement("div", { className: 'flex justify-center pb-2' },
                React.createElement(Loader, { className: 'w-6 h-6' })), isReverse: true }, getMessagesGroupedByDay(messages).map(day => (React.createElement(PatientsHistoriesMessagesChatDayMessages, { key: day.date.toString(), date: day.date, messages: day.messages })))))) : React.createElement("div", { className: 'text-gray-bi flex-1 p-6' }, "\u0423 \u0432\u0430\u0441 \u0435\u0449\u0435 \u043D\u0435\u0442 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439 \u0441 \u044D\u0442\u0438\u043C \u043F\u0430\u0446\u0438\u0435\u043D\u0442\u043E\u043C"));
    const renderContent = () => (React.createElement("div", { className: 'flex-1 flex flex-col justify-end relative', "data-testid": 'PatientChat_container' }, (loading && !isDataFetched) ? React.createElement(Loader, { className: 'h-full absolute lighter' }) : renderDayMessages()));
    return renderContent();
};
export default PatientsHistoriesMessagesChat;
