import React from 'react';
import { useNavigate } from 'react-router-dom';
import TableStatsElement from 'components/table/stats/Element';
import TableStatsRow from 'components/table/stats/Row';
import { getFormattedSemenovMetrics, getValidName } from 'functions/dashboards';
import { classNames } from 'functions/Main';
import { isDoctorMetric } from 'functions/TypesCheck';
import navigationUrls from 'navigation/routes';
// Метрики, для которых готовы страницы с описанием
// TODO: убрать при завершении страниц для всех метрик
const availableMetricsWithDescription = ['conversionPercentage', 'doctorsWorkload'];
// Компонент ряда таблицы Семёнова
const DashboardsSemenovTableRow = ({ metrics, averageMetrics, dateRange }) => {
    const navigate = useNavigate();
    let key;
    let type;
    let name;
    let doctorId;
    let doctorSharedData = null;
    const isDoctor = isDoctorMetric(metrics);
    if (isDoctor) {
        key = metrics.id;
        type = 'doctor';
        name = metrics.fullName;
        doctorId = metrics.id;
        doctorSharedData = {
            id: doctorId,
            name
        };
    }
    else {
        key = metrics.speciality || 'noSpecialityName';
        type = 'specialization';
        name = metrics.speciality;
    }
    const formattedMetrics = getFormattedSemenovMetrics(metrics, averageMetrics);
    // Переход на страцниу "Подробная статистика"
    // при клике на ряд таблицы
    const onRowClick = (speciality, doctor) => {
        let queryString = `?speciality=${speciality}`;
        if (doctor) {
            queryString += `&doctorId=${doctor.id}&doctorName=${doctor.name}`;
        }
        navigate({
            pathname: navigationUrls.dashboards.doctors.detailedStatistic,
            search: queryString
        });
    };
    // Переход на страницу при клике на элемент статистики
    const onStatsElementClick = (metricName) => {
        navigate({
            pathname: navigationUrls.dashboards.doctors.metricDescription,
            search: `?metricName=${metricName}&dateRange=${dateRange.toString()}&doctorName=${name}&doctorId=${doctorId}`
        });
    };
    const renderContent = () => (React.createElement(TableStatsRow, { key: key, className: `bg-${type === 'doctor' ? 'white' : 'gray-15'}`, onClick: () => onRowClick(metrics.speciality, doctorSharedData) },
        React.createElement("div", { className: 'flex-1 flex items-center px-1.5' },
            React.createElement("span", { className: classNames('text-smaller', { 'font-bold': !isDoctor }, [isDoctor ? 'pl-5.5' : 'pl-2.5']) }, getValidName(name))),
        React.createElement(React.Fragment, null, Object.entries(formattedMetrics).map(([metricName, metric]) => (React.createElement(TableStatsElement, { text: metric.text, status: type === 'doctor' ? metric.status : null, key: `${key}_${metricName}`, onClick: (type === 'doctor' && availableMetricsWithDescription.includes(metricName)) ?
                (() => onStatsElementClick(metricName)) :
                undefined }))))));
    return renderContent();
};
export default DashboardsSemenovTableRow;
