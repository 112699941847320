import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonsBack from 'components/buttons/Back';
import DashboardsCategoriesIndex from 'components/dashboards/categories/Index';
import Layout from 'helpers/Layout';
import navigationUrls from 'navigation/routes';
// Страница с дашбордами по категориям
const ViewsDashboardsCategories = () => {
    const navigate = useNavigate();
    const renderContent = () => (React.createElement(Layout, { className: 'overflow-auto flex flex-col' },
        React.createElement(ButtonsBack, { text: '\u0414\u0430\u0448\u0431\u043E\u0440\u0434\u044B', className: 'mb-1 w-fit', onClick: () => navigate(navigationUrls.dashboards.index) }),
        React.createElement("h1", { className: 'mb-8' }, "\u0414\u0430\u0448\u0431\u043E\u0440\u0434 \u043F\u043E \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F\u043C"),
        React.createElement(DashboardsCategoriesIndex, null)));
    return renderContent();
};
export default ViewsDashboardsCategories;
