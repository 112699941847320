import React, { Suspense } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import AppRouter from 'components/main/AppRouter';
import ErrorContextProvider from 'helpers/ErrorContextProvider';
import { persistor, setupStore } from 'redux/store';
// Главный компонент приложения
const MainApp = () => (React.createElement("div", { className: 'flex m-auto h-full' },
    React.createElement(Suspense, { fallback: null },
        React.createElement(Provider, { store: setupStore },
            React.createElement(PersistGate, { loading: null, persistor: persistor },
                React.createElement(ErrorContextProvider, null,
                    React.createElement(AppRouter, null)))))));
export default MainApp;
