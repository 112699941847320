import React from 'react';
import { TableWrapper } from 'components/table/Wrapper';
import { classNames } from 'functions/Main';
// Компонент списка врачей
const DoctorsList = ({ doctors }) => {
    // Вывод врача
    const renderDoctor = ({ id, full_name: fullName }) => (React.createElement("tr", { key: id, className: 'text-gray-85 text-smaller h-17.5' },
        React.createElement("td", { className: 'pl-3 w-8/12' }, fullName)));
    const renderContent = () => (React.createElement(TableWrapper, { tableClass: 'w-full', wrapperClass: classNames('overflow-auto !pb-0', { 'rounded-lg': doctors.length === 0 }) }, doctors.length > 0 ? (doctors.map(renderDoctor)) : (React.createElement("tr", { className: 'text-gray-85 text-small hover:!bg-white' },
        React.createElement("td", { className: 'text-center' }, "\u041D\u0435\u0442 \u0432\u0440\u0430\u0447\u0435\u0439")))));
    return renderContent();
};
export default DoctorsList;
