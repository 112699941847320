import React from 'react';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import ButtonsText from 'components/buttons/Text';
import { TableWrapper } from 'components/table/Wrapper';
import navigationUrls from 'navigation/routes';
// Шапка таблицы
const tableHead = (React.createElement("tr", null,
    React.createElement("th", { className: 'text-smallest pl-5.5 font-semibold text-gray-main' }, "\u0428\u0430\u0431\u043B\u043E\u043D"),
    React.createElement("th", { className: 'text-smallest font-semibold text-gray-main' }, "\u0414\u0430\u0442\u0430 \u0440\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F"),
    React.createElement("th", null)));
// Компонент списка шаблонов
const TemplatesList = ({ templates }) => {
    const navigate = useNavigate();
    // Вывод шаблона
    const renderTemplate = ({ id, name, updated_at: updatedAt }) => (React.createElement("tr", { key: id, className: 'text-gray-75', "data-testid": 'TemplatesList_template' },
        React.createElement("td", { className: 'text-small pl-5.5' }, name),
        React.createElement("td", { className: 'text-small' }, moment(updatedAt).format('DD.MM.YY')),
        React.createElement("td", { className: 'text-small pr-5.5' },
            React.createElement(ButtonsText, { onClick: () => navigate(navigationUrls.templates.editor(id)), className: 'text-small font-semibold' }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C"))));
    const renderContent = () => (templates.length > 0 ? (React.createElement(TableWrapper, { thead: tableHead, tableClass: 'templatesList', wrapperClass: 'rounded-lg overflow-auto', testId: 'TemplatesList' }, templates.map(renderTemplate))) : (React.createElement("span", null, "\u041D\u0435\u0442 \u0448\u0430\u0431\u043B\u043E\u043D\u043E\u0432")));
    return renderContent();
};
export default TemplatesList;
