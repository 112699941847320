import React, { useCallback, useEffect } from 'react';
import moment from 'moment-timezone';
import ChartsField from 'components/charts/base/Field';
import ChartsLayout from 'components/charts/base/Layout';
import Loader from 'helpers/Loader';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { apiUrls } from 'config/api';
const legend = [
    {
        label: 'Доставлено',
        color: 'rgba(19, 193, 192, 1)'
    },
    {
        label: 'Ошибка',
        color: 'rgba(251, 170, 116, 1)'
    },
    {
        label: 'Не доставлено',
        color: 'rgba(214, 223, 233, 1)'
    }
];
// График динамики рассылки
const CampaignsStatsDynamicsChart = ({ campaignId }) => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const { data, error, isDataFetched, refetch, loading } = useFetch(apiUrls.campaigns.statisticByMonth(campaignId), 'get', { date: moment().format('YYYY-MM-DD'), clinic_id: clinicId });
    const setError = useShowError();
    useEffect(() => {
        if (error) {
            setError(error);
        }
    }, [error]);
    useEffect(() => {
        if (isDataFetched)
            refetch();
    }, [clinicId]);
    // Получение отформатированных данных для отображения на графике
    const getData = ({ messages_count_by_days: messages }) => {
        const labels = Object.keys(messages);
        const datasets = [
            // Доставлено
            { data: Object.values(messages).map(value => value.delivered_total) },
            // Ошибки
            { data: Object.values(messages).map(value => value.failed_total) },
            // Отправлено, но не доставлено
            { data: Object.values(messages).map(value => value.sent_total - value.delivered_total - value.failed_total) }
        ];
        return {
            labels,
            datasets: datasets.map((item, index) => ({
                ...item,
                label: legend[index].label,
                backgroundColor: legend[index].color
            }))
        };
    };
    // Получение текста для отображения на тултипе
    const getTooltipText = ({ dataPoints }) => {
        // Данные получаем из точки графика, над которой появляется тултип
        const pointXValue = dataPoints[0].label;
        const delivered = dataPoints[0].raw;
        const failed = dataPoints[1].raw;
        const sent = dataPoints[2].raw;
        const all = delivered + failed + sent;
        const stats = [
            {
                name: 'Дата',
                value: moment(pointXValue).format('DD.MM.YYYY')
            },
            {
                name: 'Всего',
                value: all
            },
            {
                name: legend[0].label,
                value: delivered
            },
            {
                name: legend[1].label,
                value: failed
            },
            {
                name: legend[2].label,
                value: sent
            }
        ];
        return stats;
    };
    // Получение текста элементов по оси X
    const getXTickText = useCallback((value) => moment(value).format('D'), []);
    const renderContent = () => (React.createElement(ChartsLayout, { title: '\u0414\u0438\u043D\u0430\u043C\u0438\u043A\u0430 \u043E\u0442\u043F\u0440\u0430\u0432\u043A\u0438 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438', isWithLegend: true }, (data && !loading) ? (React.createElement(ChartsField, { id: 'CampaignsStatsDynamicsChart', data: getData(data), type: 'bar', legend: legend, getTooltipText: getTooltipText, getXTickText: getXTickText, options: {
            tooltipPosition: 'custom',
            stacked: true,
            intersection: { mode: 'index' }
        } })) : (React.createElement(Loader, { className: 'flex-1' }))));
    return renderContent();
};
export default CampaignsStatsDynamicsChart;
