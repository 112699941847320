import React from 'react';
import TooltipsBase from 'components/tooltips/Base';
import { getDashboardStatusColor } from 'functions/data';
import 'react-tooltip/dist/react-tooltip.css';
// Компонент окна-подсказки
const TooltipsMain = ({ id, status }) => {
    const tooltipId = `${id}${status ? `_${status}` : ''}`;
    // Вывод содержимого тултипа
    const renderTooltipContent = ({ content }) => (React.createElement("div", { className: 'flex items-center' },
        status && (React.createElement("div", { className: `w-2 h-2 mr-1.5 rounded-full ${getDashboardStatusColor(status).secondary}`, "data-testid": 'Tooltip_status' })),
        React.createElement("p", { className: 'text-smallest font-semibold' }, content)));
    const renderContent = () => (React.createElement(TooltipsBase, { id: tooltipId, render: renderTooltipContent }));
    return renderContent();
};
export default TooltipsMain;
