import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { Popover } from 'react-tiny-popover';
import Calendar from 'helpers/Calendar/Index';
import Select from 'helpers/Select/Index';
import { getDateRange } from 'functions/date';
export const options = [
    {
        label: 'Этот месяц',
        value: 'thisMonth'
    },
    {
        label: 'Прошлый месяц',
        value: 'lastMonth'
    },
    {
        label: 'Этот квартал',
        value: 'thisQuarter'
    },
    {
        label: 'Прошлый квартал',
        value: 'lastQuarter'
    },
    {
        label: 'Пол года',
        value: 'halfYear'
    },
    {
        label: 'Год',
        value: 'year'
    }
];
const customPeriodDefaultLabel = 'Конкретные даты';
// Компонент выбора периода в дашборде
const DashboardsSelectorsPeriod = ({ defaultOption, onChange, containerClassName = 'mb-6', selectorWrapperClassName = 'w-42.5' }) => {
    const [period, setPeriod] = useState(defaultOption);
    const [customPeriodLabel, setCustomPeriodLabel] = useState(customPeriodDefaultLabel);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const prevPeriod = useRef(period);
    const customPeriod = {
        label: customPeriodLabel,
        value: 'custom'
    };
    // Установка диапазона дат для стандартных периодов
    useEffect(() => {
        const periodValue = period.value;
        if (periodValue !== 'custom') {
            prevPeriod.current = period;
            onChange(getDateRange(periodValue));
        }
    }, [period]);
    // При смене лэйбла для кастомного периода
    // нужен дополнительный выбор периода,
    // чтобы перерисовался лэйбл инпута селекта
    useEffect(() => {
        if (customPeriodLabel !== customPeriodDefaultLabel)
            setPeriod(customPeriod);
    }, [customPeriodLabel]);
    // Установка диапазона дат для кастомного периода
    const setDateRange = (range) => {
        onChange([moment(range[0]).format('YYYY-MM-DD'), moment(range[1]).format('YYYY-MM-DD')]);
        const startDateLabel = moment(range[0]).format('DD.MM.YYYY');
        const endDateLabel = moment(range[1]).format('DD.MM.YYYY');
        setCustomPeriodLabel(`${startDateLabel}-${endDateLabel}`);
    };
    // Выбор периода из опций селекта
    const onSelectChange = (option) => {
        // Открытие календаря при кастомном периоде
        if (option?.value === 'custom') {
            setIsPopoverOpen(true);
        }
        setPeriod(option);
    };
    // Закрытые календаря
    const closeCalendar = () => {
        setIsPopoverOpen(prev => !prev);
    };
    const renderContent = () => (React.createElement("div", { className: containerClassName },
        React.createElement(Popover, { isOpen: isPopoverOpen, onClickOutside: closeCalendar, clickOutsideCapture: isPopoverOpen, content: React.createElement(Calendar, { onClose: closeCalendar, setDateRange: setDateRange }), positions: ['bottom'], align: 'start', padding: 4, containerClassName: 'z-select-menu' },
            React.createElement("div", { className: selectorWrapperClassName },
                React.createElement(Select, { title: '\u041F\u0435\u0440\u0438\u043E\u0434', options: [...options, customPeriod], onChange: onSelectChange, value: period })))));
    return renderContent();
};
export default DashboardsSelectorsPeriod;
