import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { pluralize } from 'pluralize-ru-ts';
import ButtonsBack from 'components/buttons/Back';
import CardsBase from 'components/cards/Base';
import SettingsServiceCategoriesItemLink from 'components/settings/serviceCategories/ItemLink';
import Layout from 'helpers/Layout';
import Loader from 'helpers/Loader';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { apiUrls } from 'config/api';
import navigationUrls from 'navigation/routes';
// Страница "Сценарий лечения"
const ViewsSettingsTreatScenariosItem = () => {
    const { scenarioId } = useParams();
    const { data, error } = useFetch(apiUrls.treatScenarios.show(scenarioId), 'get');
    const navigate = useNavigate();
    const setError = useShowError();
    useEffect(() => {
        if (error) {
            setError(error);
        }
    }, [error]);
    const renderScenario = ({ name, days_between_stages: daysBetweenStages, treat_scenario_stages: stages }) => (React.createElement("div", { className: 'flex flex-col w-fit' },
        React.createElement("h1", { className: 'mb-8' }, name),
        React.createElement("span", { className: 'mb-2 text-gray-main text-smaller font-semibold' }, "\u0412\u0440\u0435\u043C\u044F \u043C\u0435\u0436\u0434\u0443 1 \u0438 2 \u044D\u0442\u0430\u043F\u043E\u043C"),
        React.createElement("span", { className: 'mb-6' },
            daysBetweenStages,
            " ",
            pluralize('день', 'дня', 'дней')(daysBetweenStages)),
        React.createElement("div", { className: 'flex flex-col' },
            React.createElement("div", { className: 'mb-4 py-2.5 px-4 rounded-lg bg-blue-cold' },
                React.createElement("span", { className: 'font-semibold text-big' }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0443\u0441\u043B\u0443\u0433\u0438, \u043A\u043E\u0442\u043E\u0440\u044B\u0435 \u0431\u0443\u0434\u0443\u0442 \u0432\u0445\u043E\u0434\u0438\u0442\u044C \u0432 \u043A\u0430\u0436\u0434\u044B\u0439 \u0438\u0437 \u044D\u0442\u0430\u043F\u043E\u0432:")),
            React.createElement(CardsBase, { className: 'py-4' }, stages.map(({ id: stageId, state_name: stageName, services_count: servicesCount }) => (React.createElement(SettingsServiceCategoriesItemLink, { name: stageName, servicesCount: servicesCount, key: stageId, to: {
                    pathname: navigationUrls.settings.treatScenarios.item.stage(scenarioId, stageId),
                    search: `stageName=${stageName}&scenarioName=${name}`
                } })))))));
    const renderContent = () => (React.createElement(Layout, null,
        React.createElement(ButtonsBack, { text: '\u0421\u043F\u0438\u0441\u043A\u0438', className: 'mb-1', onClick: () => navigate({
                pathname: navigationUrls.settings.index,
                search: 'tab=treatScenarios'
            }) }),
        data ? (renderScenario(data.treat_scenario)) : (React.createElement(Loader, { className: 'h-full' }))));
    return renderContent();
};
export default ViewsSettingsTreatScenariosItem;
