/** @type {import('tailwindcss').Config} */

module.exports = {
  content: [
    './public/*.html',
    './app/frontend/**/*.{js,jsx,ts,tsx}'
  ],
  theme: {
    fontFamily: { sans: ['Lato', 'sans-serif'] },
    extend: {
      colors: {
        // Base colors
        black: '#212426',
        blue: '#DDEEFF',
        'blue-cold': '#C6EBEC',
        'gray-bi': '#4E5771',
        'gray-main': '#A3ABB1', // gray-30
        'gray-05': '#F9FAFB',
        'gray-10': '#F6F7F8',
        'gray-15': '#E7ECF0',
        'gray-20': '#CAD1D8',
        'gray-25': '#D6DFE9',
        'gray-47': '#7D8082',
        'gray-47-dim': '#7D80821A', // loader background
        'gray-50': '#797979',
        'gray-75': '#3A3E41',
        'gray-85': '#212426',
        green: '#13C12F',
        'green-dim': '#13C12F1A',
        orange: '#FF9800',
        'orange-secondary': '#F34C04',
        'orange-secondary-dim': '#F767071A',
        red: '#DE0909',
        'red-dim': '#DE09091A',
        white: '#FFFFFF',
        'yellow-cold': '#FFF8DE',
        // App colors
        background: '#F3F5F7',
        'button-pressed': '#15B3B2',
        'button-hovered': '#3BD5D3',
        border: '#E0E0E0',
        link: '#1B65F3',
        primary: '#13C1C0',
        'primary-bright': '#65D9E8FF',
        'primary-dim': '#13C1C033',
        'primary-gradient-0': '#13C1BC',
        'primary-gradient-100': '#3DD6D4 ',
        'primary-light': '#D0F3F2',
        'primary-lighter': '#BCE2ED',
        secondary: '#1A989A',
        shadow: '#D6D6D640',
        whatsapp: '#13C159'
      },
      flex: {
        2: 2,
        5: 5
      },
      fontSize: {
        'label-biggest': ['3rem', {
          lineHeight: '3.625rem',
          fontWeight: '700'
        }],
        'label-big': ['2.8rem', {
          lineHeight: '3.375rem',
          fontWeight: '500'
        }],
        'title-big': ['2.5rem', {
          lineHeight: '3rem',
          fontWeight: '800'
        }],
        'title-normal': ['2rem', {
          lineHeight: '2.375rem',
          fontWeight: '600'
        }],
        'title-small': ['1.5625rem', {
          lineHeight: '1.875rem',
          fontWeight: '600'
        }],
        'title-smaller': ['1.25rem', {
          lineHeight: '1.5rem',
          fontWeight: '500'
        }],
        big: ['1.125rem', {
          lineHeight: '1.3125rem',
          fontWeight: '500'
        }],
        normal: ['1rem', {
          lineHeight: '1.3125rem',
          fontWeight: '400'
        }],
        small: ['1rem', {
          lineHeight: '1.125rem',
          fontWeight: '500'
        }],
        smaller: ['0.875rem', {
          lineHeight: '1.125rem',
          fontWeight: '500'
        }],
        smallest: ['0.75rem', {
          lineHeight: '0.875rem',
          fontWeight: '400'
        }]
      },
      spacing: {
        4.5: '1.125rem',
        5.5: '1.375rem',
        6.5: '1.625rem',
        7.5: '1.875rem',
        8.5: '2.125rem',
        9.5: '2.375rem',
        12.5: '3.125rem',
        13: '3.25rem',
        13.5: '3.375rem',
        15: '3.75rem',
        17: '4.25rem',
        17.5: '4.375rem',
        18: '4.5rem',
        18.5: '4.625rem',
        22.5: '5.625rem',
        25: '6.25rem',
        27: '6.75rem',
        30: '7.5rem',
        42.5: '10.625rem',
        45: '11.25rem',
        50: '12.5rem',
        52.5: '13.125rem',
        54: '13.5rem',
        56.5: '14.125rem',
        64: '16rem',
        73.5: '18.375rem',
        78: '19.5rem',
        83: '20.75rem',
        91: '22.75rem'
      },
      borderRadius: { '4xl': '44px' },
      borderWidth: { 10: '10px' },
      zIndex: {
        1: 1,
        'select-menu': '99'
      }
    }
  }
};
