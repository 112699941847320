import React from 'react';
import { components } from 'react-select';
// Выпадающее меню селектора
const SelectMenuList = (props) => {
    const { children, isMulti } = props;
    if (!isMulti) {
        return React.createElement(components.MenuList, { ...props }, children);
    }
    let selectedOptions = [];
    let nonSelectedOptions = [];
    if (children instanceof Array) {
        selectedOptions = children?.filter?.(childNode => childNode.props.isSelected) ?? [];
        nonSelectedOptions = children?.filter?.(childNode => !childNode.props.isSelected) ?? [];
    }
    const childrenToRender = [...selectedOptions, ...nonSelectedOptions];
    if (childrenToRender.length === 0) {
        return (React.createElement(components.MenuList, { ...props },
            React.createElement("div", { className: 'h-8 px-3 flex items-center' },
                React.createElement("span", { className: 'text-gray-75' }, "\u041D\u0435\u0442 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u043E\u0432"))));
    }
    return (React.createElement(components.MenuList, { ...props }, childrenToRender));
};
export default SelectMenuList;
