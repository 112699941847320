import { classNames } from 'functions/Main';
import React from 'react';
import { components } from 'react-select';
import { IconDropdown } from 'svg/common';
// Вывод индикатора открытия/закрытия списка селекта
const DropdownIndicator = (props) => {
    const { selectProps } = props;
    const renderContent = () => (React.createElement(components.DropdownIndicator, { ...props, className: 'cursor-pointer' },
        React.createElement(IconDropdown, { className: classNames('selectDropdownIndicator', { selectDropdownIndicator_active: selectProps.menuIsOpen }), "data-testid": 'DropdownIndicator_icon' })));
    return renderContent();
};
export default DropdownIndicator;
