import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { classNames } from 'functions/Main';
import 'react-tooltip/dist/react-tooltip.css';
// Базовый тултип
const TooltipsBase = ({ className = 'py-3 px-6 !bg-gray-75 max-w-[440px] shadow-[0_4px_4px_rgba(214,214,214,0.25)]', ...otherProps }) => {
    const renderContent = () => (React.createElement(ReactTooltip, { className: classNames('', {}, [className || '']), style: { zIndex: 99, borderRadius: '4px' }, opacity: 1, ...otherProps }));
    return renderContent();
};
export default TooltipsBase;
