import React, { useEffect, useRef } from 'react';
import { renderLogo } from 'functions/auth';
// Компонент приветствия
const AuthGreeting = ({ onComplete }) => {
    const canvasRef = useRef(null);
    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas)
            return;
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        renderLogo(canvas, onComplete);
    }, []);
    const renderContent = () => (React.createElement("canvas", { className: 'authGreeting', ref: canvasRef, "data-testid": 'AuthGreeting' }));
    return renderContent();
};
export default AuthGreeting;
