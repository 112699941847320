import React, { useEffect, useState } from 'react';
import CardsStats from 'components/cards/Stats';
import ChartsDoughnutField from 'components/charts/doughnut/Field';
import ChartsDoughnutLegend from 'components/charts/doughnut/Legend';
import ChartsPlaceholder from 'components/charts/Placeholder';
import Loader from 'helpers/Loader';
import { getChartJsDoughnutData, getChartValueAsPercent, getValidName } from 'functions/dashboards';
import { getRubFormattedNumber } from 'functions/data';
import { useCubeFetch } from 'functions/cubeApi';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { cubeApi } from 'config/cubeApi';
import { doughnutMetrics } from 'config/charts/doughnut';
// Круговой график
const DashboardsDoughnutChart = ({ metricName, title, dateRange }) => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const [chartJsData, setChartJsData] = useState(null);
    const { cubeApiName } = doughnutMetrics[metricName];
    const setError = useShowError();
    const { resultSet, error, isLoading } = useCubeFetch(cubeApi.topList[cubeApiName]({ clinicId, dateRange }));
    useEffect(() => {
        if (error)
            setError(error.message);
    }, [error]);
    useEffect(() => {
        if (resultSet) {
            setChartJsData(getChartJsDoughnutData(resultSet, metricName));
        }
    }, [resultSet]);
    // Получение текста для отображения на тултипе
    const getTooltipText = ({ dataPoints }) => {
        // Лэйбл элемента
        const { label } = dataPoints[0];
        // Значение элемента
        const value = dataPoints[0].raw;
        const percent = getChartValueAsPercent(value, dataPoints[0].dataset.data);
        const percentToShow = percent < 0.1 ? '<0.1%' : `${percent.toFixed(1)}%`;
        const stats = [
            {
                name: 'Специальность',
                value: getValidName(label)
            },
            {
                name: 'Выручка',
                value: getRubFormattedNumber(value)
            },
            {
                name: 'Процент',
                value: percentToShow
            }
        ];
        return stats;
    };
    // Вывод данных(график и легенда)
    const renderData = (data) => {
        const dataSum = data.datasets[0].data.reduce((acc, current) => acc + +current, 0);
        return (React.createElement(React.Fragment, null,
            React.createElement(ChartsDoughnutField, { id: `DashboardsDoughnutChart_${metricName}`, data: data, getTooltipText: getTooltipText, centerLabel: getRubFormattedNumber(dataSum) }),
            React.createElement(ChartsDoughnutLegend, { data: data })));
    };
    // Вывод графика
    const renderChart = (data) => (data.labels && data.labels.length > 1 ? (renderData(data)) : (React.createElement(ChartsPlaceholder, null)));
    const renderContent = () => (React.createElement(CardsStats, { className: 'p-6 flex-col flex-1 relative' },
        React.createElement("h6", { className: 'font-bold text-gray-bi mb-6.5' }, title),
        React.createElement("div", { className: 'h-54 flex items-center gap-11' },
            chartJsData && (renderChart(chartJsData)),
            isLoading && React.createElement(Loader, { className: 'flex-1 absolute h-full' }))));
    return renderContent();
};
export default DashboardsDoughnutChart;
