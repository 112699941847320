import React from 'react';
import CardsBase from 'components/cards/Base';
import Loader from 'helpers/Loader';
import { classNames } from 'functions/Main';
// Обёртка для дашборда сервисов
const ServicesDashboardWrapper = ({ isLoading, className = '', children }) => {
    const renderContent = () => (React.createElement("div", { className: classNames('flex-1 flex overflow-hidden', {}, [className]) },
        React.createElement(CardsBase, { className: 'flex flex-1 bg-gray-05 overflow-hidden relative' },
            children,
            isLoading && React.createElement(Loader, { className: 'w-full h-full absolute bg-gray-47-dim' }))));
    return renderContent();
};
export default ServicesDashboardWrapper;
