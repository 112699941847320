import React, { memo, useCallback, useContext } from 'react';
import Checkbox from 'helpers/Checkbox';
import { ServicesDashboardContext } from 'helpers/Services/Dashboard/Context';
// Вывод услуги
const ServicesDashboardService = ({ service }) => {
    const { id, name, isChecked } = service;
    const { setServices } = useContext(ServicesDashboardContext);
    // Обработка нажатия на услугу
    const handleClick = useCallback(() => {
        setServices(prev => prev.map((item) => {
            if (item.id === id)
                return { ...service, isChecked: !isChecked };
            return item;
        }));
    }, [isChecked]);
    const renderContent = () => (React.createElement(Checkbox, { label: name, checked: isChecked, onClick: handleClick, dimensionsClassName: 'w-4 h-4' }));
    return renderContent();
};
export default memo(ServicesDashboardService);
