import React, { useState } from 'react';
import TruncateMarkup from 'react-truncate-markup';
// Компонент ячейки с именем
const TableTextCell = ({ text, className, textClassName, tooltipId, placeholder = '-', lines }) => {
    const [tooltip, setTooltip] = useState({ id: '', content: '' });
    // Установка данных тултипа при обрезании текста
    const onTruncate = (isTruncated) => {
        if (isTruncated && tooltipId && text) {
            setTooltip({
                id: tooltipId,
                content: text
            });
        }
    };
    const renderContent = () => (React.createElement("div", { className: className, "data-tooltip-id": tooltip.id, "data-tooltip-content": tooltip.content, "data-testid": 'TableTextCell' },
        React.createElement(TruncateMarkup, { lines: lines || 1, onTruncate: onTruncate },
            React.createElement("span", { className: textClassName, "data-testid": 'TableTextCell_text' }, text || placeholder))));
    return renderContent();
};
export default TableTextCell;
