import React from 'react';
import Select from 'helpers/Select/Index';
import { linearMultiMetricsMaxCount } from 'config/charts/linear/settings';
// Мульти селектор для дашборда
const DashboardsSelectorsMulti = ({ title, placeholder, values, setValues, options, isDisabled = false, onChangeAction }) => {
    const isClearable = values.length === 1;
    // Обработка изменения выбранных докторов
    const onChange = (newValue) => {
        setValues([...newValue]);
        if (onChangeAction) {
            onChangeAction();
        }
    };
    const renderContent = () => (React.createElement(Select, { isMulti: true, isDisabled: options.length === 0 || isDisabled, isClearable: isClearable, isShowDropdownIndicator: !isClearable, title: title, placeholder: placeholder, options: options, value: values, onChange: onChange, maxItems: linearMultiMetricsMaxCount, containerClassName: 'w-52.5' }));
    return renderContent();
};
export default DashboardsSelectorsMulti;
