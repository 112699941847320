import React, { useEffect, useState } from 'react';
import SettingsServiceCategoriesList from 'components/settings/serviceCategories/List';
import Loader from 'helpers/Loader';
import PeriodSelector from 'helpers/Services/PeriodSelector';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { apiUrls } from 'config/api';
// TODO: Дописать тесты на компонент
// Вывод компонента списка категорий услуг
export const SettingsServiceCategories = () => {
    const [serviceCategories, setServiceCategories] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { data, error } = useFetch(apiUrls.serviceCategories.list, 'get', { page });
    const setError = useShowError();
    useEffect(() => {
        if (!totalPages && data?.pagination?.pages)
            setTotalPages(data.pagination.pages);
        if (data?.service_categories) {
            setServiceCategories(prev => (page === 1 ?
                data.service_categories :
                [...prev, ...data.service_categories]));
        }
    }, [data]);
    useEffect(() => {
        if (error)
            setError(error);
    }, [error]);
    // Увеличение страницы для подгрузки новых элементов
    const loadMore = () => {
        if (totalPages > page)
            setPage(prev => prev + 1);
    };
    const renderContent = () => (React.createElement("div", { className: 'py-6 px-8', "data-testid": 'SettingsServiceCategories' },
        React.createElement(PeriodSelector, null),
        React.createElement("div", { className: 'mb-4 py-2.5 px-4 rounded-lg bg-blue-cold' },
            React.createElement("span", null, "\u0414\u043B\u044F \u043A\u043E\u0440\u0440\u0435\u043A\u0442\u043D\u044B\u0445 \u0440\u0430\u0441\u0447\u0435\u0442\u043E\u0432 \u0443\u043A\u0430\u0436\u0438\u0442\u0435 \u0432\u0441\u0435 \u0443\u0441\u043B\u0443\u0433\u0438, \u043A\u043E\u0442\u043E\u0440\u044B\u0435 \u0441\u043E\u0441\u0442\u043E\u044F\u0442 \u0432 \u0441\u043B\u0435\u0434\u0443\u044E\u0449\u0438\u0445 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F\u0445 :")),
        (data && data.service_categories) ? (React.createElement(SettingsServiceCategoriesList, { loadMore: loadMore, page: page, totalPages: totalPages, serviceCategories: serviceCategories })) : (React.createElement("div", { className: 'rounded-lg bg-white flex flex-1 py-10' },
            React.createElement(Loader, { className: 'h-1/2' })))));
    return renderContent();
};
export default SettingsServiceCategories;
