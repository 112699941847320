import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { useAvailableSections } from 'functions/data';
import navigationUrls from 'navigation/routes';
import { router } from 'navigation/router';
import { routerAuth } from 'navigation/routerAuth';
import { useAppSelector } from 'redux/hooks';
import { apiWrapper } from 'config/api';
// Главный роутер приложения
const AppRouter = () => {
    const { isLoggedIn, auth } = useAppSelector(state => state.user);
    const availableSections = useAvailableSections();
    const initialSection = availableSections?.length ? availableSections[0] : navigationUrls.campaigns.index;
    // Установке хедеров и получение данных app config
    if (isLoggedIn) {
        apiWrapper.addRequestTransform((request) => {
            request.headers = { ...request.headers, ...auth };
        });
    }
    const renderContent = () => (React.createElement(RouterProvider, { router: isLoggedIn ? router(initialSection) : routerAuth }));
    return renderContent();
};
export default AppRouter;
