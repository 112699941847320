import React from 'react';
import { Link } from 'react-router-dom';
import { pluralize } from 'pluralize-ru-ts';
import CardsBase from 'components/cards/Base';
import navigationUrls from 'navigation/routes';
// Карточка сценария лечения в табе сценариев
const SettingsTreatScenariosTabScenario = ({ scenario: { id, name, days_between_stages: daysBetweenStages } }) => {
    const renderContent = () => (React.createElement(Link, { to: navigationUrls.settings.treatScenarios.item.index(id), "data-testid": 'SettingsTreatScenariosTabScenario' },
        React.createElement(CardsBase, { className: 'p-6 flex flex-col gap-2 onRowHover' },
            React.createElement("span", { className: 'text-small font-semibold' }, name),
            React.createElement("span", null,
                "\u0412\u0440\u0435\u043C\u044F \u043C\u0435\u0436\u0434\u0443 \u044D\u0442\u0430\u043F\u0430\u043C\u0438: ",
                `${daysBetweenStages} ${pluralize('день', 'дня', 'дней')(daysBetweenStages)}`))));
    return renderContent();
};
export default SettingsTreatScenariosTabScenario;
