import { createSlice } from '@reduxjs/toolkit';
const initialState = {};
export const appConfigSlice = createSlice({
    name: 'appConfig',
    initialState,
    reducers: {
        update: (state, { payload }) => ({ ...state, ...payload }),
        remove: () => initialState
    }
});
export const { update: updateAppConfig, remove: removeAppConfig } = appConfigSlice.actions;
export default appConfigSlice.reducer;
