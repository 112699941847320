import React from 'react';
import moment from 'moment-timezone';
import SchedulesPatientAnswer from 'components/schedules/PatientAnswer';
import SchedulesStatus from 'components/schedules/Status';
import TableTextCell from 'components/table/TextCell';
import ProcessingDashboardRow from 'helpers/ProcessingDashboard/Row';
import { classNames } from 'functions/Main';
// Ряд таблицы дашборда подтверждения записи
const SchedulesDashboardTableRow = ({ schedule, index, changeStatusButton, columns, tooltipId }) => {
    const { appointment_date: appointmentDate, client, doctor, start_time: startTime, confirmation_status: confirmationStatus, confirmation_status_name: confirmationStatusName, message_status: messageStatus, message_status_name: messageStatusName, last_messages: lastMessages } = schedule;
    const renderContent = () => (React.createElement(ProcessingDashboardRow, { item: schedule, index: index, changeStatusButton: changeStatusButton, currentPathName: '\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435 \u0437\u0430\u043F\u0438\u0441\u0438', tooltipId: tooltipId },
        React.createElement("div", { className: classNames('text-small', {}, [columns[0].width]) }, moment(appointmentDate).format('DD.MM.YY')),
        React.createElement("div", { className: classNames('text-small', {}, [columns[1].width]) }, moment(startTime).format('HH:mm')),
        [
            { text: doctor?.full_name, key: 'doctorName' },
            { text: client?.name, key: 'clientName' }
        ].map(({ text, key }) => (React.createElement(TableTextCell, { text: text, className: classNames('pr-5', {}, [columns[2].width]), textClassName: 'text-small', tooltipId: tooltipId, lines: 2, key: key }))),
        React.createElement("div", { className: classNames('text-small', {}, [columns[4].width]) }, client?.mobile_phone || '-'),
        React.createElement("div", { className: classNames('text-small text-gray-47', {}, [columns[5].width]) },
            React.createElement(SchedulesPatientAnswer, { status: messageStatus, statusName: messageStatusName })),
        React.createElement("div", { className: columns[6].width },
            React.createElement(SchedulesStatus, { status: confirmationStatus, statusName: confirmationStatusName, lastMessages: lastMessages }))));
    return renderContent();
};
export default SchedulesDashboardTableRow;
