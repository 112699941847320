import { classNames } from 'functions/Main';
import React from 'react';
const additionalValueColor = {
    success: 'text-green',
    error: 'text-red',
    default: 'text-gray-bi'
};
// Компнент вывода статистики
const Stats = ({ value, description, additionalValue, additionalValueType = 'default', valueClassName = '', valueWrapperClassName = '', descriptionClassName = '', additionalValueClassName = '' }) => {
    // Вывод дополнительного значения
    const renderAdditionalValue = () => (React.createElement("span", { className: classNames('absolute -top-1 right-0 translate-x-full', {}, [additionalValueColor[additionalValueType], additionalValueClassName]), "data-testid": 'Stats_additionalValue' },
        "+",
        additionalValue));
    const renderContent = () => (React.createElement("div", { className: 'min-w-30 flex flex-col flexCenter' },
        React.createElement("div", { className: classNames('relative', {}, [valueWrapperClassName]), "data-testid": 'Stats_valueWrapper' },
            React.createElement("span", { className: classNames('text-gray-bi', {}, [valueClassName]), "data-testid": 'Stats_value' }, value),
            !!additionalValue && renderAdditionalValue()),
        React.createElement("p", { className: classNames('text-gray-bi', {}, [descriptionClassName]), "data-testid": 'Stats_description' }, description)));
    return renderContent();
};
export default Stats;
