import React, { useState } from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import FeedbackDashboardTableRow from 'components/feedback/dashboard/table/Row';
import Tooltip from 'components/tooltips/Main';
import ProcessingDashboard from 'helpers/ProcessingDashboard';
import ProcessingDashboardTable from 'helpers/ProcessingDashboard/Table';
import Layout from 'helpers/Layout';
import { ProcessingDashboardContext } from 'helpers/ProcessingDashboard/Context';
import ProcessingDashboardHead from 'helpers/ProcessingDashboard/Head';
import { IconRoundTick } from 'svg/schedules';
import { apiUrls } from 'config/api';
const tabs = [
    {
        status: 'unprocessed',
        statusToChange: 'resolved',
        title: 'Необработанные'
    },
    {
        status: 'processed',
        statusToChange: 'resolved',
        title: 'С оценкой'
    },
    {
        status: 'resolved',
        statusToChange: 'unprocessed',
        title: 'Обработанные'
    }
];
const columns = [
    {
        name: 'Дата приёма',
        width: 'w-[17%]'
    },
    {
        name: 'Врач',
        width: 'w-[24%]'
    },
    {
        name: 'Пациент',
        width: 'w-[24%]'
    },
    {
        name: 'Ответ',
        width: 'w-[22%]'
    }
];
const headerButtons = {
    unprocessed: {
        text: 'Перевести в обработанные',
        Icon: IconRoundTick
    },
    processed: {
        text: 'Перевести в обработанные',
        Icon: IconRoundTick
    }
};
const buttonTypes = {
    unprocessed: {
        tooltipText: 'Отметить обработанной',
        Icon: IconRoundTick
    },
    processed: {
        tooltipText: 'Отметить обработанной',
        Icon: IconRoundTick
    }
};
const pageTitle = 'Обратная связь';
const tooltipId = 'ViewsFeedbackIndex';
// Страница 'Обратная связь'
const ViewsFeedbackIndex = () => {
    const [query, setQuery] = useQueryParams({
        tab: StringParam,
        page: NumberParam
    });
    const [selectedSchedulesIds, setSelectedSchedulesIds] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    // Установка таба в query параметры
    const setTabKey = (newTab) => {
        setQuery({
            tab: newTab,
            page: 0
        });
    };
    // Установка страницы в query параметры
    const setPage = (newPage) => {
        setQuery(prev => ({
            ...prev,
            page: newPage
        }));
    };
    // Вывод ряда тыблицы дашборда
    const renderRow = ({ item, index, status, key, fetchChangeItemsStatus }) => {
        const isEditable = status !== 'resolved';
        return (React.createElement(FeedbackDashboardTableRow, { clientAnswerData: item, index: index, key: key, columns: columns, changeStatusButton: isEditable ? {
                ...buttonTypes[status],
                fetchChangeItemsStatus
            } : undefined, tooltipId: tooltipId, currentPathName: pageTitle, isSelectable: isEditable }));
    };
    // Вывод таба дашборда
    const renderTab = ({ status, statusToChange }) => {
        const isEditable = status !== 'resolved';
        return (React.createElement(ProcessingDashboardTable, { status: status, statusToChange: statusToChange, fetchItemsConfig: { url: apiUrls.campaignClients.clientsAnswers, params: { per_page: 9 } }, fetchChangeItemsStatusUrl: apiUrls.campaignClients.changeStatus, handleResponse: data => data.campaign_clients, renderHeader: ids => React.createElement(ProcessingDashboardHead, { itemsIds: ids, columns: columns, isSelectable: isEditable }), renderRow: renderRow, headerButton: headerButtons[status] }));
    };
    const renderContent = () => (React.createElement(ProcessingDashboardContext.Provider, { value: {
            selectedItemsIds: selectedSchedulesIds,
            setSelectedItemsIds: setSelectedSchedulesIds,
            isLoading,
            setIsLoading,
            tabKey: query.tab ?? tabs[0].status,
            setTabKey,
            page: query.page ?? 0,
            setPage,
            totalPages,
            setTotalPages
        } },
        React.createElement(Layout, { testId: 'ViewsFeedbackIndex', className: 'flex flex-col overflow-auto' },
            React.createElement("h1", { className: 'mb-8' }, pageTitle),
            React.createElement(ProcessingDashboard, { tabs: tabs, renderTab: renderTab }),
            React.createElement(Tooltip, { id: tooltipId }))));
    return renderContent();
};
export default ViewsFeedbackIndex;
