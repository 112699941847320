import React from 'react';
import Button from 'helpers/Button';
import { classNames } from 'functions/Main';
// Компонент главной кнопки
const ButtonsMain = ({ children, className, ...otherProps }) => {
    const renderContent = () => (React.createElement(Button, { className: classNames('buttonsMain h-9 rounded-4xl flex flexCenter', {}, [className || '']), ...otherProps },
        React.createElement("span", { className: 'text-smaller font-bold text-white' }, children)));
    return renderContent();
};
export default ButtonsMain;
