import React, { useEffect, useState } from 'react';
import { IconSearch } from 'svg/common';
import { classNames } from 'functions/Main';
let timer;
// Поле поиска услуг
const InputSearch = ({ onChangeSearch, value, placeholder = 'Поиск', wrapperClassName, testId, minSymbolsToSearch = 3, ...otherProps }) => {
    const [inputValue, setInputValue] = useState(() => value ?? '');
    // Если в поисковом значении меньше трех символов значит поиск не начинать
    const isSearchOn = inputValue.length >= minSymbolsToSearch;
    // Активация посика сервисов по запросу с задежкой в 1000 милисекунд
    useEffect(() => {
        if (isSearchOn)
            timer = setTimeout(() => onChangeSearch(inputValue), 1000);
        else if (!inputValue)
            onChangeSearch(inputValue);
        return () => clearTimeout(timer);
    }, [inputValue]);
    // Обработчик стейта инпута
    const searchInputHandler = (e) => {
        const currValue = e.currentTarget.value;
        setInputValue(currValue);
    };
    const renderContent = () => (React.createElement("div", { className: classNames('bg-gray-15 input flex items-center', {}, [wrapperClassName || '']), "data-testid": testId },
        React.createElement("input", { className: 'border-0 outline-none bg-gray-15 w-full', placeholder: placeholder, type: 'input', onChange: searchInputHandler, value: inputValue, ...otherProps }),
        React.createElement(IconSearch, null)));
    return renderContent();
};
export default InputSearch;
