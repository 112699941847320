import React, { useMemo, useState } from 'react';
import DashboardsSemenovDetailedStatisticChart from 'components/dashboards/semenov/detailedStatistic/Chart';
import DashboardsSemenovDetailedStatisticSelectors from 'components/dashboards/semenov/detailedStatistic/Selectors';
import { options } from 'components/dashboards/selectors/Period';
import TooltipsMain from 'components/tooltips/Main';
import { getGranularity, getMetricElementsNames, getValidName } from 'functions/dashboards';
import { getDateRange } from 'functions/date';
const metricsToShow = [
    'conversionPercentage',
    'treatsCountPerClient',
    // TODO: метрика "Показатель повторности" временно убрана
    // 'repetitionRate',
    'sumOfRevenueByDoctor',
    'consultationRevenue',
    'doctorsWorkload',
    'doctorsWorkloadBySchedule',
    'averageHourlyCost',
    'averageCostPerVisit'
];
const periodDefaultOption = options[2];
const tooltipId = 'ViewsDashboardsSemenovDetailedStatistic_metricTitle';
// Компонент вывода детальной статистики по Семёнову
const DashboardsSemenovDetailedStatisticIndex = ({ specialityQueryValue, doctorIdQueryValue, doctorNameQueryValue }) => {
    const [specialities, setSpecialities] = useState([{ value: specialityQueryValue, label: getValidName(specialityQueryValue) }]);
    const [dateRange, setDateRange] = useState(getDateRange(periodDefaultOption.value));
    const [doctors, setDoctors] = useState(doctorIdQueryValue && doctorNameQueryValue ?
        [{ value: doctorIdQueryValue, label: doctorNameQueryValue }] :
        []);
    // Степень детализации по времени
    const granularity = useMemo(() => getGranularity(dateRange), [dateRange]);
    // Элементы, по которым выводится график
    const activeElements = doctors && doctors.length > 0 ? doctors : specialities;
    // Вывод графиков
    const renderCharts = () => (React.createElement(React.Fragment, null, metricsToShow.map(metricName => (React.createElement(DashboardsSemenovDetailedStatisticChart, { metricName: metricName, metricElementsNames: getMetricElementsNames(activeElements, specialityQueryValue), specialities: specialities.length > 0 ?
            specialities.map(item => item.value) :
            [specialityQueryValue], doctorsIds: doctors.length > 0 ? doctors.map(item => item.value) : null, dateRange: dateRange, granularity: granularity, tooltipId: tooltipId, key: metricName })))));
    const renderContent = () => (React.createElement(React.Fragment, null,
        React.createElement(DashboardsSemenovDetailedStatisticSelectors, { specialities: specialities, setSpecialities: setSpecialities, doctors: doctors, setDoctors: setDoctors, setDateRange: setDateRange, periodDefaultOption: periodDefaultOption, dateRange: dateRange }),
        renderCharts(),
        React.createElement(TooltipsMain, { id: tooltipId })));
    return renderContent();
};
export default DashboardsSemenovDetailedStatisticIndex;
