import React from 'react';
import Loader from 'helpers/Loader';
import { classNames } from 'functions/Main';
// Компонент обертки таблицы
export const TableWrapper = ({ thead, children, tableClass, wrapperClass = '', loaderWrapperClass = 'h-full', testId, isLoading = false, additionalElements }) => {
    const renderContent = () => (React.createElement("div", { className: classNames('tableWrapper flex pb-4 w-full bg-white relative', {}, [wrapperClass]), "data-testid": testId },
        React.createElement("table", { className: tableClass },
            thead && React.createElement("thead", null, thead),
            React.createElement("tbody", null, children)),
        additionalElements,
        isLoading && (React.createElement("div", { className: classNames('absolute h-full w-full flex justify-center items-center left-0 top-0', {}, [loaderWrapperClass]) },
            React.createElement(Loader, null)))));
    return renderContent();
};
