import React from 'react';
import { classNames } from 'functions/Main';
const statuses = {
    confirmed: {
        textColor: 'text-green',
        bgColor: 'bg-green-dim'
    },
    canceled: {
        textColor: 'text-red',
        bgColor: 'bg-red-dim'
    },
    not_confirmed: {
        textColor: 'text-gray-47',
        bgColor: 'bg-gray-47-dim'
    },
    not_recognized: {
        textColor: 'text-orange-secondary',
        bgColor: 'bg-orange-secondary-dim'
    }
};
// Статус записи
const SchedulesStatus = ({ status, statusName
// lastMessages
 }) => {
    // TODO: тултип временно убран
    // const isLastMessagesExist = lastMessages && lastMessages.length > 0;
    const renderContent = () => (React.createElement("div", { className: classNames('h-7.5 w-fit px-2.5 rounded-[44px] flex items-center', {}, [statuses[status].bgColor]), "data-testid": 'SchedulesStatus' },
        React.createElement("span", { className: classNames('text-smallest font-semibold', {}, [statuses[status].textColor]), "data-testid": 'SchedulesStatus_text' }, statusName)));
    return renderContent();
};
export default SchedulesStatus;
