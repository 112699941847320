import React, { useCallback, useEffect, useState } from 'react';
import ChartsField from 'components/charts/base/Field';
import ChartsLayout from 'components/charts/base/Layout';
import { getChartJsData, getChartType, getTooltipDateText, getXTickDateText } from 'functions/dashboards';
import { useCubeFetch } from 'functions/cubeApi';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { cubeApi } from 'config/cubeApi';
import { linearCommonMetrics } from 'config/charts/linear';
// Общий график
const DashboardsCommonChart = ({ metricName, dateRange, granularity, tooltipId, color, fillColors }) => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const [chartJsData, setChartJsData] = useState(null);
    const setError = useShowError();
    const { resultSet, error, isLoading } = useCubeFetch(cubeApi.semenov.detailed({
        clinicId, dateRange, metricName, granularity
    }));
    const { tickText, stats: { tooltipText: statsTooltipText }, title, tooltipText } = linearCommonMetrics[metricName];
    const chartType = getChartType(metricName);
    useEffect(() => {
        if (resultSet) {
            setChartJsData(getChartJsData(resultSet, metricName, { fillColors }));
        }
    }, [resultSet, metricName]);
    useEffect(() => {
        if (error)
            setError(error.message);
    }, [error]);
    // Получение текста для отображения на тултипе
    const getTooltipText = ({ dataPoints }) => {
        // Значение графика по оси X
        const pointXValue = dataPoints[0].label;
        // Значение графика по оси Y
        const pointYValue = dataPoints[0].raw;
        const stats = [
            {
                name: statsTooltipText,
                value: tickText(pointYValue)
            },
            {
                name: 'Дата',
                value: getTooltipDateText(pointXValue, granularity)
            }
        ];
        return stats;
    };
    // Получение текста элементов по оси X
    const getXTickText = useCallback((value) => getXTickDateText(value, granularity), [granularity]);
    // Получение текста элементов по оси Y
    const getYTickText = useCallback((value) => tickText(value), []);
    const renderContent = () => (React.createElement(ChartsLayout, { title: title, isLoading: isLoading, headerTooltip: tooltipText ? { id: tooltipId, text: tooltipText } : undefined }, chartJsData && (React.createElement(ChartsField, { id: `DashboardsCommonChart_${metricName}`, data: chartJsData, type: chartType, chartColor: color, getTooltipText: getTooltipText, getXTickText: getXTickText, getYTickText: getYTickText }))));
    return renderContent();
};
export default DashboardsCommonChart;
