import React, { useState } from 'react';
import Switch from 'react-switch';
import { useNavigate } from 'react-router-dom';
import Stats from 'helpers/Stats';
import { IconWhatsApp } from 'svg/common';
import { handleErrors, useShowError } from 'functions/Errors';
import { getCampaignMessagesStatsFormatted } from 'functions/campaigns';
import { loadingHandler } from 'functions/data';
import navigationUrls from 'navigation/routes';
import { apiUrls, apiWrapper } from 'config/api';
// Компонент рыссылки
const CampaignsItem = ({ data: { id, name, active, messages_count: messagesCount } }) => {
    const [isActive, setIsActive] = useState(active);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const setError = useShowError();
    // Изменение статуса активности рассылки
    const changeActiveStatus = async () => {
        const response = await apiWrapper.patch(apiUrls.campaigns.switchActive(id));
        if (response.ok)
            setIsActive(prev => !prev);
        else
            setError(handleErrors(response));
    };
    // Изменение свича
    const onSwitchChange = async () => {
        loadingHandler(changeActiveStatus, setIsLoading);
    };
    // Вывод статистики
    const renderStats = () => {
        const stats = getCampaignMessagesStatsFormatted(messagesCount);
        return (React.createElement("div", { className: 'px-6 flex gap-12.5' }, Object.values(stats).map(stat => (React.createElement(Stats, { ...stat, key: stat.additionalValueType, valueClassName: 'text-title-small', valueWrapperClassName: 'mb-1', additionalValueClassName: 'text-smaller font-semibold', descriptionClassName: 'text-smaller' })))));
    };
    const renderContent = () => (React.createElement("div", { className: 'rounded-lg mb-3 p-6 bg-white flex justify-between', "data-testid": 'CampaignsItem' },
        React.createElement("div", { className: 'flex' },
            React.createElement("div", { className: 'mr-4' },
                React.createElement(Switch, { onChange: onSwitchChange, checked: isActive, disabled: isLoading, height: 24, width: 46, handleDiameter: 20, offColor: '#E7ECF0', onColor: '#13C1C0', offHandleColor: '#CAD1D8', onHandleColor: '#FFFFFF', uncheckedIcon: false, checkedIcon: false })),
            React.createElement("div", { className: 'flex flex-col justify-between cursor-pointer group', onClick: () => navigate(navigationUrls.campaigns.item(id), { state: { campaignName: name } }) },
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h4", { className: 'baseTransition font-semibold text-gray-75 underline decoration-white group-hover:decoration-primary' }, name)),
                React.createElement("div", { className: 'flex items-center' },
                    React.createElement("span", { className: 'text-smallest mr-2 text-gray-47' }, "\u0421\u043F\u043E\u0441\u043E\u0431 \u043E\u0442\u043F\u0440\u0430\u0432\u043A\u0438"),
                    React.createElement(IconWhatsApp, { className: 'fill-whatsapp' })))),
        renderStats()));
    return renderContent();
};
export default CampaignsItem;
