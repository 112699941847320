import React, { useEffect, useState } from 'react';
import CardsStats from 'components/cards/Stats';
import Loader from 'helpers/Loader';
import ProgressBar from 'helpers/ProgressBar';
import { getShortenName } from 'functions/data';
import { useCubeFetch } from 'functions/cubeApi';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { cubeApi } from 'config/cubeApi';
import { horizontalBarMetrics } from 'config/charts/horizontalBar';
// График с горизонтальными линиями в дашборде по категориям
const DashboardsCategoriesHorizontalBarChart = ({ metricName, dateRange, categoryName }) => {
    const { title, tickText, stats: { valueKey, cubeKey } } = horizontalBarMetrics[metricName];
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const [chartData, setChartData] = useState(null);
    const setError = useShowError();
    const { resultSet, error, isLoading } = useCubeFetch(cubeApi.categories.horizontalBarMetrics({
        clinicId, dateRange, metricName, categoryName
    }));
    useEffect(() => {
        if (resultSet) {
            setChartData(geChartData(resultSet));
        }
    }, [resultSet]);
    useEffect(() => {
        if (error)
            setError(error.message);
    }, [error]);
    // Вывод данных из resultSet куба
    const geChartData = (cubeResultSet) => {
        const rawData = cubeResultSet.rawData();
        return rawData.map(item => ({
            doctorId: item[`${cubeKey}.doctor_id`],
            doctorName: item[`${cubeKey}.doctor_full_name`],
            value: item[`${cubeKey}.${valueKey}`]
        }));
    };
    // Вывод ряда
    const renderRow = ({ doctorId, doctorName, value, maxValue }) => {
        const percent = (+value / +maxValue) * 100;
        return (React.createElement("div", { className: 'flex gap-2 items-center', key: doctorId },
            React.createElement("div", { className: 'flex-[10]' },
                React.createElement("span", null, getShortenName(doctorName))),
            React.createElement("div", { className: 'flex-[5] flex justify-start' },
                React.createElement("span", null, tickText(value))),
            React.createElement("div", { className: 'flex-[12]' },
                React.createElement(ProgressBar, { percent: percent }))));
    };
    const renderContent = () => (React.createElement(CardsStats, { className: 'p-4 mb-2 flex-1 flex-col relative' },
        React.createElement("div", { className: 'w-full mb-3 flex' },
            React.createElement("h6", { className: 'font-bold text-gray-bi' }, title)),
        React.createElement("div", { className: 'flex flex-col gap-2.5 min-h-5' }, chartData && chartData.map(item => renderRow({ ...item, maxValue: chartData[0].value }))),
        isLoading && React.createElement(Loader, { className: 'absolute h-full' })));
    return renderContent();
};
export default DashboardsCategoriesHorizontalBarChart;
