import React from 'react';
import Editor from 'react-simple-code-editor';
import { classNames } from 'functions/Main';
//  Текстовый редактор для шаблона
const TemplatesTextEditor = ({ value, setValue, variables, isDisabled }) => {
    // Подсветка переменных редактора
    const highlightVariables = (textValue) => {
        if (variables.length > 0) {
            const variablesRegex = variables.join('|');
            const REGEX = new RegExp(`(${variablesRegex})`, 'g');
            return textValue.split(REGEX).map((word) => {
                if (word.match(REGEX) !== null) {
                    return (React.createElement("span", { key: word, className: 'text-orange-secondary' }, word));
                }
                return React.createElement("span", { key: word }, word);
            });
        }
        return textValue;
    };
    const renderContent = () => (React.createElement("div", { className: 'templatesTextEditor__wrapper hideScrollbar overflow-auto' },
        React.createElement(Editor, { value: value, disabled: isDisabled, className: classNames('templatesTextEditor', { 'bg-gray-15': isDisabled }), placeholder: '\u0422\u0435\u043A\u0441\u0442 \u0448\u0430\u0431\u043B\u043E\u043D\u0430', onValueChange: textValue => setValue(textValue), highlight: highlightVariables, "data-testid": 'TemplatesTextEditor' })));
    return renderContent();
};
export default TemplatesTextEditor;
