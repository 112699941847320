// Получение текста процента переданной части сообщений от общего кол-ва
export const getPercentText = (total, part) => {
    const percent = (part / total) * 100;
    let percentText;
    switch (true) {
        case part === 0:
            percentText = '0%';
            break;
        case part === total:
            percentText = '100%';
            break;
        case percent < 1:
            percentText = '< 1%';
            break;
        case percent > 99:
            percentText = '> 99%';
            break;
        default:
            percentText = `${Math.round(percent)}%`;
    }
    return percentText;
};
// Получение отформатированной статистики по сообщениям рассылки
const getCampaignMessagesStatsFormatted = ({ delivered_today: deliveredToday, delivered_total: deliveredTotal, failed_today: failedToday, failed_total: failedTotal, sent_today: sentToday, sent_total: sentTotal }) => {
    const deliveredPercentText = `Доставлено ${getPercentText(sentTotal, deliveredTotal)}`;
    const failedPercentText = `Ошибок ${getPercentText(sentTotal, failedTotal)}`;
    const stats = {
        sent: {
            additionalValueType: 'default',
            value: sentTotal,
            additionalValue: sentToday,
            description: 'Отправлено'
        },
        delivered: {
            additionalValueType: 'success',
            value: deliveredTotal,
            additionalValue: deliveredToday,
            description: deliveredPercentText
        },
        failed: {
            additionalValueType: 'error',
            value: failedTotal,
            additionalValue: failedToday,
            description: failedPercentText
        }
    };
    return stats;
};
export default getCampaignMessagesStatsFormatted;
