import React, { useMemo } from 'react';
import DashboardsCommonChart from 'components/dashboards/common/Chart';
import DashboardsDoughnutChart from 'components/dashboards/DoughnutChart';
import { getGranularity } from 'functions/dashboards';
const metricsToShow = [
    {
        name: 'sumOfRevenue',
        fillColors: ['rgba(132, 234, 247, 1)', 'rgba(54, 204, 223, 1)']
    },
    {
        name: 'averageBill',
        color: 'rgba(116, 192, 252, 1)'
    },
    {
        name: 'chairsWorkload',
        fillColors: ['rgba(148, 208, 255, 1)', 'rgba(84, 170, 238, 1)']
    },
    {
        name: 'visitsCount',
        color: 'rgba(177, 151, 252, 1)'
    }
];
// Компонент общего дашборда
const DashboardsCommonIndex = ({ dateRange, tooltipId }) => {
    // Степень детализации по времени
    const granularity = useMemo(() => getGranularity(dateRange), [dateRange]);
    const renderContent = () => (React.createElement(React.Fragment, null,
        React.createElement(React.Fragment, null, metricsToShow.slice(0, 2).map(({ name, color, fillColors }) => (React.createElement(DashboardsCommonChart, { metricName: name, dateRange: dateRange, color: color, fillColors: fillColors, key: name, granularity: granularity, tooltipId: tooltipId })))),
        React.createElement("div", { className: 'flex gap-2 mb-2' },
            React.createElement(DashboardsDoughnutChart, { metricName: 'specialities', title: '\u0422\u041E\u041F-10 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0435\u0439 \u043F\u043E \u0432\u044B\u0440\u0443\u0447\u043A\u0435', dateRange: dateRange }),
            React.createElement(DashboardsDoughnutChart, { metricName: 'doctors', title: '\u0422\u041E\u041F-10 \u0432\u0440\u0430\u0447\u0435\u0439 \u043F\u043E \u0432\u044B\u0440\u0443\u0447\u043A\u0435', dateRange: dateRange })),
        React.createElement(React.Fragment, null, metricsToShow.slice(2).map(({ name, color, fillColors }) => (React.createElement(DashboardsCommonChart, { metricName: name, dateRange: dateRange, color: color, fillColors: fillColors, key: name, granularity: granularity, tooltipId: tooltipId }))))));
    return renderContent();
};
export default DashboardsCommonIndex;
