import React from 'react';
// Компонент картинки с обработкой нескольких форматов
const Image = ({ srcPng, srcWebp, ...otherProps }) => {
    const renderContent = () => (React.createElement("picture", null,
        React.createElement("source", { srcSet: srcWebp, type: 'image/webp' }),
        React.createElement("source", { srcSet: srcPng, type: 'image/png' }),
        React.createElement("img", { src: srcPng, alt: otherProps.alt, ...otherProps })));
    return renderContent();
};
export default Image;
