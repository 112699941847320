import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import Button from 'helpers/Button';
import { IconCopy, IconDoubleTick } from 'svg/common';
import { classNames } from 'functions/Main';
// Компонент кнопки копирования
const ButtonsCopy = ({ textToCopy }) => {
    const [isCopied, setIsCopied] = useState(false);
    // Копирование переданного текста
    const onCopy = () => {
        const isCopiedSuccessfully = copy(textToCopy);
        if (isCopiedSuccessfully) {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
    };
    const renderContent = () => (React.createElement(Button, { className: classNames('buttonsCopy group h-6 flex items-center justify-center bg-primary rounded disabled:opacity-100', { 'buttonsCopy_expanded w-[116px]': isCopied }), disabled: isCopied, onClick: onCopy },
        React.createElement(IconCopy, { className: 'buttonsCopy__copy' }),
        React.createElement("div", { className: 'buttonsCopy__copied items-center' },
            React.createElement(IconDoubleTick, { className: 'mr-1' }),
            React.createElement("span", { className: 'text-smaller text-white' }, "\u0421\u043A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u043D\u043E"))));
    return renderContent();
};
export default ButtonsCopy;
