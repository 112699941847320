import cubejs from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { useAppSelector } from 'redux/hooks';
// Хук для запроса дынных из cubejs
export default function useCubeFetch(query, options) {
    const { host, jwtToken } = useAppSelector(state => state.appConfig.cubejs);
    const { time_zone: timezone } = useAppSelector(state => state.clinics.activeClinic);
    const cubeJsBaseUrl = `${host}/cubejs-api/v1`;
    const cubeApi = cubejs(jwtToken, { apiUrl: cubeJsBaseUrl });
    return useCubeQuery({ ...query, timezone }, { cubeApi, ...options });
}
