import React from 'react';
import moment from 'moment-timezone';
import PatientsHistoriesMessagesChatMessage from 'components/patients/history/messages/ChatMessage';
// Компонент вывода сообщений за день
const PatientsHistoriesMessagesChatDayMessages = ({ date, messages }) => {
    const renderContent = () => (React.createElement("div", { className: 'flex flex-col mb-1', key: date.toString(), "data-testid": 'PatientChatDay' },
        React.createElement("span", { className: 'mb-4 font-semibold text-gray-bi self-center' }, moment(date).format('D MMMM YYYY')),
        messages.map(messageItem => React.createElement(PatientsHistoriesMessagesChatMessage, { message: messageItem, key: messageItem.id }))));
    return renderContent();
};
export default PatientsHistoriesMessagesChatDayMessages;
