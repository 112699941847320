import { useContext } from 'react';
import { ErrorContext } from 'helpers/ErrorContextProvider';
// Хук для установки ошибки в контекст
export const useShowError = () => {
    const { setError } = useContext(ErrorContext);
    return setError;
};
// Обработчик ошибок
export const handleErrors = ({ data, status }) => {
    let error = null;
    // Если присутствует поле message, то отображаем его содержимое
    if (data?.errors)
        error = data?.errors.join(' ');
    else if (status === 404)
        error = 'Не найдено';
    // Если поле message отсутствует, но запрос завершен с ошибкой, то отображаем следующее сообщение
    else
        error = 'Неизвестная ошибка';
    return error;
};
