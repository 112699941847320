import React, { useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { defaultPeriod } from 'components/schedules/Filters/data';
import SchedulesDashboardTableRow from 'components/schedules/dashboard/table/Row';
import SchedulesFilters from 'components/schedules/Filters';
import Tooltip from 'components/tooltips/Main';
import TooltipsBase from 'components/tooltips/Base';
import ProcessingDashboard from 'helpers/ProcessingDashboard';
import ProcessingDashboardTable from 'helpers/ProcessingDashboard/Table';
import Layout from 'helpers/Layout';
import { ProcessingDashboardContext } from 'helpers/ProcessingDashboard/Context';
import ProcessingDashboardHead from 'helpers/ProcessingDashboard/Head';
import { IconReturn, IconRoundTick } from 'svg/schedules';
import { apiUrls } from 'config/api';
const tabs = [
    {
        status: 'unprocessed',
        statusToChange: 'processed',
        title: 'Необработанные'
    },
    {
        status: 'processed',
        statusToChange: 'unprocessed',
        title: 'Обработанные'
    }
];
const columns = [
    {
        name: 'Дата',
        width: 'w-[6%]'
    },
    {
        name: 'Время',
        width: 'w-[6%]'
    },
    {
        name: 'Врач',
        width: 'w-[15%]'
    },
    {
        name: 'Пациент',
        width: 'w-[15%]'
    },
    {
        name: 'Номер телефона',
        width: 'w-[15%]'
    },
    {
        name: 'Статус сообщения',
        width: 'w-[15%]'
    },
    {
        name: 'Статус записи',
        width: 'w-[15%]'
    }
];
const headerButtons = {
    unprocessed: {
        text: 'Отметить обработанными',
        Icon: IconRoundTick
    },
    processed: {
        text: 'Вернуть в необработанные',
        Icon: IconReturn
    }
};
const buttonTypes = {
    unprocessed: {
        tooltipText: 'Отметить обработанными',
        Icon: IconRoundTick
    },
    processed: {
        tooltipText: 'Вернуть в необработанные',
        Icon: IconReturn
    }
};
// Компонент страницы Подтверждение записи
const ViewsSchedulesIndex = () => {
    const [tabKey, setTabKey] = useQueryParam('tab', StringParam);
    const [period, setPeriod] = useState(defaultPeriod);
    const [selectedSchedulesIds, setSelectedSchedulesIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // Вывод ряда тыблицы дашборда
    const renderRow = ({ item, index, status, key, fetchChangeItemsStatus }) => (React.createElement(SchedulesDashboardTableRow, { schedule: item, index: index, key: key, columns: columns, changeStatusButton: {
            ...buttonTypes[status],
            fetchChangeItemsStatus
        }, tooltipId: 'ViewsSchedulesIndex' }));
    // Вывод таба дашборда
    const renderTab = ({ status, statusToChange }) => (React.createElement(ProcessingDashboardTable, { status: status, statusToChange: statusToChange, fetchItemsConfig: { url: apiUrls.schedules.list }, fetchChangeItemsStatusUrl: apiUrls.schedules.changeStatus, handleResponse: data => data.schedules, renderHeader: ids => React.createElement(ProcessingDashboardHead, { itemsIds: ids, columns: columns }), renderRow: renderRow, headerButton: headerButtons[status] }));
    const renderContent = () => (React.createElement(ProcessingDashboardContext.Provider, { value: {
            period,
            setPeriod,
            selectedItemsIds: selectedSchedulesIds,
            setSelectedItemsIds: setSelectedSchedulesIds,
            isLoading,
            setIsLoading,
            tabKey: tabKey || tabs[0].status,
            setTabKey
        } },
        React.createElement(Layout, { testId: 'ViewsSchedulesIndex', className: 'flex flex-col overflow-auto' },
            React.createElement("h1", { className: 'mb-8' }, "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435 \u0437\u0430\u043F\u0438\u0441\u0438"),
            React.createElement(SchedulesFilters, null),
            React.createElement(ProcessingDashboard, { tabs: tabs, renderTab: renderTab }),
            React.createElement(Tooltip, { id: 'ViewsSchedulesIndex' }),
            React.createElement(TooltipsBase, { id: 'ViewsSchedulesIndex_lastMessages', place: 'left', className: '!bg-gray-75 !py-6 !px-7 max-w-[584px] shadow-[0_4px_4px_rgba(214,214,214,0.25)]' }))));
    return renderContent();
};
export default ViewsSchedulesIndex;
