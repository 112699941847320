import React from 'react';
import CardsStats from 'components/cards/Stats';
import Loader from 'helpers/Loader';
import { IconTooltip } from 'svg/common';
import { classNames } from 'functions/Main';
// Макет графика
const ChartsLayout = ({ title, headerTooltip, isWithLegend, isLoading, children }) => {
    const renderContent = () => (React.createElement(CardsStats, { className: 'p-4 mb-2 flexCenter flex-col relative' },
        React.createElement("div", { className: 'w-full mb-3 flex' },
            React.createElement("h6", { className: 'font-bold text-gray-bi' }, title),
            headerTooltip && (React.createElement("div", { className: 'ml-1', "data-tooltip-id": headerTooltip.id, "data-tooltip-content": headerTooltip.text, "data-testid": 'ChartsLayout_tooltipTarget' },
                React.createElement(IconTooltip, { className: 'w-5 h-5' })))),
        React.createElement("div", { className: classNames('w-full flex', {}, [isWithLegend ? 'h-73.5' : 'h-64']), "data-testid": 'ChartsLayout_children' }, children),
        isLoading && React.createElement(Loader, { className: 'flex-1 absolute h-full' })));
    return renderContent();
};
export default ChartsLayout;
