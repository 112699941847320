import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardsSelectorsPeriod, { options } from 'components/dashboards/selectors/Period';
import ButtonsBack from 'components/buttons/Back';
import DashboardsCommonIndex from 'components/dashboards/common/Index';
import TooltipsMain from 'components/tooltips/Main';
import Layout from 'helpers/Layout';
import { getDateRange } from 'functions/date';
import navigationUrls from 'navigation/routes';
const periodDefaultOption = options[1];
const tooltipId = 'ViewsDashboardsCommon_metricTitle';
// Страница дашборда общей статистики
const ViewsDashboardsCommon = () => {
    const [dateRange, setDateRange] = useState(getDateRange(periodDefaultOption.value));
    const navigate = useNavigate();
    const renderContent = () => (React.createElement(Layout, { className: 'overflow-auto' },
        React.createElement(ButtonsBack, { text: '\u0414\u0430\u0448\u0431\u043E\u0440\u0434\u044B', className: 'mb-1', onClick: () => navigate(navigationUrls.dashboards.index) }),
        React.createElement("h1", { className: 'mb-8' }, "\u041E\u0431\u0449\u0438\u0439"),
        React.createElement(DashboardsSelectorsPeriod, { onChange: setDateRange, defaultOption: periodDefaultOption }),
        React.createElement(DashboardsCommonIndex, { dateRange: dateRange, tooltipId: tooltipId }),
        React.createElement(TooltipsMain, { id: tooltipId })));
    return renderContent();
};
export default ViewsDashboardsCommon;
