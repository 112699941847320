import React, { useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { useParams } from 'react-router-dom';
import CampaignsSystemAnswersItem from 'components/campaigns/systemAnswers/Item';
import Loader from 'helpers/Loader';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { apiUrls } from 'config/api';
// Таб с шаблонами ответов на рассылки
const CampaignsSystemAnswersTab = () => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const { campaignId } = useParams();
    const { data, error, isDataFetched, refetch } = useFetch(apiUrls.campaigns.show(campaignId), 'get', { clinic_id: clinicId });
    const setError = useShowError();
    useEffect(() => {
        if (error)
            setError(error);
    }, [error]);
    useEffect(() => {
        if (isDataFetched)
            refetch();
    }, [clinicId]);
    const renderContent = () => (data ? (React.createElement("div", { className: 'p-8.5 w-[600px] flex flex-col gap-10' }, data.campaign.reply_templates.map(template => React.createElement(CampaignsSystemAnswersItem, { template: template, key: template.id })))) : (React.createElement(Loader, { className: 'h-[200px]' })));
    return renderContent();
};
export default CampaignsSystemAnswersTab;
