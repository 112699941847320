import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import PatientsHistoriesMessagesIndex from 'components/patients/history/messages/Index';
import PatientsHistoriesTreatsIndex from 'components/patients/history/treats/Index';
import PatientsShowHeaderIndex from 'components/patients/show/header/Index';
import Tooltip from 'components/tooltips/Main';
import TabsWrapper from 'helpers/TabsWrapper';
import { classNames } from 'functions/Main';
const tabs = [
    {
        key: 'treats',
        title: 'История посещений',
        renderComponent: () => React.createElement(PatientsHistoriesTreatsIndex, null)
    },
    {
        key: 'messages',
        title: 'История сообщений',
        renderComponent: () => React.createElement(PatientsHistoriesMessagesIndex, null)
    }
];
// Компонент вывод страницы пациента
const ViewsPatientsItem = () => {
    const [tabKey, setTabKey] = useQueryParam('tab', StringParam);
    const isMessagesHistoryTab = tabKey === 'messages';
    const renderContent = () => (React.createElement("div", { className: classNames('flex-1 flex flex-col', { 'overflow-auto': !isMessagesHistoryTab }) },
        React.createElement(PatientsShowHeaderIndex, null),
        React.createElement(TabsWrapper, { tabs: tabs, tabKey: tabKey, setTabKey: setTabKey, className: classNames('', { overflow_visible: !isMessagesHistoryTab }) }),
        React.createElement(Tooltip, { id: 'ViewsPatientsItem' })));
    return renderContent();
};
export default ViewsPatientsItem;
