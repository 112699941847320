// Получить укороченное значение суммы 90500 => 90,5 тыс.
const getShortenSum = (value) => {
    // Сокращаем тысячи
    let formattedSum = +value / 1000;
    // Если начальное число больше тысячи, то округляем результат
    // до одного знака после запятой
    if (+value > 1000)
        formattedSum = +formattedSum.toFixed(1);
    return `${formattedSum.toLocaleString('ru-RU')} тыс.`;
};
export default getShortenSum;
