import React from 'react';
import moment from 'moment-timezone';
import { classNames } from 'functions/Main';
// Вывод сообщения в истории сообщений с пациентом
const PatientsHistoriesMessagesChatMessage = ({ message: { content, created_at: createdAt, send_type: sendType } }) => {
    const isMessageOutcome = sendType === 'outcome';
    const renderContent = () => (React.createElement("div", { className: classNames('flex mb-3', {}, [isMessageOutcome ? 'justify-end' : 'justify-start']), "data-testid": 'PatientsHistoriesMessagesChatMessage' },
        React.createElement("div", { className: classNames('flex flex-col rounded-xl p-3 max-w-[630px] shadow-[0_4px_4px_0_rgba(180,180,180,0.25)]', {}, [isMessageOutcome ? 'items-end bg-blue' : 'items-start bg-yellow-cold']) },
            React.createElement("p", null, content),
            React.createElement("span", { className: 'text-smaller text-gray-bi' }, moment(createdAt).format('H:mm')))));
    return renderContent();
};
export default PatientsHistoriesMessagesChatMessage;
