import React from 'react';
const defaultProps = {
    email: {
        placeholder: 'E-mail',
        type: 'email'
    }
};
// Поле инпута
const InputField = ({ type, ...otherProps }) => {
    const renderContent = () => (React.createElement("input", { className: 'input w-full', placeholder: defaultProps[type].placeholder, type: defaultProps[type].type, ...otherProps }));
    return renderContent();
};
export default InputField;
