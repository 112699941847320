import React from 'react';
import ServicesDashboardHeader from 'helpers/Services/Dashboard/Header';
import ServicesDashboardList from 'helpers/Services/Dashboard/List';
import ServicesDashboardSelectAll from 'helpers/Services/Dashboard/SelectAll';
// Вывод компонента обертки списка услуг выбранной категории
const ServicesDashboardBlock = ({ children }) => {
    const renderContent = () => (React.createElement("div", { className: 'bg-white flex flex-col flex-1', "data-testid": 'ServicesDashboardBlock' }, children));
    return renderContent();
};
ServicesDashboardBlock.Header = ServicesDashboardHeader;
ServicesDashboardBlock.List = ServicesDashboardList;
ServicesDashboardBlock.ServicesDashboardSelectAll = ServicesDashboardSelectAll;
export default ServicesDashboardBlock;
