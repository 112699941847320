import React, { useEffect, useState } from 'react';
import DashboardsSelectorsPeriod from 'components/dashboards/selectors/Period';
import Select from 'helpers/Select/Index';
import { getValidName, sortSelectOptionsAlphabetically } from 'functions/dashboards';
import { useCubeFetch } from 'functions/cubeApi';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { cubeApi } from 'config/cubeApi';
import { linearMultiMetricsMaxCount } from 'config/charts/linear/settings';
// Компонент селекторов для графиков детальной статистики
const DashboardsSemenovDetailedStatisticSelectors = ({ specialities, setSpecialities, doctors, setDoctors, setDateRange, dateRange, periodDefaultOption }) => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const [specialitiesOptions, setSpecialitiesOptions] = useState([]);
    const [doctorsOptions, setDoctorsOptions] = useState([]);
    const { resultSet: specialitiesResultSet, error: specialitiesError } = useCubeFetch(cubeApi.semenov.specialities({ clinicId, dateRange }));
    const { resultSet: doctorsResultSet, error: doctorsError } = useCubeFetch(cubeApi.semenov.doctors({ clinicId, dateRange, speciality: specialities[0]?.value }), 
    // Запрос на докторов не выполняется, если выбрана НЕ одна специальность
    { skip: specialities.length !== 1 });
    const setError = useShowError();
    // Обработка полученных данных специальностей
    useEffect(() => {
        if (specialitiesResultSet) {
            const specialitiesOptionsData = specialitiesResultSet.rawData().map((item) => {
                const specialityName = item['treats.doctor_speciality'];
                return {
                    value: specialityName,
                    label: getValidName(specialityName)
                };
            });
            setSpecialitiesOptions(sortSelectOptionsAlphabetically(specialitiesOptionsData, 'value'));
        }
    }, [specialitiesResultSet]);
    // Обработка полученных данных докторов
    useEffect(() => {
        if (doctorsResultSet) {
            const doctorsOptionsData = doctorsResultSet.rawData().map((item) => {
                const doctorId = item['treats.doctor_id'];
                const doctorName = item['treats.doctor_full_name'];
                return {
                    value: doctorId,
                    label: doctorName
                };
            });
            setDoctorsOptions(sortSelectOptionsAlphabetically(doctorsOptionsData, 'label'));
        }
    }, [doctorsResultSet]);
    // Обработка ошибок запрсов
    useEffect(() => {
        const error = specialitiesError || doctorsError;
        if (error)
            setError(error.message);
    }, [specialitiesError, doctorsError]);
    // Обработка изменения выбранных специальностей
    const onSpecialitiesChange = (newValue) => {
        setSpecialities([...newValue]);
        setDoctors([]);
    };
    // Обработка изменения выбранных докторов
    const onDoctorsChange = (newValue) => {
        setDoctors([...newValue]);
    };
    // Можем ли очистить поля со специальностью или доктором
    // * при множественном выборе поля очищаются в бэйдже селекта
    const isSpecialityClearable = specialities.length === 1;
    const isDoctorsClearable = doctors.length === 1;
    const renderContent = () => (React.createElement("div", { className: 'mb-6 h-9.5 flex gap-6' },
        React.createElement(DashboardsSelectorsPeriod, { onChange: setDateRange, containerClassName: '', selectorWrapperClassName: 'w-52.5', defaultOption: periodDefaultOption }),
        React.createElement(Select, { isMulti: true, isDisabled: specialitiesOptions.length === 0, isClearable: isSpecialityClearable, isShowDropdownIndicator: !isSpecialityClearable, title: '\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u044C', placeholder: '\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u044C', options: specialitiesOptions, value: specialities, onChange: onSpecialitiesChange, maxItems: linearMultiMetricsMaxCount, containerClassName: 'w-52.5' }),
        React.createElement(Select, { isMulti: true, isDisabled: doctorsOptions.length === 0 || specialities.length !== 1, isClearable: isDoctorsClearable, isShowDropdownIndicator: !isDoctorsClearable, title: '\u0412\u0440\u0430\u0447\u0438', placeholder: '\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0432\u0440\u0430\u0447\u0430', options: doctorsOptions, value: doctors, onChange: onDoctorsChange, maxItems: linearMultiMetricsMaxCount, containerClassName: 'w-52.5' })));
    return renderContent();
};
export default DashboardsSemenovDetailedStatisticSelectors;
