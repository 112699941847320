import React from 'react';
import AuthCode from 'components/auth/Code';
import AuthReleaseVersion from 'components/auth/ReleaseVersion';
import ButtonsBack from 'components/buttons/Back';
import { IconAuth } from 'svg/common';
import { useAppSelector } from 'redux/hooks';
// Страница подтверждения кода авторизации
const ViewsAuthCode = () => {
    const { email } = useAppSelector(state => state.user.data);
    const renderContent = () => (React.createElement("div", { className: 'flex-1 flex bg-white' },
        React.createElement("div", { className: 'viewsAuthCode__leftSide flex-1 relative flex items-center justify-center' },
            React.createElement("div", { className: 'viewsAuthCode__leftSide__content' },
                React.createElement(IconAuth, null)),
            React.createElement("div", { className: 'viewsAuthCode__leftSide__background absolute' })),
        React.createElement("div", { className: 'viewsAuthCode__rightSide flex-1 flex flex-col justify-center pl-13.5' },
            React.createElement(ButtonsBack, { text: '\u0421\u043C\u0435\u043D\u0438\u0442\u044C \u0430\u043A\u043A\u0430\u0443\u043D\u0442', className: 'mb-4.5' }),
            React.createElement("h5", { className: 'viewsAuthCode__rightSide__description text-gray-85 mb-13.5' },
                "\u041C\u044B \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043B\u0438 \u043A\u043E\u0434 \u0434\u043B\u044F \u0432\u0445\u043E\u0434\u0430 \u043D\u0430 ",
                React.createElement("span", { className: 'underline' }, email),
                ".",
                React.createElement("br", null),
                "\u0415\u0441\u043B\u0438 \u0412\u044B \u043D\u0435 \u043F\u043E\u043B\u0443\u0447\u0438\u043B\u0438 \u043A\u043E\u0434 \u0442\u0435\u0447\u0435\u043D\u0438\u0435 \u043D\u0435\u0441\u043A\u043E\u043B\u044C\u043A\u0438\u0445 \u043C\u0438\u043D\u0443\u0442, \u043F\u0440\u043E\u0432\u0435\u0440\u044C\u0442\u0435 \u043F\u0438\u0441\u044C\u043C\u0430 \u0432 \u043F\u0430\u043F\u043A\u0435 \u0441\u043F\u0430\u043C \u0438 \u043F\u0440\u043E\u043C\u043E\u0430\u043A\u0446\u0438\u0438 \u0438\u043B\u0438 \u043E\u0442\u043F\u0440\u0430\u0432\u044C\u0442\u0435 \u043A\u043E\u0434 \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E."),
            React.createElement("div", { className: 'mb-18.5' },
                React.createElement(AuthCode, null)),
            React.createElement(AuthReleaseVersion, null))));
    return renderContent();
};
export default ViewsAuthCode;
