// Получения значения сортировки для имен дашборда
const getSortValue = (a, b) => {
    let sortValue = 0;
    if (a === null || a.trim().toLowerCase() === 'null') {
        sortValue = 1;
    }
    else if (b === null || b.trim().toLowerCase() === 'null') {
        sortValue = -1;
    }
    else if (a < b) {
        sortValue = -1;
    }
    else if (a > b) {
        sortValue = 1;
    }
    return sortValue;
};
export default getSortValue;
