import React from 'react';
import ReactSelect from 'react-select';
import ClearIndicator from 'components/helpers/Select/ClearIndicator';
import DropdownIndicator from 'components/helpers/Select/DropdownIndicator';
import SelectSingleValue from 'components/helpers/Select/SingleValue';
import SelectLabel from 'helpers/Select/Label';
import SelectMenuList from 'helpers/Select/MenuList';
import SelectMultiValue from 'helpers/Select/MultiValue';
import SelectOption from 'helpers/Select/Option';
import { classNames } from 'functions/Main';
// Основной селектор
const Select = (props) => {
    const { title, containerClassName = '', isMulti, maxItems, isDisabled, isClearable = false, isShowDropdownIndicator = true } = props;
    const renderContent = () => (React.createElement("div", { className: classNames('selectWrapper', { selectWrapper_disabled: isDisabled }, [containerClassName]), "data-testid": 'Select' },
        title && (React.createElement(SelectLabel, { className: 'text-smallest text-gray-bi absolute' },
            React.createElement("div", null, title))),
        React.createElement(ReactSelect, { isMulti: isMulti, hideSelectedOptions: false, closeMenuOnSelect: !isMulti, isSearchable: false, isClearable: isClearable, classNamePrefix: 'selectWrapper__select', components: {
                SingleValue: singleProps => React.createElement(SelectSingleValue, { ...singleProps }),
                MultiValue: SelectMultiValue,
                MenuList: SelectMenuList,
                Option: optionProps => React.createElement(SelectOption, { ...optionProps, maxItems: maxItems }),
                DropdownIndicator: isShowDropdownIndicator ? DropdownIndicator : null,
                ClearIndicator,
                IndicatorSeparator: () => null
            }, ...props })));
    return renderContent();
};
export default Select;
