import React, { useEffect, useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AuthGreeting from 'components/auth/Greeting';
import Button from 'helpers/Button';
import Loader from 'helpers/Loader';
import { getAppConfig } from 'functions/Api';
import { handleErrors } from 'functions/Errors';
import { loadingHandler } from 'functions/data';
import navigationUrls from 'navigation/routes';
import { makeLogged, updateUserAuth, updateUserData } from 'redux/slices/user';
import { updateActiveClinic } from 'redux/slices/clinics';
import { updateAppConfig } from 'redux/slices/appConfig';
import { useAppSelector } from 'redux/hooks';
import { apiUrls, apiWrapper } from 'config/api';
// Компонент ввода кода авторизации
const AuthCode = () => {
    const { data: userData } = useAppSelector(state => state.user);
    const [code, setCode] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(true);
    const [doShowGreeting, setDoShowGreeting] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (seconds === 0) {
                setIsCodeSent(false);
                return clearTimeout(timeout);
            }
            return setSeconds(prev => prev - 1);
        }, 1000);
    }, [seconds]);
    useEffect(() => {
        if (code.length === 6)
            loadingHandler(signIn, setIsLoading);
    }, [code]);
    // Авторизация
    const signIn = async () => {
        const response = await apiWrapper.post(apiUrls.auth.signIn, { confirmation_code: code, id: userData.id });
        if (response.ok) {
            const { auth, user } = response.data;
            const appConfigResponse = await getAppConfig({ ...auth, 'token-type': 'Bearer' });
            if (appConfigResponse.data) {
                dispatch(updateAppConfig({
                    cubejs: {
                        host: appConfigResponse.data.cubejs_host,
                        jwtToken: appConfigResponse.data.cubejs_jwt_token
                    }
                }));
                dispatch(updateActiveClinic(user.user_clinics[0].clinic));
                dispatch(updateUserData(user));
                dispatch(updateUserAuth(auth));
                setDoShowGreeting(true);
            }
            else
                setError(appConfigResponse.error);
        }
        else
            setError(handleErrors(response));
    };
    // Вход в приложение
    const enterIn = () => {
        dispatch(makeLogged());
        navigate(navigationUrls.root);
    };
    // Повторная отправка кода авторизации
    const resendCode = async () => {
        const response = await apiWrapper.post(apiUrls.auth.confirmationCode, { email: userData.email });
        if (response.ok) {
            setIsCodeSent(true);
            setSeconds(30);
        }
        else
            setError(handleErrors(response));
    };
    // Вывод таймера повторной отправки кода
    const renderResendCodeTimer = () => (React.createElement("h6", { className: 'authCode__timer', "data-testid": 'AuthCode_resendCode_timer' },
        "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u0435\u0449\u0435 \u0440\u0430\u0437 \u0447\u0435\u0440\u0435\u0437 00:",
        seconds > 9 ? seconds : `0${seconds}`));
    // Вывод кнопки повторной отправки кода авторизации
    const renderResendCodeButton = () => (React.createElement(Button, { className: 'authCode__resendButton', onClick: () => loadingHandler(resendCode, setIsLoading) },
        React.createElement("h6", null, "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u0435\u0449\u0435 \u0440\u0430\u0437")));
    // Вывод инпута кода
    const renderCodeInput = () => {
        let statusClass = '';
        if (isLoading)
            statusClass = 'authCode__codeInput_loading';
        if (error)
            statusClass = 'authCode__codeInput_error';
        return (React.createElement("div", { className: 'relative mb-6.5' },
            React.createElement(ReactCodeInput, { type: 'number', className: `authCode__codeInput ${statusClass}`, onChange: setCode, "data-testid": 'AuthCode_input' }),
            isLoading && (React.createElement("div", { className: 'w-full h-full absolute top-0 flex items-center justify-center' },
                React.createElement(Loader, { className: 'w-11 h-10' }))),
            error && React.createElement("span", { className: 'text-smallest font-medium text-red absolute bottom-27' }, error)));
    };
    const renderContent = () => (React.createElement("div", { className: 'authCode flex items-center flex-col' },
        renderCodeInput(),
        React.createElement("div", { className: 'h-4' },
            React.createElement("div", { className: isLoading ? 'hidden' : '', "data-testid": 'AuthCode_resendCode' }, isCodeSent ? renderResendCodeTimer() : renderResendCodeButton())),
        doShowGreeting && React.createElement(AuthGreeting, { onComplete: enterIn })));
    return renderContent();
};
export default AuthCode;
