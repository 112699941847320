import { doughnutColors, doughnutMetrics } from 'config/charts/doughnut';
// Получение данных для отображения на графике
const getChartJsDoughnutData = (resultSet, metricName) => {
    const { getLegendName, stats: { cubeKey, xValueKey, yValueKey } } = doughnutMetrics[metricName];
    const chartDatasets = [
        {
            data: resultSet.rawData().map(data => data[`${cubeKey}.${yValueKey}`]),
            backgroundColor: doughnutColors,
            borderWidth: 0
        }
    ];
    const chartLabels = resultSet.rawData().map(data => getLegendName(data[`${cubeKey}.${xValueKey}`]));
    return {
        datasets: chartDatasets,
        labels: chartLabels
    };
};
export default getChartJsDoughnutData;
