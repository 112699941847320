import { logoData } from './data';
// Отрисовка элемента логотипа - треугольника
export default function renderTriangle(canvas, { color, positions: { initial, final }, invert, scale: elementInitialScale, additionalOffset }, progress) {
    const { width: logoWidth, height: logoHeight, elementHeight, elementWidth, marginBottom } = logoData;
    const ctx = canvas.getContext('2d');
    if (!ctx)
        return;
    // Размеры канваса
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    // Положение логотипа
    const logoX = (canvasWidth / 2) - (logoWidth / 2);
    const logoY = (canvasHeight / 2) - (logoHeight / 2) - marginBottom;
    // Положение треугольника внутри логотипа
    const triangleX = final.x * elementWidth;
    const triangleY = final.y * elementHeight;
    // Определение, в какой части логотипа распологается треугольник
    const isLeftPart = initial.x <= 0.5;
    const isUpPart = initial.y <= 0.5;
    // Определение дополнительного отступа треуголника в начальном положении
    const initialOffsetX = isLeftPart ? -(elementWidth * elementInitialScale + additionalOffset) : additionalOffset;
    const initialOffsetY = isUpPart ? -(elementHeight * elementInitialScale + additionalOffset) : additionalOffset;
    // Начальное положение треугольника на канвасе
    const initialX = canvasWidth * initial.x + initialOffsetX;
    const initialY = canvasHeight * initial.y + initialOffsetY;
    // Конечное положение треугольника на канвасе
    const finalX = logoX + triangleX;
    const finalY = logoY + triangleY;
    // Длина пути, который должен пройти треугольник
    const deltaX = Math.abs(finalX - initialX);
    const deltaY = Math.abs(finalY - initialY);
    // Определение направления, в котором движется треугольник
    const isPositiveDirectionX = initialX < finalX;
    const isPositiveDirectionY = initialY < finalY;
    // Текущее расположение треугольника
    const currentX = isPositiveDirectionX ? (initialX + (deltaX * progress)) : (finalX + (deltaX * (1 - progress)));
    const currentY = isPositiveDirectionY ? (initialY + (deltaY * progress)) : (finalY + (deltaY * (1 - progress)));
    ctx.save();
    ctx.translate(currentX, currentY);
    // Маштабирование треугольника в зависимости от прогресса анимации
    const scaleDelta = elementInitialScale - 1;
    ctx.scale(1 + (scaleDelta * (1 - progress)), 1 + (scaleDelta * (1 - progress)));
    if (invert) {
        // Разворот треуголника на 180 градусов
        ctx.rotate(Math.PI);
        ctx.translate(-elementWidth, -elementHeight);
    }
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(elementWidth, elementHeight / 2);
    ctx.lineTo(0, elementHeight);
    ctx.closePath();
    ctx.fillStyle = color;
    ctx.fill();
    ctx.restore();
}
