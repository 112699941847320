import React from 'react';
// Компонент детали сообщения рассылки
const CampaignsMessagesDetail = ({ title, text, Icon, isDecorated = false, className = '' }) => {
    const renderContent = () => (React.createElement("div", { className: className, "data-testid": 'CampaignsMessagesDetail' },
        React.createElement("span", { className: 'text-smaller text-gray-main' }, title),
        React.createElement("div", { className: 'mt-2 flex items-center' },
            Icon && React.createElement(Icon, { className: 'mr-1 w-5 h-5 fill-whatsapp' }),
            React.createElement("p", { className: isDecorated ? 'text-primary underline' : '', "data-testid": 'CampaignsMessagesDetail_text' }, text))));
    return renderContent();
};
export default CampaignsMessagesDetail;
