import { categories } from './categories';
import { description } from './description';
import { patients } from './patients';
import { semenov } from './semenov';
import { topList } from './topList';
export const cubeApi = {
    categories,
    description,
    semenov,
    topList,
    patients
};
