// Список метрик, для которых рисуется линейный график
export const lineMetrics = [
    'conversionPercentage',
    'sumOfRevenueByDoctor',
    'doctorsWorkload',
    'doctorsWorkloadBySchedule',
    'sumOfRevenue',
    'chairsWorkload',
    'treatsTotalCount'
];
export const chartTargetLabels = {
    category: 'Категория',
    doctor: 'Врач',
    speciality: 'Специальность'
};
// Список цветов для графиков с отображением нескольких метрик
export const linearMultiMetricsColors = [
    {
        line: 'rgb(51, 154, 240)',
        bar: 'rgb(101, 217, 232)'
    },
    {
        line: 'rgb(255, 146, 44)',
        bar: 'rgb(20, 170, 191)'
    },
    {
        line: 'rgb(255, 107, 107)',
        bar: 'rgb(255, 216, 168)'
    },
    {
        line: 'rgb(82, 207, 102)',
        bar: 'rgb(247, 103, 7)'
    },
    {
        line: 'rgb(134, 51, 240)',
        bar: 'rgb(243, 194, 174)'
    }
];
// Максимальное кол-во метрик на одном графике
export const linearMultiMetricsMaxCount = linearMultiMetricsColors.length;
