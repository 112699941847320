import { create } from 'apisauce';
// Получение мета тега с CSRF токеном
const csrfMetaTag = document?.querySelector("meta[name='csrf-token']");
// Настройки заголовков и базового url
export const apiWrapper = create({
    baseURL: '/api/v1/',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'token-type': 'Bearer',
        'X-CSRF-Token': csrfMetaTag?.content
    }
});
export const apiUrls = {
    accounts: {
        repetitionPeriods: {
            list: (accountId) => `accounts/${accountId}/show_repetition_periods`,
            update: (accountId) => `accounts/${accountId}/change_repetition_period`
        }
    },
    appConfig: {
        releaseVersion: 'release_version',
        show: 'app_config'
    },
    auth: {
        signIn: '/auth/sign_in',
        confirmationCode: '/auth/send_confirmation_code'
    },
    campaigns: {
        list: '/campaigns',
        replyTemplates: {
            show: (campaignId, templateId) => `/campaigns/${campaignId}/reply_templates/${templateId}`
        },
        show: (id) => `/campaigns/${id}`,
        statistic: (campaignId) => `/campaigns/${campaignId}/statistic`,
        statisticByMonth: (campaignId) => `/campaigns/${campaignId}/statistic_by_month`,
        switchActive: (id) => `/campaigns/${id}/switch_active`,
        triggers: 'campaigns/triggers',
        messages: {
            list: (id) => `/campaigns/${id}/conversation_messages`,
            show: (campaignId, messageId) => `/campaigns/${campaignId}/conversation_messages/${messageId}`
        }
    },
    campaignClients: {
        changeStatus: 'campaign_clients/change_status',
        clientsAnswers: 'campaign_clients/clients_answers',
        index: 'campaign_clients'
    },
    clients: {
        show: (id) => `/clients/${id}`,
        treats: (id) => `/clients/${id}/treats_list`,
        changeNotificationAllowance: (id) => `/clients/${id}/change_notification_allowance`,
        conversationsList: (id) => `/clients/${id}/conversations/list_by_client`
    },
    conversations: { show: (id) => `/conversations/${id}/conversation_messages/list_by_conversation` },
    doctors: {
        changeServiceCategory: 'doctors/change_service_category',
        list: 'doctors',
        // Список врачей всего
        listWithoutCategory: 'doctors/list_without_service_category'
    },
    serviceCategories: {
        changeServiceCategory: (categoryId) => (`service_categories/${categoryId}/services/change_service_category`),
        // Список врачей в категории
        doctors: (categoryId) => `service_categories/${categoryId}/show_doctors_in_category`,
        list: '/service_categories',
        // Список сервисов в категории
        services: (categoryId) => `service_categories/${categoryId}/show_services_in_category`,
        show: (categoryId) => `service_categories/${categoryId}`,
        // Список сервисов всего
        showTreat: 'service_categories/show_treat'
    },
    schedules: {
        changeStatus: '/schedules/change_status',
        list: '/schedules'
    },
    services: {
        // Список услуг вне этапа сценария лечения
        notInStage: '/services/services_not_in_treat_scenario_stage'
    },
    templates: {
        list: '/templates',
        show: (id) => `/templates/${id}`
    },
    treatScenarios: {
        services: {
            // Список услуг в этапе сценария лечения
            list: (scenarioId, stageId) => `/treat_scenarios/${scenarioId}/treat_scenario_stages/${stageId}`,
            changeService: (scenarioId, stageId) => `/treat_scenarios/${scenarioId}/treat_scenario_stages/${stageId}/change_service`
        },
        list: '/treat_scenarios',
        show: (id) => `/treat_scenarios/${id}`,
        clients: {
            changeStatus: '/treat_scenario_clients/change_status',
            list: '/treat_scenario_clients'
        }
    }
};
