import React, { useEffect } from 'react';
import SettingsTreatScenariosTabScenario from 'components/settings/treatScenariosTab/Scenario';
import Loader from 'helpers/Loader';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { apiUrls } from 'config/api';
import { useAppSelector } from 'redux/hooks';
// Таб со сценариями лечения
const SettingsTreatScenariosTab = () => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const { data, error, refetch, isDataFetched, loading } = useFetch(apiUrls.treatScenarios.list, 'get', { clinic_id: clinicId });
    const setError = useShowError();
    useEffect(() => {
        if (error) {
            setError(error);
        }
    }, [error]);
    useEffect(() => {
        if (isDataFetched)
            refetch();
    }, [clinicId]);
    // Вывод списка сценариев
    const renderScenarios = (scenarios) => (scenarios.length > 0 ? (scenarios.map(item => React.createElement(SettingsTreatScenariosTabScenario, { scenario: item, key: item.id }))) : (React.createElement("span", null, "\u041D\u0435\u0442 \u0441\u0446\u0435\u043D\u0430\u0440\u0438\u0435\u0432 \u043B\u0435\u0447\u0435\u043D\u0438\u044F")));
    const renderContent = () => (React.createElement("div", { className: 'py-6 px-8 flex flex-1 flex-col gap-3 relative' },
        (data && !loading) && (renderScenarios(data.treat_scenarios)),
        loading && (React.createElement(Loader, { className: 'h-50' }))));
    return renderContent();
};
export default SettingsTreatScenariosTab;
