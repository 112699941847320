import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonsBack from 'components/buttons/Back';
import CampaignsMessagesList from 'components/campaigns/messages/List';
import CampaignsStatsTab from 'components/campaigns/stats/Tab';
import CampaignsSystemAnswersTab from 'components/campaigns/systemAnswers/Tab';
import TabsWrapper from 'helpers/TabsWrapper';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { apiUrls } from 'config/api';
import navigationUrls from 'navigation/routes';
import { useAppSelector } from 'redux/hooks';
import Loader from 'helpers/Loader';
const tabs = [
    {
        key: 'stats',
        title: 'Статистика',
        renderComponent: () => React.createElement(CampaignsStatsTab, null)
    },
    {
        key: 'systemAnswers',
        title: 'Системные ответы',
        renderComponent: () => React.createElement(CampaignsSystemAnswersTab, null)
    },
    {
        key: 'messagesList',
        title: 'История рассылок',
        renderComponent: () => React.createElement(CampaignsMessagesList, null)
    }
];
const getAvailableTabs = (trigger) => {
    // Убираем вкладку systemAnswers для всех триггеров, кроме net_promoter_score
    if (trigger !== 'net_promoter_score') {
        return tabs.filter(tab => tab.key !== 'systemAnswers');
    }
    return tabs;
};
// Компонент страницы Рассылки
const ViewsCampaignsItem = () => {
    const { campaignId } = useParams();
    const [tabKey, setTabKey] = useQueryParam('tab', StringParam);
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const [campaign, setCampaign] = useState(null);
    const { data, error, isDataFetched, refetch } = useFetch(apiUrls.campaigns.show(campaignId), 'get', { clinic_id: clinicId });
    const setError = useShowError();
    const navigate = useNavigate();
    useEffect(() => {
        if (data) {
            setCampaign(data.campaign);
        }
    }, [data]);
    useEffect(() => {
        if (error)
            setError(error);
    }, [error]);
    useEffect(() => {
        if (isDataFetched)
            refetch();
    }, [clinicId]);
    const renderContent = () => (React.createElement("div", { className: 'flex-1 flex flex-col' },
        React.createElement("div", { className: 'm-8' },
            React.createElement(ButtonsBack, { text: '\u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0438', className: 'mb-1', onClick: () => navigate(navigationUrls.campaigns.index) }),
            React.createElement("div", { className: 'h-9.5' },
                React.createElement("h1", null, campaign && campaign.name))),
        campaign ? (React.createElement(TabsWrapper, { tabs: getAvailableTabs(campaign.trigger), tabKey: tabKey, setTabKey: setTabKey })) : (React.createElement(Loader, { className: 'h-[200px]', testId: 'ViewsCampaignsItem_loader' }))));
    return renderContent();
};
export default ViewsCampaignsItem;
