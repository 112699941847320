import React from 'react';
import { renderToString } from 'react-dom/server';
import TooltipsStatsContent from 'components/tooltips/StatsContent';
// Вывод тултипа для графика
const renderChartJsTooltip = ({ tooltip }, { tooltipElementId, setIsTooltipOpen }, { chartType, getTooltipText }) => {
    // Скрытие тултипа
    if (tooltip.opacity === 0) {
        setIsTooltipOpen(false);
        return;
    }
    const tooltipEl = document.querySelector(`#${tooltipElementId}`);
    const stats = getTooltipText(tooltip);
    // Установка атрибутов для отображения тултипа
    tooltipEl.setAttribute('data-tooltip-html', renderToString(React.createElement(TooltipsStatsContent, { stats: stats })));
    tooltipEl.style.position = 'absolute';
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltip.yAlign) {
        tooltipEl.classList.add(tooltip.yAlign);
    }
    else {
        tooltipEl.classList.add('no-transform');
    }
    // Позиционирование тултипа на странице
    const additionalOffset = (chartType === 'line' || chartType === 'doughnut') ? 6 : 0;
    tooltipEl.style.left = `${tooltip.caretX}px`;
    tooltipEl.style.top = `${tooltip.caretY - additionalOffset}px`;
    setIsTooltipOpen(true);
};
export default renderChartJsTooltip;
