import { random } from 'lodash';
import { logoData } from './data';
// Добавление в данные треугольников случайное маштабирование и случайный отступ
export default function randomizeTriangleProps(triangles) {
    const { elementScaleRange, elementAdditionalOffsetRange } = logoData;
    return triangles.map(triangle => ({
        ...triangle,
        scale: random(elementScaleRange.from, elementScaleRange.to),
        additionalOffset: random(elementAdditionalOffsetRange.from, elementAdditionalOffsetRange.to)
    }));
}
