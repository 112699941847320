import twMerge from 'config/twMerge';
// Функция для объединения классов стилей в один
// cls - дефолтный класс/классы строкой
// mods - моды классов. Можно условиями задавать модификации классов
// additional - массив доп классов. Тут должны лежать все классы отступов, цветов, и прочего
export function classNames(cls, mods = {}, additional = []) {
    return twMerge([
        cls,
        ...additional.filter(Boolean),
        Object.keys(mods).filter(key => mods[key])
    ]);
}
