import React from 'react';
import Button from 'helpers/Button';
import { classNames } from 'functions/Main';
const statusClassName = {
    good: 'tableStatsElement_good',
    average: 'tableStatsElement_average',
    bad: 'tableStatsElement_bad',
    notRated: 'tableStatsElement_notRated'
};
// Компонет элемента таблицы статистики
const TableStatsElement = ({ text, tooltipId, tooltipContent, status, onClick }) => {
    // Обработка клика на элемент статистики
    const onElementClick = (e) => {
        if (onClick) {
            e.stopPropagation();
            onClick();
        }
    };
    const renderContent = () => (React.createElement(Button, { className: 'flex-1 flex items-center justify-center px-1.5', onClick: onElementClick, "data-testid": 'TableStatsElement_button' },
        React.createElement("div", { className: classNames(status ? statusClassName[status] : '', {}, ['flex items-center justify-center flex-1 h-10 rounded']), "data-tooltip-id": tooltipId, "data-tooltip-content": tooltipContent, "data-testid": 'TableStatsElement' },
            React.createElement("p", { className: 'text-smaller' }, text))));
    return renderContent();
};
export default TableStatsElement;
