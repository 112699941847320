import React from 'react';
// Содержание тултипа статистики
const TooltipsStatsContent = ({ stats }) => {
    const renderContent = () => (React.createElement("div", { className: 'tooltipsStatsContent flex gap-2' },
        React.createElement("div", { className: 'flex-1 flex flex-col items-end' }, stats.map(({ name }) => (React.createElement("span", { key: name, className: 'text-gray-main' },
            name,
            ":")))),
        React.createElement("div", { className: 'flex-1 flex flex-col' }, stats.map(({ name, value }) => (React.createElement("span", { key: `${name}${value}` }, value))))));
    return renderContent();
};
export default TooltipsStatsContent;
